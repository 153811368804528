import React from 'react';
import { Helmet } from 'react-helmet';

export const PageTitle = ({ title }) => (
  <Helmet>
    <title>
      Scenset{process.env.TAG ? ` ${process.env.TAG}` : ''}
      {title ? ` - ${title}` : ''}
    </title>
  </Helmet>
);
