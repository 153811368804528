import { useReducer, useEffect } from 'react';

function findIndexOfDocument(doc, items) {
  return items.findIndex(item => {
    return item.id === doc.id;
  });
}

function updateItem(doc, items) {
  const i = findIndexOfDocument(doc, items);
  items[i] = doc;
}

function deleteItem(doc, items) {
  const i = findIndexOfDocument(doc, items);
  items.splice(i, 1);
}

function addItem(doc, items, isNew) {
  const i = findIndexOfDocument(doc, items);
  if (i === -1) {
    if (isNew) {
      items.push(doc);
    } else {
      items.unshift(doc);
    }
  }
}

// eslint-disable-next-line consistent-return
function reducer(state, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'loaded': {
      const items = [...state.items];
      let isAdding = false;
      let isNew = false;

      action.value.docChanges().forEach(change => {
        if (change.type === 'added') {
          isAdding = true;
          isNew = change.oldIndex === -1 && change.newIndex === 0;
          addItem(change.doc, items, isNew);
        } else if (change.type === 'modified') {
          updateItem(change.doc, items);
        } else if (change.type === 'removed') {
          deleteItem(change.doc, items);
        }
      });

      const nextLimit = items.length + action.pageSize;

      const end = items.length < action.limit || nextLimit === state.limit;

      return {
        ...state,
        hasMore: isAdding && !isNew ? !end : state.hasMore,
        limit: nextLimit,
        loading: false,
        loadingError: null,
        lastLoaded: action.value.docs[action.value.docs.length - 1],
        loadingMore: false,
        items,
      };
    }

    case 'loadMore': {
      return {
        ...state,
        loadingMore: true,
        after: state.lastLoaded,
      };
    }
  }
}

const initialState = {
  hasMore: false,
  after: null,
  limit: 0,
  items: [],
  lastLoaded: null,
  loading: true,
  loadingError: null,
  loadingMore: false,
  loadingMoreError: null,
};

export const usePaginatedQuery = (query, { firstPage = 50, pageSize = 50, includeMetadataChanges = false }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const unsubscribe = query
      .orderBy('time.date', 'desc')
      .limit(state.limit || firstPage)
      .onSnapshot({ includeMetadataChanges }, snap => {
        dispatch({ type: 'loaded', value: snap, pageSize });
      });

    return () => unsubscribe();
  }, [state.after]);

  function loadMore() {
    dispatch({ type: 'loadMore' });
  }

  return {
    after: state.after,
    lastLoaded: state.lastLoaded,
    loadingMore: state.loadingMore,
    loadingError: state.loadingError,
    loadingMoreError: state.loadingMoreError,
    loading: state.loading,
    hasMore: state.hasMore,
    items: state.items,
    loadMore,
  };
};
