import { cx } from '@emotion/css';
import { Image as OriginImage, originTextToString } from '@origin-dot/core';

import { Stylable } from '../helpers/types';
import { CdnConfig, useComponentConfig } from './ComponentsConfigProvider';
import { generateImageInfo, ImgInfo } from './imageHelper';
import { OriginText } from './OriginText';

type PhotoProps = Stylable<{
  image: OriginImage;
  showCaption?: boolean;
  heightBound?: boolean;
}>;

export const Photo = ({ className, image, showCaption = false, heightBound = false }: PhotoProps) => {
  const config = useComponentConfig();
  const cdnProvider = config?.cdnProvider || 'none';
  const cdnConfig: undefined | CdnConfig = config && config.cdnConfig;
  const imageInfo: ImgInfo = generateImageInfo(image, cdnProvider, cdnConfig, heightBound);

  return (
    <div>
      <div
        className={cx(className, 'darkmode')}
        css={[
          { position: 'relative', borderRadius: 10, flexShrink: 0, overflow: 'hidden' },
          heightBound ? { height: '100%' } : { width: '100%' },
          imageInfo.placeholderUrl
            ? {
                backgroundImage: `url(${imageInfo.placeholderUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }
            : {},
        ]}
      >
        <img
          loading="lazy"
          src={imageInfo.url}
          srcSet={imageInfo.srcSet}
          width={imageInfo.width}
          height={imageInfo.height}
          title={image.caption && !showCaption ? originTextToString(image.caption) : undefined}
          alt={image.caption && !showCaption ? originTextToString(image.caption) : ''}
          draggable={false}
          css={[
            {
              objectFit: 'cover',
              maxWidth: 'inherit',
            },
            heightBound ? { height: '100%', width: '100%' } : { width: '100%', height: 'auto' },
          ]}
        />
        {showCaption && image.caption && (
          <div
            title={image.caption && originTextToString(image.caption)}
            css={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%)',
            }}
          >
            <OriginText
              className="type-caption1Regular text-primary"
              css={{
                margin: 16,
                overflow: 'hidden',
                display: '-webkit-box',
                lineClamp: 3,
                WebkitLineClamp: 3,
                boxOrient: 'vertical',
                WebkitBoxOrient: 'vertical',
                textShadow: '0 0 2px rgba(0, 0, 0, 0.75)',
              }}
              text={image.caption}
            />
          </div>
        )}
      </div>
      {image.attribution && (
        <div
          title={originTextToString(image.attribution)}
          css={{
            width: '100%',
          }}
        >
          <OriginText
            className="type-attribution text-primary"
            css={{
              margin: 16,
              overflow: 'hidden',
              display: '-webkit-box',
              lineClamp: 3,
              WebkitLineClamp: 3,
              boxOrient: 'vertical',
              WebkitBoxOrient: 'vertical',
              textShadow: '0 0 2px rgba(0, 0, 0, 0.75)',
            }}
            text={image.attribution}
          />
        </div>
      )}
    </div>
  );
};
