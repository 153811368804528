import React, { useContext, useMemo, useState } from 'react';

const WordCountDiffContext = React.createContext();

export const WordCountDiffProvider = ({ children }) => {
  const [blockWordCountDiff, setBlockWordCountDiff] = useState(0);
  const [tripWordCountDiff, setTripWordCountDiff] = useState(0);

  const value = useMemo(
    () => ({
      blockWordCountDiff,
      setBlockWordCountDiff,
      tripWordCountDiff,
      setTripWordCountDiff,
    }),
    [blockWordCountDiff, tripWordCountDiff]
  );

  return <WordCountDiffContext.Provider value={value}>{children}</WordCountDiffContext.Provider>;
};

export const useWordCountDiff = () => useContext(WordCountDiffContext);
