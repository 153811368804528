import { useEffect, useRef, useState } from 'react';

export const useOverflow = () => {
  const ref = useRef<HTMLElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (ref.current) {
        const { clientWidth, clientHeight, scrollWidth, scrollHeight } = ref.current;
        if (scrollWidth > clientWidth || scrollHeight > clientHeight) {
          setIsOverflowing(true);
        } else {
          setIsOverflowing(false);
        }
      }
    };

    window.addEventListener('resize', checkOverflow);
    // perform initial check
    checkOverflow();

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []); // Notice the empty set, this effect runs once after the initial render

  return [ref, isOverflowing];
};
