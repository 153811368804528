import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload as downloadIcon } from '@fortawesome/free-solid-svg-icons/faDownload';
import React from 'react';
import { formatDateRange, getNumberOfNights } from '@origin-dot/core';

const allowedBlocks = {
  Text: {
    match: block => block.type === 'Text',
    convert: ({ info: { formattedText, title } }) => [formattedText || title],
  },
  Header: {
    match: block => block.type === 'Header',
    convert: block => [`** ${block.info.title || block.info.formattedText} **`],
  },
  'Section Header': {
    match: block => block.type === 'Group' && block.info && block.info.kind === 'SectionHeader',
    convert: ({ info: { blocks: sectionBlocks } }) => {
      const dateHeader = sectionBlocks.find(block => block.type === 'DateHeader');
      const header = sectionBlocks.find(block => block.type === 'Header');
      const formattedRange = formatDateRange(dateHeader.info.startDate, dateHeader.info.endDate);
      const numberOfNights = getNumberOfNights(dateHeader.info.startDate, dateHeader.info.endDate);

      return [
        '----------------------',
        `* ${formattedRange} ${numberOfNights} *`,
        `* ${header.info.title || header.info.formattedText} *`,
      ];
    },
  },
  Divider: {
    match: block => block.type === 'Divider',
    convert: () => ['----------------------'],
  },
  Callout: {
    match: block => block.type === 'Card' && block.info && block.info.card && block.info.card.type === 'Callout',
    convert: block => [block.info.card.info.title, block.info.card.info.formattedText],
  },
};

export const DownloadText = ({ blocks, type }) => {
  const download = fileName => {
    const content = blocks.reduce((result, block) => {
      const matchedBlock = Object.values(allowedBlocks).find(({ match }) => match(block));
      if (matchedBlock) {
        result.push(...matchedBlock.convert(block).filter(Boolean));
      }
      return result;
    }, []);
    const element = document.createElement('a');
    const file = new Blob([content.join('\r')], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.txt`;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <button className="hover:text-textColor px-1 cursor-pointer" rel="noreferrer" onClick={() => download(type)}>
      <FontAwesomeIcon icon={downloadIcon} /> Download text
    </button>
  );
};
