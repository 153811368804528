import { LegacyRef, useState } from 'react';
import { cx } from '@emotion/css';
import type { CalloutCard as CalloutCardType } from '@origin-dot/core';

import { CardContainer, OriginRichText, OriginText } from '../common';
import { CardComponentProps } from '../helpers/types';
import { useOverflow } from '../helpers/useOverflow';

/** A message to get attention, with a colorful emoji. */
export const CalloutCard = ({
  className = '',
  emoji,
  formattedText,
  title,
  collapsible,
}: CardComponentProps<CalloutCardType>) => {
  const [ref, isOverflow] = useOverflow();

  const [collapsed, setCollapsed] = useState(true);
  return (
    <CardContainer className={className} css={{ position: 'relative' }}>
      {isOverflow && collapsible && (
        <button
          type="button"
          css={{ position: 'absolute', top: collapsed ? 5 : 10, right: 10 }}
          className={cx(className, 'arrow', collapsed ? 'down' : 'up')}
          onClick={() => setCollapsed(!collapsed)}
        />
      )}
      <div css={{ display: 'flex' }}>
        {emoji && (
          <div className="type-title3" css={{ lineHeight: 1, marginRight: 16 }}>
            {emoji}
          </div>
        )}
        <div>
          {title && (
            <OriginText className="type-title3 text-primary" css={{ lineHeight: 1, marginBottom: 8 }} text={title} />
          )}
          <div
            ref={ref as LegacyRef<HTMLDivElement>}
            css={
              collapsible && collapsed
                ? {
                    overflow: 'hidden',
                    display: '-webkit-box',
                    '-webkit-box-orient': 'vertical',
                    '-webkit-line-clamp': '6',
                    'text-overflow': 'ellipsis',
                  }
                : {}
            }
          >
            <OriginRichText className="type-footnote text-secondary" text={formattedText} />
          </div>
        </div>
      </div>
    </CardContainer>
  );
};
