import { createContext, useContext, useEffect, useState } from 'react';
import { cx } from '@emotion/css';

import { Stylable, WithChildren } from '../helpers/types';

declare global {
  interface Window {
    __setMode: (mode: Mode) => void;
  }
}

export const DarkModeScript = () => {
  const code = `
    document.addEventListener('DOMContentLoaded', function () {
      function setMode(mode) {
        if (mode === 'dark') document.documentElement.classList.add('darkmode');
        else document.documentElement.classList.remove('darkmode');
        window.dispatchEvent(new CustomEvent('mode', { detail: mode }));
      };
      try {
        var preferredMode = localStorage.getItem('mode');
      } catch (err) {}
      window.__setMode = function (mode) {
        setMode(mode);
        try {
          localStorage.setItem('mode', mode);
        } catch (err) {}
      };
      const q = window.matchMedia('(prefers-color-scheme: dark)');
      setMode(preferredMode || (document.documentElement.classList.contains('darkmode') || q.matches ? 'dark' : 'light'));
      q.addListener(function(e) {
        window.__setMode(e.matches ? 'dark' : 'light');
      });
    });
  `
    .replace(/\n\s*/g, ' ')
    .trim();

  // eslint-disable-next-line react/no-danger
  return <script dangerouslySetInnerHTML={{ __html: code }} />;
};

export const toggleDarkMode = () => {
  // eslint-disable-next-line no-underscore-dangle
  window.__setMode(document.documentElement.classList.contains('darkmode') ? 'light' : 'dark');
};

export type Mode = 'light' | 'dark';

const ModeContext = createContext<Mode | undefined>(undefined);

export const useDarkMode = () => {
  const modeOverride = useContext(ModeContext);
  const [documentMode, setDocumentMode] = useState<Mode>(() =>
    typeof window !== 'undefined' && document.documentElement.classList.contains('darkmode') ? 'dark' : 'light'
  );

  useEffect(() => {
    const handleMode: EventListener = (event: CustomEventInit<Mode>) => {
      if (event.detail) setDocumentMode(event.detail);
    };
    window.addEventListener('mode', handleMode);
    return () => window.removeEventListener('mode', handleMode);
  }, []);

  return (modeOverride ?? documentMode) === 'dark';
};

export const DarkModeContainer = ({ className, ...props }: Stylable<WithChildren>) => (
  <ModeContext.Provider value="dark">
    <div className={cx(className, 'darkmode')} {...props} />
  </ModeContext.Provider>
);

export const LightModeContainer = ({ className, ...props }: Stylable<WithChildren>) => (
  <ModeContext.Provider value="light">
    <div className={cx(className, 'lightmode')} {...props} />
  </ModeContext.Provider>
);
