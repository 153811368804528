import type { ViewStoryBlock as ViewStoryBlockType } from '@origin-dot/core';

import { BlockContainer } from '../common/BlockContainer';
import { Button } from '../common/Button';
import { useTrip } from '../common/TripProvider';
import type { BlockComponentProps } from '../helpers/types';

export const ViewStoryBlock = ({ className }: BlockComponentProps<ViewStoryBlockType>) => {
  const tripContext = useTrip();
  if (!tripContext) return null;

  const { trip, onViewStory } = tripContext;
  if (!trip) return null;

  let caption;
  let disabled = false;

  if (trip.status === 'PREPARING') {
    caption = 'Preparing full story…';
    disabled = true;
  } else if (trip.status === 'READY' || trip.status === 'BOOKED') {
    caption = 'View full story';
  } else {
    return null;
  }

  return (
    <BlockContainer className={className}>
      <Button
        className="darkmode"
        style="secondary"
        disabled={disabled}
        css={{ width: '100%' }}
        onClick={(event) => {
          event.preventDefault();
          if (onViewStory) onViewStory();
        }}
      >
        {caption}
      </Button>
    </BlockContainer>
  );
};
