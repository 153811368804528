import { cx } from '@emotion/css';

import { Stylable, WithChildren } from '../helpers/types';

/** Rounded rectangle with shadow that serves as a basis for (most) cards. */
export const CardContainer = ({ className, ...props }: Stylable<WithChildren>) => (
  <div
    className={cx(className, 'shadow-card')}
    css={{
      backgroundColor: 'var(--cardElevatedPrimaryColor)',
      borderRadius: 12,
      padding: 16,
    }}
    {...props}
  />
);
