import React, { Children, cloneElement, useState } from 'react';

const calculateGcd = (a, b) => (b ? calculateGcd(b, a % b) : a);

const getReducedFraction = (numerator, denominator) => {
  const gcd = calculateGcd(numerator, denominator);
  return `${numerator / gcd}/${denominator / gcd}`;
};

const DefaultTitle = ({ children }) => <div>{children}</div>;

export const LayoutColumn = ({ relativeWidth, title, compactTitle, ...props }) => <div {...props}></div>;

export const Layout = ({
  hasCollapsibleSidebar = false,
  isSidebarOpen = false,
  sidebarBreakpoint = 'lg',
  columnBreakpoint = 'md',
  titleComponent: TitleComponent = DefaultTitle,
  className,
  children,
}) => {
  const [columnIndex, setColumnIndex] = useState(0);

  // Elements should be only <LayoutColumn> components
  const elements = Children.toArray(children);
  const widths = elements.map(element => element.props.relativeWidth || 1);
  const totalWidth = widths.reduce((a, b) => a + b, 0);

  let sidebar;
  let sidebarWidth = 0;
  let columnElements = elements;
  let columnWidths = widths;

  if (hasCollapsibleSidebar) {
    let sidebarElement = null;
    [sidebarElement, ...columnElements] = elements;
    [sidebarWidth, ...columnWidths] = widths;

    let sidebarClassName = 'flex-none max-h-full flex-col overflow-hidden';
    sidebarClassName += ` ${sidebarBreakpoint}:flex ${sidebarBreakpoint}:w-${getReducedFraction(
      sidebarWidth,
      totalWidth
    )}`;
    sidebarClassName += isSidebarOpen ? ' flex' : ' hidden';
    if (sidebarElement.props.className) sidebarClassName += ` ${sidebarElement.props.className}`;
    const sidebarTitle = <TitleComponent>{sidebarElement.props.title}</TitleComponent>;
    sidebar = cloneElement(sidebarElement, {
      className: sidebarClassName,
      children: Children.toArray([sidebarTitle, sidebarElement.props.children]),
    });
  }

  const columns = columnElements.map((columnElement, index) => {
    const columnWidth = columnWidths[index];
    let columnClassName = 'w-full flex-col';
    columnClassName += ` ${columnBreakpoint}:w-${getReducedFraction(columnWidth, totalWidth - sidebarWidth)}`;
    columnClassName += index === columnIndex ? ' flex' : ` hidden ${columnBreakpoint}:flex`;
    if (columnElement.props.className) columnClassName += ` ${columnElement.props.className}`;
    const columnTitle = (
      <TitleComponent className={`hidden ${columnBreakpoint}:inline`}>{columnElement.props.title}</TitleComponent>
    );
    return cloneElement(columnElement, {
      className: columnClassName,
      children: Children.toArray([columnTitle, columnElement.props.children]),
    });
  });

  let switcher;
  if (columnElements.length > 0) {
    const otherColumnElements = [...columnElements];
    const [selectedColumnElement] = otherColumnElements.splice(columnIndex, 1);
    switcher = (
      <div className={`flex ${columnBreakpoint}:hidden flex-none items-end`}>
        <TitleComponent className="flex-grow">{selectedColumnElement.props.title}</TitleComponent>
        {otherColumnElements.map((columnElement, index) => {
          const originalIndex = index + (index >= columnIndex ? 1 : 0);
          return (
            <button
              key={originalIndex}
              onClick={() => {
                setColumnIndex(originalIndex);
              }}
            >
              <TitleComponent isToggleButton>
                {columnElement.props.compactTitle || columnElement.props.title}
              </TitleComponent>
            </button>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className={`${className || ''} overflow-hidden flex flex-col ${sidebar ? `${sidebarBreakpoint}:flex-row` : ''}`}
    >
      {sidebar}
      {switcher}
      <div
        className={`flex-grow flex overflow-hidden ${
          sidebar ? `${sidebarBreakpoint}:w-${getReducedFraction(totalWidth - sidebarWidth, totalWidth)}` : 'w-full'
        }`}
      >
        {columns}
      </div>
    </div>
  );
};
