import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDocument } from 'react-firebase-hooks/firestore';

import { firestore } from '../../firebase';
import { WordCountDiffProvider } from '../../hooks/useWordCountDiff';

import { Layout, LayoutColumn } from '../Layout';
import { ColumnTitle } from '../ColumnTitle';
import { PageTitle } from '../PageTitle';
import { ImageCropProvider } from '../ImageCropProvider';

import { TripRequestInfo } from './TripRequestInfo';
import { TripRequestFeed } from './TripRequestFeed';
import { TripRequestQuestionnaires } from '../Questionnaires';
import { TripDisplay } from '../wysiwyg/shared';
import { TripRequestChat } from '../wysiwyg/TripRequestChat';
import { useAuth } from '../auth/useAuth';

export const TripRequestPage = ({ isSidebarOpen }) => {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isDraft = query.has('draft');

  const { tripRequestId, view } = useParams();
  let { tripId } = useParams();
  const isOnQuestionnaires = tripId === 'questionnaires';
  if (isOnQuestionnaires) tripId = undefined;
  const isOnStory = view === 'story';

  const liveReference = useMemo(() => firestore.collection('tripRequests').doc(tripRequestId), [tripRequestId]);
  const draftReference = useMemo(() => firestore.collection('draftTripRequests').doc(tripRequestId), [tripRequestId]);
  const tripRequestQuestionnaireReference = useMemo(
    () => firestore.collection('tripRequests').doc(tripRequestId).collection('questionnaireSubmissions').doc('current'),
    [tripRequestId]
  );
  const userOOOChatSettingsReference = useMemo(
    () => firestore.collection('users').doc(auth.userId).collection('oooChatSettings').doc('current'),
    [auth.userId]
  );

  const [liveTripRequestSnapshot] = useDocument(liveReference);
  const [draftTripRequestSnapshot] = useDocument(draftReference);
  const [tripRequestQuestionnaireSnapshot] = useDocument(tripRequestQuestionnaireReference);
  const [userOOOChatSettingsSnapshot] = useDocument(userOOOChatSettingsReference);

  useEffect(() => {
    if (isDraft && draftTripRequestSnapshot && !draftTripRequestSnapshot.exists) {
      // We don't have a draft (anymore) so redirect to client view.
      history.replace({ search: '' });
    }
  }, [history, isDraft, draftTripRequestSnapshot]);

  const tripRequestSnapshot =
    isDraft && draftTripRequestSnapshot && draftTripRequestSnapshot.exists
      ? draftTripRequestSnapshot
      : liveTripRequestSnapshot;

  const tripReference = useMemo(
    () => (tripId && tripRequestSnapshot ? tripRequestSnapshot.ref.collection('trips').doc(tripId) : null),
    [tripRequestSnapshot, tripId]
  );

  const tripRequestQuestionnaireData =
    tripRequestQuestionnaireSnapshot &&
    tripRequestQuestionnaireSnapshot.exists &&
    tripRequestQuestionnaireSnapshot.data();

  if (!liveTripRequestSnapshot || !draftTripRequestSnapshot) return null;

  let mainTitle;
  if (tripId) {
    mainTitle = isOnStory ? 'Full Story' : 'Trip Preview';
  } else {
    mainTitle = 'Trip Request Feed';
  }

  return (
    <WordCountDiffProvider>
      <PageTitle title="Trip Request" />
      <Layout className="flex-grow" hasCollapsibleSidebar isSidebarOpen={isSidebarOpen} titleComponent={ColumnTitle}>
        <LayoutColumn relativeWidth={1} title="Trip Request">
          <TripRequestInfo
            tripRequestSnapshot={tripRequestSnapshot}
            tripReference={tripReference}
            hasDraft={draftTripRequestSnapshot.exists}
            draftStatus={draftTripRequestSnapshot.get('status')}
            liveStatus={liveTripRequestSnapshot.get('status')}
            hasQuestionnaires={!!tripRequestQuestionnaireData}
            userOOOChatSettingsSnapshot={userOOOChatSettingsSnapshot}
          />
        </LayoutColumn>
        {isOnQuestionnaires && tripRequestQuestionnaireData ? (
          <LayoutColumn relativeWidth={4} title="Trip Request Questionnaires" compactTitle="Questionnaires">
            <TripRequestQuestionnaires
              tripRequestQuestionnaire={tripRequestQuestionnaireData}
              tripRequestSnapshot={tripRequestSnapshot}
            />
          </LayoutColumn>
        ) : (
          <LayoutColumn relativeWidth={4} title={mainTitle} compactTitle="Itinerary">
            <ImageCropProvider>
              {tripId ? (
                <TripDisplay
                  tripRequestId={tripRequestId}
                  tripReference={tripReference}
                  isStory={isOnStory}
                  isBlueprintable={isOnStory}
                />
              ) : (
                <TripRequestFeed tripRequestReference={tripRequestSnapshot.ref} />
              )}
            </ImageCropProvider>
          </LayoutColumn>
        )}
      </Layout>
      {tripId && <TripRequestChat tripRequestId={tripRequestId} />}
    </WordCountDiffProvider>
  );
};
