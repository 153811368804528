import { Agent as AgentType, originText } from '@origin-dot/core';

import { Stylable } from '../helpers/types';
import { Avatar } from './Avatar';
import { useProfile } from './ProfileProvider';

const defaultSubTitle = originText`Your travel curator`;

type AgentProps = Stylable<AgentType & { border?: boolean }>;

export const Agent = ({ className, id, subTitle, cachedDisplayName, cachedPicture, border = false }: AgentProps) => {
  const profile = useProfile(id);

  const name = profile?.displayName ?? cachedDisplayName ?? 'Origin';

  return (
    <div className={className} css={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        userId={id}
        cachedDisplayName={cachedDisplayName}
        cachedPicture={cachedPicture}
        size={36}
        border={border}
      />
      <div css={{ display: 'flex', flexDirection: 'column', justifyItems: 'center', marginLeft: 12 }}>
        <div className="type-subhead" css={{ lineHeight: 1 }}>
          {name}
        </div>
        <div className="type-caption1Regular text-secondary" css={{ lineHeight: 1, marginTop: 4 }}>
          {subTitle ?? defaultSubTitle}
        </div>
      </div>
    </div>
  );
};
