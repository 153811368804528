import { Global } from '@emotion/react';

import { arrows, colors, fonts, reset, shadows } from '../styles';

export const GlobalStyles = () => (
  <Global
    styles={[
      // Order is important here.
      reset,
      fonts,
      colors,
      shadows,
      arrows,
    ]}
  />
);
