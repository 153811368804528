import type { HexColor, MapboxStyle } from '@origin-dot/core';
import { Icon } from '@origin-dot/core';
import { Map } from 'mapbox-gl';

import { IconDefinition, icons } from '../../icons';

const defaultIcon = 'pin';

const balloonIcon: IconDefinition = {
  path: 'M28,14 C28,21 22.61,26.333333 18.1667,28 C15.6667,29.6667 14.8333,33 14,33 C13.1667,33 12.3333,29.6667 9.83333,28 C5.39,26.3333 0,21 0,14 C0,6.272 6.272,0 14,0 C21.728,0 28,6.272 28,14',
  width: 28,
  height: 33,
};

// Large balloon icon that iOS app uses for selected points.
// const balloonIcon: IconDefinition = {
//   path:
//     'M56,28 C56,42 45.22,54 33,56 C30,58 29,62 28,62 C27,62 26,58 23,56 C10.78,54 0,42 0,28 C0,12.544 12.544,0 28,0 C43.456,0 56,12.544 56,28',
//   width: 56,
//   height: 62,
// };

type IconType = 'balloon' | 'roundedrect';

export type IconImage = {
  icon: Icon;
  type: IconType;
};

const imageForIcon = async (
  icon: IconDefinition,
  type: IconType,
  style: MapboxStyle,
  size: number
): Promise<HTMLImageElement> => {
  let width: number;
  let height: number;
  let container: string;
  let color: HexColor;
  let transform: string;

  switch (type) {
    case 'balloon': {
      width = size;
      height = size * (balloonIcon.height / balloonIcon.width);

      const iconRatio = 0.9;
      const iconScale = (size * iconRatio) / icon.width;
      transform = `translate(${0.5 * (1 - iconRatio) * size}, ${
        0.5 * (1 - iconRatio) * size + 0.5 * iconScale * (icon.width - icon.height)
      }) scale(${iconScale})`;
      color = style.pinText;

      const balloonScale = size / balloonIcon.width;
      container = `<path d="${balloonIcon.path}" fill="${style.pin}" transform="scale(${balloonScale})" />`;
      break;
    }
    default:
    case 'roundedrect': {
      width = size * 3.6;
      height = size;

      const iconRatio = 0.9;
      const iconScale = (size * iconRatio) / icon.height;
      transform = `translate(${0.25 * size}, ${0.5 * (1 - iconRatio) * size}) scale(${iconScale})`;
      color = style.lineText;

      const cornerRadius = 0.25 * height;
      container = `<rect width="${width}" height="${height}" rx="${cornerRadius}" fill="${style.line}" stroke="${style.lineText}" stroke-width="1" />`;
      break;
    }
  }

  const svg = `
    <svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" fill="none" xmlns="http://www.w3.org/2000/svg">
      ${container}
      <path fillRule="evenodd" clip-rule="evenodd" d="${icon.path}" transform="${transform}" fill="${color}" />
    </svg>
  `;

  return new Promise((resolve) => {
    const base64 = btoa(svg);
    const image = new Image(width, height);
    image.onload = () => resolve(image);
    image.src = `data:image/svg+xml;base64,${base64}`;
  });
};

export const getIconImageId = (icon: keyof typeof icons = defaultIcon, type: IconType) => `origin-${icon}-${type}`;

export const ensureIconImage = async (
  map: Map,
  style: MapboxStyle,
  icon: keyof typeof icons = defaultIcon,
  type: IconType
) => {
  const id = getIconImageId(icon, type);
  if (map.hasImage(id)) return;

  let size;
  switch (type) {
    case 'balloon':
      size = 50;
      break;

    default:
    case 'roundedrect':
      size = 40;
  }

  const image = await imageForIcon(icons[icon], type, style, size);

  if (map.hasImage(id)) return;
  map.addImage(id, image, { pixelRatio: 2 });
};
