import { CSSProperties } from 'react';
import type { HeaderBlock as HeaderBlockType } from '@origin-dot/core';

import { BlockContainer } from '../common/BlockContainer';
import { OriginText } from '../common/OriginText';
import { BlockComponentProps } from '../helpers/types';

export const HeaderBlock = ({
  className,
  title,
  style = 'main',
  hasTopPadding = true,
  hasAdditionalTopPadding = false,
  hasBottomPadding = true,
}: BlockComponentProps<HeaderBlockType>) => {
  const titleClassName = style === 'sub' ? 'type-title3 text-primary' : 'type-title2 text-primary';

  let paddingTop: CSSProperties['paddingTop'] = style === 'sub' ? 32 : 16;
  let paddingBottom: CSSProperties['paddingBottom'] = 4;

  if (hasTopPadding === false) {
    paddingTop = 0;
  } else if (hasAdditionalTopPadding === true) {
    paddingTop += 32;
  }

  if (hasBottomPadding === false) {
    paddingBottom = 0;
  }

  return (
    <BlockContainer className={className} css={{ paddingTop, paddingBottom }}>
      <OriginText className={titleClassName} text={title} />
    </BlockContainer>
  );
};
