import { Stylable } from '../helpers/types';
import { IconName, icons } from '../icons';

type IconProps = Stylable<{
  name: IconName;
  size?: number;
}>;

export const Icon = ({ className, name, size = 32 }: IconProps) => {
  const icon = icons[name];
  if (!icon) return null;

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 ${icon.width} ${icon.height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d={icon.path} />
    </svg>
  );
};
