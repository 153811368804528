import React from 'react';

import { FormattedText } from '../FormattedText';

export const NotificationCard = ({ info }) => {
  const { formattedText } = info;
  return (
    <div className="-mx-4 -my-2 darkmode bg-oldBackgroundColor text-textColor p-4 text-xs font-bold text-center">
      <FormattedText source={formattedText} />
    </div>
  );
};
