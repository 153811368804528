import React, { useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone as copyIcon } from '@fortawesome/free-solid-svg-icons/faClone';
import { faCheck as copyDoneIcon } from '@fortawesome/free-solid-svg-icons/faCheck';

export const CopyButton = ({ value, className }) => {
  const timeoutRef = useRef();
  const [isCopied, setCopied] = useState(false);

  const handleClick = event => {
    event.preventDefault();

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    (async () => {
      try {
        await navigator.clipboard.writeText(value);
        setCopied(true);
        timeoutRef.current = setTimeout(() => {
          setCopied(false);
          timeoutRef.current = null;
        }, 1000);
      } catch (err) {
        setCopied(false);
      }
    })();
  };

  return (
    <button className={`${className || ''} text-xs max-w-full truncate`} onClick={handleClick}>
      <FontAwesomeIcon icon={isCopied ? copyDoneIcon : copyIcon} size="sm" fixedWidth />
      <span className="ml-1">{value}</span>
    </button>
  );
};
