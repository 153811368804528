import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import moment from 'moment';

import { Avatar } from '@origin-dot/components';
import { firestore } from '../../firebase';

import { Layout, LayoutColumn } from '../Layout';
import { ColumnTitle } from '../ColumnTitle';
import { PageTitle } from '../PageTitle';
import { Card } from '../card/Card';
import { PhoneFrame } from '../PhoneFrame';

import { itineraryCardForTemplate } from '../../helpers/cards';

export const PublishPage = () => {
  const { templateId } = useParams();
  const reference = useMemo(() => firestore.collection('templates').doc(templateId), [templateId]);
  const [template] = useDocumentDataOnce(reference);

  const [agentCalloutTitle, setAgentCalloutTitle] = useState('Test Title');
  const [agentCalloutFormattedText, setAgentCalloutFormattedText] = useState('Bla bla bla\nBla\n\nBla bla');
  const [feedPushMessage, setFeedPushMessage] = useState('Your proposal is ready to view!');

  const [now, setNow] = useState();
  useEffect(() => {
    const timer = setInterval(() => {
      setNow(moment());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  if (!template) return null;

  const itineraryCard = itineraryCardForTemplate(template);
  Object.assign(itineraryCard.info.trip, {
    isProposal: true,
    members: ['axKdAbPkMNXlzSwU1O2qF1FEABm1'],
  });

  const agentCalloutCard = {
    type: 'AgentCallout',
    info: {
      agent: {
        id: '7bGxpJz0TJaqDmWw1Lip455wuFk2',
        cachedDisplayName: 'Eli Bressert',
        cachedPicture: 'profiles/7bGxpJz0TJaqDmWw1Lip455wuFk2/5c972c55-12b4-498a-b957-22cdded62612.jpg',
      },
      formattedText: agentCalloutFormattedText,
      title: agentCalloutTitle,
    },
  };

  return (
    <>
      <PageTitle title={`Publish ${template.tripInfo.title}`} />
      <Layout className="flex-grow" titleComponent={ColumnTitle}>
        <LayoutColumn relativeWidth={1} title="Publish Template" compactTitle="Publish">
          <input type="text" value={agentCalloutTitle} onChange={e => setAgentCalloutTitle(e.target.value)} />
          <textarea value={agentCalloutFormattedText} onChange={e => setAgentCalloutFormattedText(e.target.value)} />
          <input type="text" value={feedPushMessage} onChange={e => setFeedPushMessage(e.target.value)} />
        </LayoutColumn>
        <LayoutColumn relativeWidth={2} title="Preview">
          <div className="flex-grow p-3 overflow-y-auto md:flex">
            <PhoneFrame className="iphone-feed">
              <div
                className="absolute text-textDimmedColor font-extrabold uppercase"
                style={{ left: 16, top: 87, fontSize: 10 }}
              >
                {now && now.format('dddd, D MMMM').toUpperCase()}
              </div>
              <Avatar
                userId="axKdAbPkMNXlzSwU1O2qF1FEABm1"
                className="absolute"
                style={{ top: 102, right: 16, width: 36, height: 36 }}
              />
              <div
                className="absolute w-full overflow-y-auto"
                style={{ top: 149, height: 585, padding: '8px 16px 32px 16px' }}
              >
                <Card card={agentCalloutCard} />
                <Card card={itineraryCard} style={{ marginTop: 16 }} />
              </div>
            </PhoneFrame>
            <PhoneFrame displayTime={false} className="iphone-push">
              <div className="absolute w-full text-center text-white font-thin" style={{ top: 85, fontSize: 80 }}>
                {now && now.format('HH:mm')}
              </div>
              <div className="absolute w-full text-center text-white font-normal" style={{ top: 187, fontSize: 22 }}>
                {now && now.format('dddd, D MMMM')}
              </div>
              <div className="absolute font-normal truncate" style={{ left: 20, right: 20, top: 276, fontSize: 15 }}>
                {feedPushMessage}
              </div>
            </PhoneFrame>
          </div>
        </LayoutColumn>
      </Layout>
    </>
  );
};
