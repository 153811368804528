import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { firestore } from '../../firebase';

import { Layout, LayoutColumn } from '../Layout';
import { ColumnTitle } from '../ColumnTitle';
import { PageTitle } from '../PageTitle';

import { TripInfo } from './TripInfo';
import { TripItinerary } from './TripItinerary';
import { TripToolbox } from './TripToolbox';

export const TripPage = ({ isSidebarOpen }) => {
  const { tripId } = useParams();
  const reference = useMemo(() => firestore.collection('trips').doc(tripId), [tripId]);
  const [trip, loading, error] = useDocumentData(reference);

  if (loading || error || !trip) return null;

  return (
    <>
      <PageTitle title={trip.title} />
      <Layout className="flex-grow" hasCollapsibleSidebar isSidebarOpen={isSidebarOpen} titleComponent={ColumnTitle}>
        <LayoutColumn relativeWidth={1} title="Trip Info">
          <TripInfo tripId={tripId} trip={trip} />
        </LayoutColumn>
        <LayoutColumn relativeWidth={2} title="Itinerary Editor" compactTitle="Itinerary">
          <TripItinerary />
        </LayoutColumn>
        <LayoutColumn relativeWidth={1} title="Toolbox">
          <TripToolbox trip={trip} />
        </LayoutColumn>
      </Layout>
    </>
  );
};
