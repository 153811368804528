import {
  DirectionsProfileExclusion,
  DirectionsResponse,
  DirectionsService,
} from '@mapbox/mapbox-sdk/services/directions';
import { MapboxStyle, MapLeg, MapLocation, originTextToString } from '@origin-dot/core';
import { along, length } from '@turf/turf';
// eslint-disable-next-line import/no-unresolved
import { Feature, FeatureCollection, LineString, Point } from 'geojson';
import { GeoJSONSource, Map } from 'mapbox-gl';

import { ensureIconImage, getIconImageId } from './icons';

const updateSource = (map: Map, sourceId: string, features: Feature[]) => {
  const data: FeatureCollection = {
    type: 'FeatureCollection',
    features,
  };

  const source = map.getSource(sourceId) as GeoJSONSource;
  if (source) source.setData(data);
  else map.addSource(sourceId, { type: 'geojson', tolerance: 1, data });
};

const ensureSource = (map: Map, sourceId: string) => {
  const source = map.getSource(sourceId);
  if (!source) updateSource(map, sourceId, []);
};

const getStraightLineGeometry = ({ start, end }: MapLeg): LineString => ({
  type: 'LineString',
  coordinates: [
    [start.location.longitude, start.location.latitude],
    [end.location.longitude, end.location.latitude],
  ],
});

const getGeodesicLineGeometry = (leg: MapLeg): LineString => {
  const geometry = getStraightLineGeometry(leg);
  const feature: Feature<LineString> = {
    type: 'Feature',
    geometry,
    properties: {},
  };

  const arc = [];
  const steps = 500;
  const lineDistance = length(feature);

  for (let i = 0; i < lineDistance; i += lineDistance / steps) {
    const segment = along(geometry, i);
    if (segment.geometry?.coordinates) {
      arc.push(segment.geometry.coordinates);
    }
  }

  return {
    type: 'LineString',
    coordinates: arc,
  };
};

const getRouteLineGeometry = async (leg: MapLeg, directions: DirectionsService | undefined) => {
  const { start, end, transportationType } = leg;

  let profile: DirectionsProfileExclusion;
  switch (transportationType) {
    case 'walking':
      profile = { profile: 'walking' };
      break;
    case 'bicycle':
      profile = { profile: 'cycling' };
      break;
    default:
      profile = { profile: 'driving' };
  }

  if (!directions) {
    return getStraightLineGeometry(leg);
  }

  const directionsResult = await directions
    .getDirections({
      ...profile,
      overview: 'full',
      geometries: 'geojson',
      waypoints: [
        { coordinates: [start.location.longitude, start.location.latitude] },
        { coordinates: [end.location.longitude, end.location.latitude] },
      ],
    })
    .send();

  const { routes } = directionsResult.body as DirectionsResponse;

  if (!routes || !routes[0]) return getStraightLineGeometry(leg);
  return routes[0].geometry;
};

const ensureLineSource = async (
  map: Map,
  source: string,
  directions: DirectionsService | undefined,
  legs: MapLeg[]
) => {
  const features = await Promise.all(
    legs.map<Promise<Feature>>(async (leg) => {
      let adjustedLeg = leg;
      const startLocation = { ...leg.start.location };
      const endLocation = { ...leg.end.location };
      if (Math.abs(startLocation.longitude - endLocation.longitude) > 180) {
        if (startLocation.longitude < 0) startLocation.longitude += 360;
        else endLocation.longitude += 360;
        adjustedLeg = {
          ...leg,
          start: { ...leg.start, location: startLocation },
          end: { ...leg.end, location: endLocation },
        };
      }

      let geometry;
      switch (leg.routeType) {
        case 'geodesic':
          geometry = getGeodesicLineGeometry(adjustedLeg);
          break;
        case 'route':
          geometry = await getRouteLineGeometry(adjustedLeg, directions);
          break;
        case 'straight':
        default:
          geometry = getStraightLineGeometry(adjustedLeg);
          break;
      }

      return {
        type: 'Feature',
        geometry,
        properties: {
          text: leg.text ? originTextToString(leg.text) : '',
          icon: getIconImageId(leg.transportationType, 'roundedrect'),
        },
      };
    })
  );

  updateSource(map, source, features);
};

const ensureLineLayers = async (map: Map, source: string, style: MapboxStyle, legs: MapLeg[]) => {
  if (!map.getLayer('origin-line')) {
    map.addLayer({
      id: 'origin-line',
      type: 'line',
      source,
      layout: {
        'line-cap': 'round',
      },
      paint: {
        'line-color': style.line,
        'line-width': 4,
      },
    });
  }

  const icons = Array.from(new Set(legs.map(({ transportationType }) => transportationType)));
  await Promise.all(icons.map(async (icon) => ensureIconImage(map, style, icon, 'roundedrect')));
  for (const icon of icons) {
    // Use the same ID for the layers.
    const id = getIconImageId(icon, 'roundedrect');

    if (!map.getLayer(id)) {
      map.addLayer({
        id,
        type: 'symbol',
        source,
        filter: ['==', 'icon', id],

        layout: {
          'icon-image': id,
          'icon-keep-upright': true,
          'icon-rotation-alignment': 'viewport',
          'icon-allow-overlap': true,
          'symbol-placement': 'line-center',
          'text-field': '{text}',
          'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
          'text-letter-spacing': 0.05,
          'text-offset': [1, 0],
          'text-rotation-alignment': 'viewport',
          'text-size': 10,
        },
        paint: {
          'text-color': style.lineText,
        },
      });
    }
  }
};

const ensurePinSource = (map: Map, source: string, pins: MapLocation[]) => {
  const features = pins.map<Feature>(
    ({ icon, location, title, subTitle }): Feature<Point> => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [location.longitude, location.latitude],
      },
      properties: {
        icon: getIconImageId(icon, 'balloon'),
        // Make sure there's a space in the end.
        text: `${title ? originTextToString(title) : ''}\n${subTitle ? originTextToString(subTitle) : ''} `,
      },
    })
  );

  updateSource(map, source, features);
};

const ensurePinLayers = async (map: Map, source: string, style: MapboxStyle, pins: MapLocation[]) => {
  const icons = Array.from(new Set(pins.map(({ icon }) => icon)));
  await Promise.all(icons.map(async (icon) => ensureIconImage(map, style, icon, 'balloon')));
  for (const icon of icons) {
    // Use the same ID for the layers.
    const id = getIconImageId(icon, 'balloon');

    if (!map.getLayer(id)) {
      map.addLayer({
        id,
        type: 'symbol',
        source,
        filter: ['==', 'icon', id],
        layout: {
          'icon-image': id,
          'icon-offset': [0, -14],
          'icon-allow-overlap': true,
          'text-field': '{text}',
          'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
          'text-letter-spacing': 0.05,
          'text-offset': [0, 1.3],
          'text-optional': true,
          'text-size': 11,
        },
        paint: {
          'text-halo-color': style.stroke,
          'text-halo-width': 1,
          'text-color': style.text,
        },
      });
    }
  }
};

const ensurePinSourceAndLayers = async (
  map: Map,
  source: string,
  style: MapboxStyle,
  legs: MapLeg[],
  points: MapLocation[]
) => {
  const pins = [...points, ...legs.flatMap(({ start, end }) => [start, end])];
  ensurePinSource(map, source, pins);
  await ensurePinLayers(map, source, style, pins);
};

export const ensureSourcesAndLayers = async (
  map: Map,
  directions: DirectionsService | undefined,
  style: MapboxStyle,
  legs: MapLeg[],
  points: MapLocation[]
) => {
  const lineSource = 'origin-lines';
  const pinSource = 'origin-pins';

  // Optimal order & concurrency.
  ensureSource(map, lineSource);
  await ensureLineLayers(map, lineSource, style, legs);
  await Promise.all([
    ensurePinSourceAndLayers(map, pinSource, style, legs, points),
    ensureLineSource(map, lineSource, directions, legs),
  ]);
};
