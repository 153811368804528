import { useEffect, useState } from 'react';

import { Stylable } from '../helpers/types';
import { toggleDarkMode, useDarkMode } from './DarkMode';
import { Icon } from './Icon';

type DarkModeToggleProps = Stylable<{
  size?: number;
  onClick?: () => void;
}>;

export const DarkModeToggle = ({ className, size = 20, onClick }: DarkModeToggleProps) => {
  const isDarkMode = useDarkMode();
  const [afterRender, setAfterRender] = useState(false);
  useEffect(() => {
    setAfterRender(true);
  }, []);

  if (!afterRender) return <div className={className} css={{ width: size, height: size }} />;

  return (
    <button type="button" className={className} onClick={onClick ?? toggleDarkMode}>
      <Icon name={isDarkMode ? 'sun' : 'moon'} size={size} />
    </button>
  );
};

// Old, animated version in pure CSS below (requires opaque background):

// export const DarkModeToggle = ({ className, onClick }: DarkModeToggleProps) => (
//   <button
//     type="button"
//     className={className}
//     css={{ display: 'block', width: 24, height: 24 }}
//     onClick={onClick ?? toggleDarkMode}
//   >
//     <div
//       css={css`
//         width: 20px;
//         height: 20px;
//         left: 50%;
//         margin-left: -10px;
//         border-radius: 50%;
//         background-color: var(--textPrimaryColor);
//         transition: all 350ms ease;
//         position: relative;
//         transform: scale(1);

//         &::before {
//           content: '';
//           position: absolute;
//           width: 18px;
//           height: 18px;
//           left: 9px;
//           top: -5px;
//           background-color: var(--backgroundColor);
//           border-radius: 50%;
//           transition: left 350ms ease, top 350ms ease;
//         }

//         &::after {
//           content: '';
//           position: absolute;
//           width: 8px;
//           height: 8px;
//           left: 50%;
//           top: 50%;
//           margin-left: -4px;
//           margin-top: -4px;
//           border-radius: 50%;
//           box-shadow: var(--textPrimaryColor) 0 -18px 0, var(--textPrimaryColor) 12px -12px 0,
//             var(--textPrimaryColor) 18px 0 0, var(--textPrimaryColor) 12px 12px 0, var(--textPrimaryColor) 0 18px 0,
//             var(--textPrimaryColor) -12px 12px 0, var(--textPrimaryColor) -18px 0 0,
//             var(--textPrimaryColor) -12px -12px 0;
//           background-color: rgba(0, 0, 0, 0);
//           transform: scale(0);
//           transition: all 350ms ease;
//         }

//         .darkmode & {
//           transform: scale(0.5);
//         }

//         .darkmode &::before {
//           left: 19px;
//           top: -15px;
//         }

//         .darkmode &::after {
//           transform: scale(1);
//         }
//       `}
//     />
//   </button>
// );
