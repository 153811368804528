import React, { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { InstantSearch, Configure } from 'react-instantsearch';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch as searchIcon } from '@fortawesome/free-solid-svg-icons/faSearch';

import { useAlgolia } from '../hooks/useAlgolia';

import { AutoComplete } from './AutoComplete';
import { UserSearchResultWithLink } from './user/UserSearchResult';
import { TripSearchResultWithLink } from './trip/TripSearchResult';

const UniversalSearchContainer = forwardRef(function UniversalSearchContainer({ isEmpty, children, ...props }, ref) {
  return (
    <div ref={ref} className={`w-full mt-1 shadow bg-cardBackgroundColor rounded-lg overflow-hidden`} {...props}>
      {children}
    </div>
  );
});

const UniversalSearchResult = ({ data, isHighlighted }) => {
  const ResultComponent = (() => {
    switch (data.indexType) {
      case 'user':
        return UserSearchResultWithLink;
      case 'trip':
        return TripSearchResultWithLink;
      default:
        return null;
    }
  })();

  if (!ResultComponent) return null;

  return (
    <div key={data.objectID} className={`cursor-pointer ${isHighlighted ? 'bg-dividerColor highlighted' : ''}`}>
      <ResultComponent data={data} isHighlighted={isHighlighted} />
    </div>
  );
};

const adminPathForObject = suggestion => {
  switch (suggestion.indexType) {
    case 'user':
      return `/${suggestion.objectID.replace(':', 's/')}`;
    case 'trip':
      return `/${suggestion.objectID.replace('trip', 'tripRequest').replace(':', 's/').replace(/:/g, '/')}`;
    default:
      return '/';
  }
};

export const UniversalSearch = () => {
  const history = useHistory();
  const algolia = useAlgolia();

  return (
    <div className="relative w-full">
      <InstantSearch searchClient={algolia} indexName={`${process.env.ALGOLIA_INDEX_PREFIX}admin_universal`}>
        <Configure hitsPerPage={10} />
        <AutoComplete
          className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg py-2 pl-10 pr-2 block w-full appearance-none leading-normal ds-input"
          placeholder="Universal search (Press / to focus)"
          ResultComponent={UniversalSearchResult}
          ContainerComponent={UniversalSearchContainer}
          postHogEvent="selected_universalsearch_result"
          onSelect={suggestion => {
            history.push(adminPathForObject(suggestion));
          }}
        />
      </InstantSearch>
      <div className="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
        <FontAwesomeIcon icon={searchIcon} className="text-textDimmedColor" />
      </div>
    </div>
  );
};
