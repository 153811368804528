import React, { useState } from 'react';

import { firestore } from '../../firebase';
import { getLocalTime } from '../../helpers/time';

import { CopyButton } from '../CopyButton';
import { Image } from '../Image';

import { TripMember } from './TripMember';

export const TripInfo = ({ tripId, trip }) => {
  const [isDropping, setDropping] = useState(false);

  const startTime = getLocalTime(trip.startTime);
  const endTime = getLocalTime(trip.endTime);

  const dateTimeFormat = 'YYYY-MM-DD HH:mm';

  return (
    <div className="flex-grow p-3 overflow-y-auto">
      <div className="flex flex-col items-center bg-cardBackgroundColor p-3 rounded shadow">
        <h2 className="text-xl font-semibold w-full text-center truncate">{trip.title}</h2>
        <CopyButton className="w-40 text-mainTintColor" value={tripId} />
        <div className="relative rounded-lg overflow-hidden mt-3 w-full max-w-sm">
          <Image path={trip.image.path} aspect={343 / 412} className="bg-regularButtonBackgroundColor" />
          <div
            className={`${isDropping ? 'bg-black opacity-50' : ''} absolute top-0 w-full h-full`}
            onDragOver={event => {
              event.preventDefault();
              setDropping(true);
            }}
            onDragLeave={() => {
              setDropping(false);
            }}
            onDrop={event => {
              setDropping(false);
              const image = event.dataTransfer.getData('image');
              firestore.collection('trips').doc(tripId).set({ image }, { merge: true });
            }}
          />
        </div>
      </div>
      <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow">
        <div className="">Start: {startTime.format(dateTimeFormat)}</div>
        <div className="mt-1">End: {endTime.format(dateTimeFormat)}</div>
        {/* XXX <div className="mt-2">Continent: {trip.destination.continent}</div>
        <div className="mt-1">Country: {trip.destination.country}</div> */}
      </div>
      <div className="grid grid-cols-1 gap-2 bg-cardBackgroundColor mt-3 p-3 rounded shadow">
        {(trip.members || []).map(userId => (
          <TripMember key={userId} userId={userId} />
        ))}
      </div>
    </div>
  );
};
