import { cx } from '@emotion/css';
import { CalendarDate, formatCalendarDate, getNumberOfDays, Trip } from '@origin-dot/core';

import { DividerBlock } from '../blocks/DividerBlock';
import { Stylable } from '../helpers/types';
import { Agent } from './Agent';
import { Avatar } from './Avatar';
import { AvatarList } from './AvatarList';
import { BlockContainer } from './BlockContainer';
import { CdnConfig, useComponentConfig } from './ComponentsConfigProvider';
import { BackgroundImgInfo, generateBackgroundImageInfo } from './imageHelper';
import { OriginRichText } from './OriginRichText';
import { OriginText } from './OriginText';
import { TripProvider } from './TripProvider';

type TripDatesProps = Stylable<{ startDate: CalendarDate; endDate: CalendarDate }>;

const TripDates = ({ startDate, endDate }: TripDatesProps) => {
  const topRowProps = { className: 'type-caption1Bold text-quarternary' };
  const middleRowProps = { className: 'type-headline', css: { padding: '5px 0' } };
  const bottomRowProps = { className: 'type-caption1Regular text-quarternary' };

  const numberOfDays = getNumberOfDays(startDate, endDate);

  return (
    <BlockContainer extended css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 100 }}>
        <div {...topRowProps}>{formatCalendarDate(startDate, 'cccc')}</div>
        <div {...middleRowProps}>{formatCalendarDate(startDate, 'dd MMM')}</div>
        <div {...bottomRowProps}>{formatCalendarDate(startDate, 'yyyy')}</div>
      </div>
      <div className="type-headline" css={{ color: 'var(--separatorColor)' }}>
        /
      </div>
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 100 }}>
        <div {...topRowProps}>{formatCalendarDate(endDate, 'cccc')}</div>
        <div {...middleRowProps}>{formatCalendarDate(endDate, 'dd MMM')}</div>
        <div {...bottomRowProps}>{formatCalendarDate(endDate, 'yyyy')}</div>
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 100,
          borderLeftWidth: 1,
          borderLeftColor: 'var(--separatorColor)',
        }}
      >
        <div {...topRowProps}>Duration</div>
        <div {...middleRowProps}>{numberOfDays}</div>
        <div {...bottomRowProps}>{numberOfDays === 1 ? 'Day' : 'Days'}</div>
      </div>
    </BlockContainer>
  );
};

type TripHeaderProps = Stylable<
  Trip & {
    onViewStory?: () => void;
  }
>;

export const TripHeader = ({ className, onViewStory, ...trip }: TripHeaderProps) => {
  const { title, subtitle, image, formattedIntro, users, agent, author, startDate, endDate } = trip;
  const displayAuthor = author ?? agent;
  const config = useComponentConfig();
  const cdnProvider = config?.cdnProvider || 'none';
  const cdnConfig: undefined | CdnConfig = config && config.cdnConfig;

  const imageInfo: BackgroundImgInfo = generateBackgroundImageInfo(image, cdnProvider, cdnConfig);

  const backgroundImage = `linear-gradient(rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 1) 100%), ${
    imageInfo.imageSet ? imageInfo.imageSet : `url(${imageInfo.url})`
  }${imageInfo.placeholderUrl ? `, url(${imageInfo.placeholderUrl})` : ''}`;

  return (
    <TripProvider trip={trip} onViewStory={onViewStory}>
      <div className={cx(className, 'darkmode')}>
        <div
          css={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: 'calc(100vh - 180px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
          // We have to use a custom style tag to bypass the vendor prefixing that fails with nested parenthesis in emotion.js.
          // image-set is now fully supported
          style={{ backgroundImage: `${backgroundImage}` }}
        >
          {users && users.length > 0 && (
            <BlockContainer css={{ display: 'flex', justifyContent: 'center' }}>
              <AvatarList>
                {users.map((userId, index) => (
                  <Avatar key={index} userId={userId} />
                ))}
              </AvatarList>
            </BlockContainer>
          )}
          {title && (
            <BlockContainer extended className={className}>
              <OriginText className="type-largeTitle" css={{ textAlign: 'center' }} text={title} />
            </BlockContainer>
          )}
          {subtitle && (
            <BlockContainer extended className={className}>
              <OriginRichText className="type-body text-primary" css={{ textAlign: 'center' }} text={subtitle} />
            </BlockContainer>
          )}
          {startDate && endDate && <TripDates startDate={startDate} endDate={endDate} />}
        </div>
        <div css={{ paddingBottom: 48 }}>
          {(formattedIntro || displayAuthor) && <DividerBlock style="fullButWithLessTopPadding" />}
          {formattedIntro && (
            <BlockContainer extended className={className}>
              <OriginRichText className="type-body text-primary" text={formattedIntro} />
            </BlockContainer>
          )}
          {displayAuthor && (
            <BlockContainer extended>
              <Agent {...displayAuthor} border />
            </BlockContainer>
          )}
        </div>
      </div>
    </TripProvider>
  );
};
