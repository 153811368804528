import type { AgentCalloutCard as AgentCalloutCardType } from '@origin-dot/core';

import { Agent, Button, CardContainer, OriginRichText, OriginText } from '../common';
import { CardComponentProps, Overridable } from '../helpers/types';

/** A message together with an agent name & picture. */
export const AgentCalloutCard = ({
  className,
  formattedText,
  agent,
  title,
  action,
  onClick,
}: Overridable<CardComponentProps<AgentCalloutCardType>>) => (
  <CardContainer className={className}>
    {agent && <Agent {...agent} css={{ marginBottom: 16 }} />}
    {title && <OriginText className="type-title3 text-primary" css={{ marginBottom: 8 }} text={title} />}
    <OriginRichText className="type-body text-secondary" text={formattedText} />
    {action && action.caption && (
      <Button
        className="darkmode"
        style="secondary"
        css={{ width: '100%', marginTop: 16 }}
        onClick={onClick || action.onClick}
      >
        <OriginText text={action.caption} />
      </Button>
    )}
  </CardContainer>
);
