import React from 'react';

import { generateImageInfo, useComponentConfig } from '@origin-dot/components';
import { useImageUrl } from '../hooks/useImageUrl';

export const Image = ({ path, aspect, className, ...props }) => {
  const url = useImageUrl(path);
  const config = useComponentConfig();
  const cdnProvider = config?.cdnProvider || 'none';
  const cdnConfig = config && config.cdnConfig;
  const imageInfo = generateImageInfo({ url }, cdnProvider, cdnConfig, false);

  if (aspect) {
    const { style, ...otherProps } = props;
    const newStyle = {
      ...style,
      paddingTop: `${(100 / aspect).toFixed()}%`,
      height: 0,
    };
    if (imageInfo.url)
      newStyle.backgroundImage = `url(${imageInfo.url})${
        imageInfo.placeholderUrl ? `, url(${imageInfo.placeholderUrl})` : ''
      }`;

    return (
      <div>
        <div
          role="img"
          className={`${className || ''} ${imageInfo.url ? '' : 'placeholder'} bg-cover bg-center`}
          style={newStyle}
          {...otherProps}
        />
      </div>
    );
  }

  if (!url) {
    return <div role="img" className={`${className || ''} placeholder`} {...props} />;
  }

  return <img src={imageInfo.url} className={`${className || ''} object-cover`} {...props} />;
};
