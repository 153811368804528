import React from 'react';

import { getLocalTime } from '../../helpers/time';
import { itineraryCardForTemplate } from '../../helpers/cards';

import { Card } from '../card/Card';
import { NotionIcon } from '../NotionIcon';
import { functions } from '../../firebase';

export const InspirationListing = ({ inspirations, selectedInspirationId, onSelectInspirationId }) => {
  const createWYSIWGInspiration = () => {
    // eslint-disable-next-line no-alert, no-restricted-globals

    (async () => {
      // eslint-disable-next-line no-alert
      alert(
        'Please wait for the alert saying the inspiration item has been created before clicking this again!\nIt takes a while\nif this bugs you bug Nour'
      );
      try {
        await functions.httpsCallable('processInspirationCommand')({
          command: 'CreateWYSIWYGInspirationItem',
          params: {},
        });
      } catch (err) {
        // eslint-disable-next-line no-alert
        alert(err.message);
      }
      // eslint-disable-next-line no-alert
      alert('A inspiration item has been created yay!');
    })();
  };

  return (
    <div
      className="flex-grow p-3 overflow-y-auto grid content-start gap-2"
      style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))' }}
      onClick={() => onSelectInspirationId(null)}
    >
      {inspirations && (
        <button
          className="bg-cardBackgroundColor rounded-lg border-4 border-cardBackgroundColor p-3 text-sm text-center"
          onClick={createWYSIWGInspiration}
        >
          <div className="font-bold">Click Here</div>
          <div className="font-bold">To Create</div>
          <div className="font-bold">WYSIWYG</div>
          <div className="font-bold">Explore Item</div>
        </button>
      )}
      {inspirations &&
        inspirations.map(inspiration => {
          const inspirationId = inspiration.id;
          const itineraryCard = itineraryCardForTemplate({ type: 'INSPIRATION', tripInfo: inspiration }); // TODO: fix this, it's hacky
          return (
            <button
              key={inspirationId}
              onClick={event => {
                event.stopPropagation();
                onSelectInspirationId(selectedInspirationId === inspirationId ? null : inspirationId);
              }}
              className={`text-left p-2 bg-cardBackgroundColor border-2 rounded-lg ${
                selectedInspirationId === inspirationId ? 'border-mainTintColor' : 'border-cardBackgroundColor'
              }`}
            >
              <div className="mb-2 flex flex-col text-xs items-stretch">
                <div className="flex items-center">
                  <NotionIcon className="w-4 h-4 mr-1 flex-shrink-0" />
                  <div className="font-bold text-sm flex-grow truncate">{inspiration.title || ''}</div>
                </div>
                <div className="truncate">
                  Last modified: {getLocalTime({ date: inspiration.lastModified.toDate() }).fromNow()}
                </div>
              </div>
              <Card card={itineraryCard} />
            </button>
          );
        })}
    </div>
  );
};
