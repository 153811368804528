import { css } from '@emotion/react';

const colorDefinitions: Record<string, { light: string; dark: string } | string> = {
  generalTint: 'rgba(232, 95, 95, 1)',
  generalButtonPrimary: 'rgba(237, 238, 239, 1)',
  generalButtonSecondary: 'rgba(44, 50, 50, 1)',
  generalTextPrimary: 'rgba(238, 239, 240, 1)',
  generalTextSecondary: 'rgba(238, 239, 240, 0.8)',
  generalTextQuarternary: 'rgba(238, 239, 240, 0.4)',
  generalFillGeneric: 'rgba(140, 142, 143, 1)',
  generalSeparator: 'rgba(255, 255, 255, 0.2)',
  generalTextInversePrimary: 'rgba(25, 30, 31, 1)',
  background: {
    light: 'rgba(255, 255, 255, 1)',
    dark: 'rgba(1, 1, 1, 1)',
  },
  cardElevatedPrimary: {
    light: 'rgba(242, 243, 245, 1)',
    dark: 'rgba(25, 30, 31, 1)',
  },
  cardElevatedSecondary: {
    light: 'rgba(255, 255, 255, 1)',
    dark: 'rgba(12, 15, 15, 1)',
  },
  buttonPrimary: {
    light: 'rgba(44, 50, 50, 1)',
    dark: 'rgba(237, 238, 239, 1)',
  },
  buttonPrimaryHover: {
    light: 'rgba(44, 50, 50, 0.65)',
    dark: 'rgba(237, 238, 239, 0.65)',
  },
  buttonSecondary: {
    light: 'rgba(237, 238, 239, 1)',
    dark: 'rgba(44, 50, 50, 1)',
  },
  buttonSecondaryHover: {
    light: 'rgba(237, 238, 239, 0.65)',
    dark: 'rgba(44, 50, 50, 0.65)',
  },
  buttonCard: {
    light: 'rgba(255, 255, 255, 1)',
    dark: 'rgba(44, 50, 50, 1)',
  },
  buttonColored: {
    light: 'rgba(16, 120, 74, 0.2)',
    dark: 'rgba(253, 206, 128, 0.2)',
  },
  primary: {
    light: 'rgba(16, 120, 74, 1)',
    dark: 'rgba(253, 206, 128, 1)',
  },
  textPrimary: {
    light: 'rgba(25, 30, 31, 1)',
    dark: 'rgba(238, 239, 240, 1)',
  },
  textSecondary: {
    light: 'rgba(25, 30, 31, 0.8)',
    dark: 'rgba(238, 239, 240, 0.8)',
  },
  textTertiary: {
    light: 'rgba(25, 30, 31, 0.65)',
    dark: 'rgba(238, 239, 240, 0.65)',
  },
  textQuarternary: {
    light: 'rgba(25, 30, 31, 0.4)',
    dark: 'rgba(238, 239, 240, 0.4)',
  },
  separator: {
    light: 'rgba(0, 0, 0, 0.1)',
    dark: 'rgba(255, 255, 255, 0.2)',
  },
  textInversePrimary: {
    light: 'rgba(238, 239, 240, 1)',
    dark: 'rgba(25, 30, 31, 1)',
  },
  textInverseSecondary: {
    light: 'rgba(238, 239, 240, 0.8)',
    dark: 'rgba(25, 30, 31, 0.8)',
  },
  textInverseQuarternary: {
    light: 'rgba(238, 239, 240, 0.4)',
    dark: 'rgba(25, 30, 31, 0.4)',
  },
};

const colorVariable = (name: string) => `--${name}Color`;

const generalColorVariables: string[] = [];
const lightModeColorVariables: string[] = [];
const darkModeColorVariables: string[] = [];
const textColorClasses: { [key: string]: string } = {};

for (const [name, value] of Object.entries(colorDefinitions)) {
  if (typeof value === 'string') {
    generalColorVariables.push(`${colorVariable(name)}: ${value};`);
  } else {
    lightModeColorVariables.push(`${colorVariable(name)}: ${value.light};`);
    darkModeColorVariables.push(`${colorVariable(name)}: ${value.dark};`);
  }
  if (name.startsWith('text')) {
    const shortName = `${name[4] ? name[4].toLowerCase() : ''}${name.substr(5)}`;
    textColorClasses[shortName] = `var(${colorVariable(name)})`;
  }
}

export const colors = css`
  :root {
    ${generalColorVariables}
  }

  :root,
  .lightmode {
    ${lightModeColorVariables}
  }

  .darkmode {
    ${darkModeColorVariables}
  }

  body,
  .lightmode,
  .darkmode {
    background-color: var(--backgroundColor);
    color: var(--textPrimaryColor);
  }

  ${Object.entries(textColorClasses).map(
    ([name, value]) => css`
      .text-${name} {
        color: ${value};
      }
    `
  )}
`;
