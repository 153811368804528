import React, { useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

import { firestore, FieldValue } from '../../firebase';

import { Card } from '../card/Card';

const UserFeedEvent = ({ eventSnapshot }) => {
  const eventReference = eventSnapshot.ref;
  const event = eventSnapshot.data();

  const handleCardMerge = (cardChange, doBump = false) => {
    if (!cardChange && !doBump) return;
    const eventChange = {};
    if (cardChange) eventChange.card = cardChange;
    if (doBump) eventChange.time = FieldValue.serverTimestamp();
    eventReference.set(eventChange, { merge: true });
  };

  return <Card card={event.card} onMerge={handleCardMerge} />;
  // <>
  //   <div className="text-center text-xs -mb-3">{time.format('YYYY-MM-DD HH:mm')}</div>
  //   <Card card={event.card} onMerge={handleCardMerge} />
  // </>
};

export const UserFeed = ({ userId }) => {
  const query = useMemo(
    () => firestore.collection('users').doc(userId).collection('events').orderBy('time.date', 'desc'),
    [userId]
  );
  const [result, loading, error] = useCollection(query);

  if (loading || error) return null;

  return (
    <div className="flex-grow p-3 overflow-y-auto">
      <div
        className="bg-newBackgroundColor p-4 grid grid-cols-1 content-start gap-4"
        style={{ minHeight: '100%', width: 375 }}
      >
        {result.docs.map(eventSnapshot => (
          <UserFeedEvent key={eventSnapshot.id} eventSnapshot={eventSnapshot} />
        ))}
      </div>
    </div>
  );
};
