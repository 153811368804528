import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { firestore } from '../../firebase';

import { Layout, LayoutColumn } from '../Layout';
import { ColumnTitle } from '../ColumnTitle';
import { PageTitle } from '../PageTitle';

import { InspirationListing } from './InspirationListing';
import { SubstituteUserToggle } from '../user/SubstituteUserToggle';
import { InspirationPageItemInfo } from './InspirationPageItemInfo';

export const InspirationsPage = () => {
  const reference = useMemo(() => firestore.collection('inspirations').orderBy('createdAt', 'desc'), []);
  const [inspirations] = useCollectionData(reference, { idField: 'id' });
  const history = useHistory();
  const { inspirationId } = useParams();
  const selectedInspiration = inspirationId && inspirations && inspirations.find(({ id }) => id === inspirationId);

  const onSelectInspirationId = selectedInspirationId => {
    history.replace(`/inspirations${selectedInspirationId ? `/${selectedInspirationId}` : ''}`);
  };

  return (
    <>
      <PageTitle title={selectedInspiration ? selectedInspiration.title : 'Inspirations'} />
      <Layout className="flex-grow" titleComponent={ColumnTitle}>
        <LayoutColumn
          relativeWidth={2}
          title={
            <div className="flex items-center">
              <div className="flex-grow">All Inspirations</div>
              <SubstituteUserToggle
                className="font-sans font-normal"
                userId={process.env.PREVIEW_USER_ID}
                label="Preview"
              />
            </div>
          }
          compactTitle="Inspirations"
        >
          <InspirationListing
            inspirations={inspirations}
            selectedInspirationId={inspirationId}
            onSelectInspirationId={onSelectInspirationId}
          />
        </LayoutColumn>
        <LayoutColumn relativeWidth={1} title="Inspiration Info" compactTitle="Info">
          <InspirationPageItemInfo selectedInspiration={selectedInspiration} />
        </LayoutColumn>
      </Layout>
    </>
  );
};
