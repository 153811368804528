import React, { useMemo } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../auth/useAuth';
import { firestore } from '../../firebase';

export const SubscribeToggle = ({ userId, id = 'subscribeUser', label = 'Subscribe to chat', className, ...props }) => {
  const auth = useAuth();
  const adminId = auth.userId;

  const reference = useMemo(() => firestore.collection('users').doc(userId), [userId]);
  const [user, loading, error] = useDocumentData(reference);

  const subscribedUsers = loading || error ? [] : user.subscribedUsers || [];
  const isSubscribed = subscribedUsers.includes(adminId);

  const handleToggle = () => {
    if (isSubscribed) {
      reference.set({ subscribedUsers: subscribedUsers.filter(x => x !== adminId) }, { merge: true });
    } else {
      subscribedUsers.push(adminId);
      reference.set({ subscribedUsers }, { merge: true });
    }
  };

  return (
    <div className={`${className || ''} flex items-center`} {...props}>
      <input
        className="w-0 h-0"
        style={{ visibility: 'hidden' }}
        id={id}
        type="checkbox"
        checked={isSubscribed}
        onChange={handleToggle}
      />
      <label
        className={`flex items-center transition-colors duration-100 ease-out ${
          isSubscribed ? 'bg-coloredButtonForegroundColor' : 'bg-dividerColor'
        } w-12 h-6 rounded-full cursor-pointer`}
        htmlFor={id}
      >
        <span
          className="bg-cardBackgroundColor h-0 w-0 rounded-full transition-transform duration-100 ease-out"
          style={{
            paddingTop: '42%',
            paddingLeft: '42%',
            marginLeft: '4%',
            marginRight: '4%',
            ...(isSubscribed && { transform: 'translate(119%, 0)' }),
          }}
        />
      </label>
      {label && (
        <label htmlFor={id} className="text-sm ml-1 leading-none">
          {label}
        </label>
      )}
    </div>
  );
};
