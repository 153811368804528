import { Card as CardType } from '@origin-dot/core';

import { AgentCalloutCard } from './AgentCalloutCard';
import { BlogPostCard } from './BlogPostCard';
import { CalloutCard } from './CalloutCard';
import { PaymentCard } from './PaymentCard';
import { TransportationCard } from './TransportationCard';
import { TripCard } from './TripCard';

type CardProps = CardType & { className?: string };

export const Card = ({ className, ...card }: CardProps) => {
  switch (card.type) {
    case 'AgentCallout':
      return <AgentCalloutCard className={className} {...card.info} />;
    case 'BlogPost':
      return <BlogPostCard className={className} {...card.info} />;
    case 'Callout':
      return <CalloutCard className={className} {...card.info} />;
    case 'Payment':
      return <PaymentCard className={className} {...card.info} />;
    case 'Transportation':
      return <TransportationCard className={className} {...card.info} />;
    case 'Trip':
      return <TripCard className={className} {...card.info} />;
    default:
      return null;
  }
};

export { AgentCalloutCard, BlogPostCard, CalloutCard, TripCard };
