import { HTMLProps } from 'react';
import { cx } from '@emotion/css';

type ButtonProps = Omit<HTMLProps<HTMLButtonElement>, 'style' | 'css' | 'type'> & {
  style?: 'primary' | 'secondary';
};

export const Button = ({ className, style = 'primary', disabled = false, ...props }: ButtonProps) => {
  let backgroundColorVariable;
  let backgroundHoverColorVariable;
  let textClassName;

  if (style === 'primary') {
    backgroundColorVariable = '--buttonPrimaryColor';
    backgroundHoverColorVariable = '--buttonPrimaryHoverColor';
    textClassName = disabled ? 'text-inverseQuarternary' : 'text-inversePrimary';
  } else {
    backgroundColorVariable = '--buttonSecondaryColor';
    backgroundHoverColorVariable = '--buttonSecondaryHoverColor';
    textClassName = disabled ? 'text-quarternary' : 'text-primary';
  }

  return (
    <button
      type="button"
      className={cx(className, 'type-headline', textClassName)}
      css={[
        {
          padding: '8px 16px',
          height: 50,
          borderRadius: 6,
          backgroundColor: `var(${backgroundColorVariable})`,
          transition: 'background-color .3s ease',
        },
        !disabled && {
          '&:hover': {
            backgroundColor: `var(${backgroundHoverColorVariable})`,
          },
        },
      ]}
      disabled={disabled}
      {...props}
    />
  );
};
