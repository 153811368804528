import React from 'react';
import { Link } from 'react-router-dom';
import 'twix';

import { Avatar, AvatarList } from '@origin-dot/components';
import { getLocalTime } from '../../helpers/time';

import { Image } from '../Image';
import { TripPlaceholderCard } from './TripPlaceholderCard';

const aspectRatio = 343 / 412;

export const ItineraryCard = ({ info }) => {
  const { trip, tripId } = info;

  const startTime = trip.startTime && getLocalTime(trip.startTime);
  const endTime = trip.endTime && getLocalTime(trip.endTime);
  const range = startTime && endTime && startTime.twix(endTime, { allDay: true });

  let itineraryView;
  if (!trip.title || !trip.image) {
    // Placeholder stage
    itineraryView = (
      <TripPlaceholderCard
        info={{
          title: `We're working on your next trip`,
          formattedMessage: `We'll let you know when your trip is ready. If you have any qustions please let us know. We're just one tap away.`,
          action: {
            caption: 'Send a Message',
            type: 'Chat',
          },
        }}
      />
    );
    // itineraryView = (
    //   <div className="relative -mx-4 -my-2">
    //     <Image aspect={aspectRatio} />
    //     <div className="absolute top-0 w-full h-full flex flex-col items-stretch p-4 text-center">
    //       <div className="flex-grow" />
    //       <div className="font-serif font-extrabold px-2 text-2xl">We&apos;re working on your next&nbsp;trip</div>
    //       <div className="text-xs text-textDimmedColor mt-3">
    //         We&apos;ll let you know when we&apos;re ready with your proposal
    //       </div>
    //       <div className="flex-grow" />
    //       <div className="text-xs text-textDimmedColor">Have feedback or any suggestions?</div>
    //       <button className="bg-regularButtonBackgroundColor text-lg py-3 -mx-2 -mb-2 mt-4 rounded font-semibold">
    //         Contact Us
    //       </button>
    //     </div>
    //   </div>
    // );
  } else {
    // Proposal or trip stage
    itineraryView = (
      <div className="relative -mx-4 -my-2 darkmode bg-oldBackgroundColor">
        <Image path={trip.image.path} aspect={aspectRatio} />
        <div
          className="absolute top-0 w-full h-full"
          style={{
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0.8) 100%',
          }}
        >
          <div className="w-full h-full p-4 flex flex-col">
            {trip.isProposal && (
              <div className="bg-generalTintColor text-generalTextPrimaryColor self-end rounded-full px-2 py-1 text-xs font-bold">
                Proposal
              </div>
            )}
            <div className="flex-grow" />
            <div className="flex items-end text-textPrimaryColor">
              <div className="flex-grow flex flex-col items-start overflow-hidden">
                <div className="text-xl font-serif font-bold w-full mb-1">{trip.title}</div>
                <div className="text-xs text-textSecondaryColor font-bold w-full truncate">
                  {range && range.format({ monthFormat: 'MMMM', implicitYear: false })}
                </div>
              </div>
              <div className="flex items-center mb-1">
                {trip.members && trip.members.length > 0 && (
                  <div
                    className="relative flex-shrink-0 h-8 ml-12"
                    style={{ width: `${1.3 * (trip.members.length - 1) + 2}rem` }}
                  >
                    <AvatarList>
                      {[...trip.members].reverse().map(userId => (
                        <Avatar key={userId} userId={userId} border />
                      ))}
                    </AvatarList>
                  </div>
                )}
                <div className="chevron w-2 h-4 flex-shrink-0 ml-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return tripId && trip.title ? <Link to={`/trips/${tripId}`}>{itineraryView}</Link> : itineraryView;
};
