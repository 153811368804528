import { Stylable } from '../helpers/types';
import { CdnConfig, useComponentConfig } from './ComponentsConfigProvider';
import { generateProfileInfo, ProfileImgInfo } from './imageHelper';
import { useProfile } from './ProfileProvider';

type AvatarProps = Stylable<{
  userId: string;
  cachedDisplayName?: string;
  cachedPicture?: string;
  size?: number;
  border?: boolean;
}>;

export const Avatar = ({
  className,
  userId,
  cachedDisplayName,
  cachedPicture,
  size = 32,
  border = false,
}: AvatarProps) => {
  const profile = useProfile(userId);

  const picture = profile ? profile.picture : cachedPicture;
  const char = (profile ? profile.displayName : cachedDisplayName)?.trim().substr(0, 1).toUpperCase();

  const shouldShow = !!profile || !!picture || !!char;

  const config = useComponentConfig();
  const cdnProvider = config?.cdnProvider || 'none';
  const cdnConfig: undefined | CdnConfig = config && config.cdnConfig;
  let backgroundImage = '';
  if (shouldShow && picture) {
    const profileImgInfo: ProfileImgInfo = generateProfileInfo(picture, size, cdnProvider, cdnConfig);
    backgroundImage = `url(${profileImgInfo.url})`;
  }

  return (
    <div
      className={className}
      css={[
        {
          width: size,
          height: size,
        },
        shouldShow && {
          borderRadius: '50%',
          backgroundColor: 'var(--generalTintColor)',
          color: 'var(--generalTextPrimaryColor)',
        },
        shouldShow && border && { border: 'solid 1px var(--generalTextPrimaryColor)' },
        shouldShow &&
          picture && { backgroundImage: `${backgroundImage}`, backgroundPosition: 'center', backgroundSize: 'cover' },
      ]}
    >
      {shouldShow && !picture && char && (
        <svg viewBox="0 0 80 80" className="type-headline" css={{ fontSize: 44, lineHeight: 1 }}>
          <text x="50%" y="50%" dominantBaseline="central" textAnchor="middle" fill="currentColor">
            {char}
          </text>
        </svg>
      )}
      {shouldShow && !picture && !char && (
        <svg viewBox="0 0 1024 1024">
          <path
            fill="currentcolor"
            d="M512 576C600.366 576 672 497.202 672 400C672 302.798 600.366 224 512 224C423.634 224 352 302.798 352 400C352 497.202 423.634 576 512 576ZM512 920C640 920 736 864 800 800C752 736 671.058 672 512 672C352.942 672 272 736 224 800C288 864 384 920 512 920Z"
          />
        </svg>
      )}
    </div>
  );
};
