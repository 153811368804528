import React from 'react';
import ContentEditable from 'react-contenteditable';
import { Converter } from 'showdown';

const converter = new Converter({ simpleLineBreaks: true });

export const EditableText = ({ className, style, isEditing, value, onChange }) => {
  const html = converter.makeHtml(value);

  const handleChange = event => {
    onChange(converter.makeMarkdown(event.target.value));
  };
  return (
    <ContentEditable
      html={html}
      onChange={handleChange}
      disabled={!isEditing}
      className={`${className} border ${isEditing ? 'border-mainTintColor' : 'border-transparent'} border-dashed`}
      style={{ ...style }}
    />
  );
};
