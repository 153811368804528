import React, { useMemo } from 'react';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import TextareaAutosize from 'react-textarea-autosize';

import { Card } from '../card/Card';

import { Editable } from '../wysiwyg/Editable';
import { FieldValue } from '../../firebase';

const AgentCalloutCardEditor = ({ value, setValue }) => {
  return (
    <>
      <input
        type="text"
        className="bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2"
        placeholder="Title (optional)"
        value={value.title || ''}
        onChange={e => {
          setValue({ ...value, title: e.target.value || undefined });
        }}
      />
      <TextareaAutosize
        className="flex-grow bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs font-mono border border-searchBorderColor rounded px-2 py-1 appearance-none mb-2"
        placeholder="Text"
        value={value.formattedText}
        onChange={e => {
          setValue({ ...value, formattedText: e.target.value });
        }}
      />
    </>
  );
};

const CardEditor = ({ value, setValue }) => {
  const updateInfo = info => setValue({ ...value, info });
  switch (value.type) {
    case 'AgentCallout':
      return <AgentCalloutCardEditor value={value.info} setValue={updateInfo} />;
    default:
      return null;
  }
};

const EditableCard = ({ card, onChange, readonly }) => {
  const Viewer = ({ value }) => (
    <div className="px-4 py-2">
      <Card card={value} />
    </div>
  );
  return (
    <Editable
      className="bg-newBackgroundColor"
      ViewerComponent={Viewer}
      editorComponents={[CardEditor]}
      value={card}
      onChange={onChange}
      readonly={readonly}
    />
  );
};

export const TripRequestFeed = ({ tripRequestReference }) => {
  const eventsQuery = useMemo(
    () => tripRequestReference.collection('events').orderBy('time', 'desc'),
    [tripRequestReference]
  );
  const [eventsResult, eventsLoading, eventsError] = useCollection(eventsQuery);
  const [tripRequestSnapshot] = useDocument(tripRequestReference);
  const tripRequestData = tripRequestSnapshot && tripRequestSnapshot.exists && tripRequestSnapshot.data();
  if (!tripRequestData || eventsLoading || eventsError) return null;

  const isDraft = tripRequestReference.path.startsWith('draft');

  return (
    <div className="flex-grow p-3 overflow-y-auto">
      <div className="bg-newBackgroundColor pt-2" style={{ width: 375 }} />
      {eventsResult.docs.map(eventSnapshot => {
        const eventReference = eventSnapshot.ref;
        const eventId = eventReference.id;
        const event = eventSnapshot.data();
        const { card } = event;
        const editableCard = (
          <EditableCard
            key={eventId}
            card={card}
            readonly={!isDraft || card.type !== 'AgentCallout'}
            onChange={newCard => {
              if (!newCard.info.title) delete newCard.info.title;
              eventReference.set({ ...event, card: newCard });
              tripRequestReference.set(
                {
                  previewIntroTitle: newCard.info.title || FieldValue.delete(),
                  previewIntroMessage: newCard.info.formattedText,
                },
                { merge: true }
              );
            }}
          />
        );
        if (card.type === 'AgentCallout' && ['READY', 'BOOKED'].includes(tripRequestData.status)) {
          return (
            <div key={eventId}>
              <div>
                <div className="px-4 py-2 bg-newBackgroundColor" style={{ width: 375 }}>
                  <h2 className="font-semibold">{"Trip preview's intro"}</h2>
                </div>
                {editableCard}
                <div className="px-4 py-2 bg-newBackgroundColor" style={{ width: 375 }}>
                  <h2 className="font-semibold">{"Full story's intro"}</h2>
                </div>
                <EditableCard
                  card={{
                    info: {
                      ...(tripRequestData.storyIntroTitle && { title: tripRequestData.storyIntroTitle }),
                      formattedText: tripRequestData.storyIntroMessage || '',
                      agent: tripRequestData.agent,
                    },
                    type: 'AgentCallout',
                  }}
                  readonly={!isDraft}
                  onChange={newCard => {
                    if (!newCard.info.title) delete newCard.info.title;
                    tripRequestReference.set(
                      {
                        storyIntroTitle: newCard.info.title || FieldValue.delete(),
                        storyIntroMessage: newCard.info.formattedText,
                      },
                      { merge: true }
                    );
                  }}
                />
              </div>
            </div>
          );
        }
        return editableCard;
      })}
      <div className="bg-newBackgroundColor pt-2" style={{ width: 375 }} />
    </div>
  );
};
