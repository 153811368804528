import React, { useEffect } from 'react';

import { Prompt } from 'react-router-dom';

export const PendingChangesPrompt = ({ show }) => {
  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      if (!show) {
        return;
      }
      e.returnValue = true;
    };

    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, [show]);

  return <Prompt when={!!show} message="You have unsaved changes. Are you sure you want to leave this page?" />;
};
