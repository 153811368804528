import 'tippy.js/dist/tippy.css';
import Tippy from '@tippyjs/react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faPen, faPhone, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { noop } from 'lodash';

import moment from 'moment';
import { EditContactPopup } from './EditContactPopup';
import { Button } from './Button';

import { ActionButton } from '../../ActionButton';
import { useToggle } from '../../popup/useToggle';

const ContactListContext = React.createContext({ disabled: false, editable: false });

const Contact = ({ title, numbers = [], description, dateRange, onRemoveButtonClick, onEditButtonClick, kind }) => {
  const { disabled, editable } = useContext(ContactListContext);

  const dateRangeArray = (dateRange || []).filter(Boolean);
  const showDateRange = kind === 'general' && dateRangeArray.length === 2;

  return (
    <div className="bg-cardStackedBackgroundColor rounded p-2 flex flex-col space-y-2 relative">
      {showDateRange && (
        <div className="absolute left-0 top-0 bg-cardStackedBackgroundColor -mt-1 border-2 rounded-md px-1 text-xs">
          {moment(dateRangeArray[0])
            .twix(moment(dateRangeArray[1]))
            .format({ monthFormat: 'MMM', implicitYear: false, hideTime: true })}
        </div>
      )}
      <div className={`flex flex-row ${showDateRange ? 'pt-2' : ''}`}>
        <div className="text-base italic w-full">
          {title}{' '}
          {description && (
            <Tippy content={description}>
              <span>
                <FontAwesomeIcon icon={faInfo} fixedWidth />
              </span>
            </Tippy>
          )}
        </div>
        {editable && (
          <div className={`grow-0 flex flex-row ${disabled ? 'text-dividerColor' : 'text-generalTintColor'}`}>
            <ActionButton
              onAction={onEditButtonClick}
              className={`py-0 ${disabled ? '' : 'hover:text-primaryColor'}`}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faPen} fixedWidth />
            </ActionButton>
            <ActionButton
              onAction={onRemoveButtonClick}
              className={`py-0 ${disabled ? '' : 'hover:text-generalTintColor'}`}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faTimes} fixedWidth />
            </ActionButton>
          </div>
        )}
      </div>
      <div className="flex flex-col space-y-2">
        {numbers.map((number, i) => (
          <div className="flex flex-row space-x-2 px-3 align-middle text-sm" key={`${number}-${i}`}>
            <div className="border-dividerColor">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className="border-left-1 border-solid border-rose-600">{formatPhoneNumberIntl(number)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const ContactList = ({
  title,
  contacts: unfilteredContacts = [],
  editable = false,
  kind = 'general',
  onAdd = noop,
  onRemove = noop,
  onEdit = noop,
}) => {
  const [disabled, disable, enable] = useToggle(false);
  const [isEditContactPopupOpen, openEditContactPopup, closeEditContactPopup] = useToggle();
  const [editContactValues, setEditContactValues] = useState();
  const contacts = useMemo(
    () => unfilteredContacts.filter(contact => contact.kind === kind),
    [unfilteredContacts, kind]
  );

  const startContactEdit = useCallback(
    contact => {
      setEditContactValues(contact);
      openEditContactPopup();
    },
    [setEditContactValues, openEditContactPopup]
  );

  // Popup handlers
  const handleEditContactPopupClose = useCallback(() => {
    closeEditContactPopup();
  }, [closeEditContactPopup]);

  const handleEditContactPopupSubmit = useCallback(
    async ({ id: contactId, ...contactData }) => {
      if (contactId) {
        return onEdit(contactId, contactData);
      }
      return onAdd(contactData);
    },
    [onEdit, onAdd]
  );

  // Contact list handlers
  const handleEditButtonClick = useCallback(
    index => async () => {
      return startContactEdit(contacts[index]);
    },
    [contacts, startContactEdit]
  );

  const handleRemoveButtonClick = useCallback(
    index => async () => {
      try {
        disable();
        // eslint-disable-next-line no-restricted-globals,no-alert
        if (!confirm(`Are you sure you want to remove ${contacts[index].title} from this trip?`)) {
          return;
        }
        const { id: contactId } = contacts[index];
        await onRemove(contactId);
      } finally {
        enable();
      }
    },
    [contacts, enable, disable, onRemove]
  );

  // Add contact button handlers
  const handleAddContactButtonClick = useCallback(() => startContactEdit(), [startContactEdit]);

  return (
    <ContactListContext.Provider value={{ disabled, editable }}>
      <div className="flex flex-col space-y-2">
        <div className="text-base font-bold w-full truncate">{title}</div>
        {contacts.length > 0
          ? contacts.map((contact, i) => (
              <Contact
                key={i}
                {...contact}
                onRemoveButtonClick={handleRemoveButtonClick(i)}
                onEditButtonClick={handleEditButtonClick(i)}
              />
            ))
          : !editable && <span className="text-sm">No contacts yet</span>}
        {editable && (
          <>
            <Button className="w-full" onClick={handleAddContactButtonClick} disabled={disabled}>
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add contact
            </Button>
            <EditContactPopup
              kind={kind}
              isOpen={isEditContactPopupOpen}
              onClose={handleEditContactPopupClose}
              onSubmit={handleEditContactPopupSubmit}
              initialValues={
                editContactValues || {
                  title: '',
                  kind,
                  numbers: [''],
                  description: '',
                  dateRange: [],
                }
              }
            />
          </>
        )}
      </div>
    </ContactListContext.Provider>
  );
};
