import React from 'react';
import { ProfileProvider } from '@origin-dot/components';
import { firestore, storage } from '../firebase';

const downloadUrlCache = {};

const resolveImageUrl = async path => {
  if (path.match(/^https?:\/\//i)) return path;

  if (!downloadUrlCache[path]) {
    downloadUrlCache[path] = await storage.ref(path).getDownloadURL();
  }
  return downloadUrlCache[path];
};

export const FirestoreProfileProvider = ({ children }) => {
  const repository = (() => {
    const profiles = {};
    return {
      get: userId => {
        return profiles[userId];
      },
      onUpdate: (userId, setProfile) => {
        if (profiles[userId]) setProfile(profiles[userId]);

        const listener = firestore
          .collection('profiles')
          .doc(userId)
          .onSnapshot(async doc => {
            const profile = doc.exists ? doc.data() : {};
            if (profile.picture) profile.picture = await resolveImageUrl(profile.picture);
            profiles[userId] = profile;
            setProfile(profile);
          });

        // eslint-disable-next-line consistent-return
        return () => listener();
      },
    };
  })();

  return <ProfileProvider repository={repository}>{children}</ProfileProvider>;
};
