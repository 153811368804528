import React from 'react';
import Select from 'react-select';

const contributors = {
  mi4V8zFJ88fbgc5WXzloOgpzD6b2: { name: 'Charlotte Piazza', subtitle: 'Scenset travel writer' },
  J0ajS8GEkgVLWnhb5IEgqASQN3m2: { name: 'Brian McMahon', subtitle: 'Scenset travel writer' },
  jFvAE0vxFGOXeeTTK8u71NWuf8p2: { name: 'Jurriaan Teulings', subtitle: 'Scenset travel writer' },
  LcvRz3XMeqNHLeiukePeD7s9bwX2: { name: 'Alex Roche', subtitle: 'Scenset travel writer' },
  PL8kg07yayYTFsiziIPFEoimo4e2: { name: 'Judith van Raalten', subtitle: 'Scenset travel curator' },
  COd3ONwpF4S0PylbFHlmAEZu4wv1: { name: 'Sarah Casewit', subtitle: 'Scenset travel curator' },
  TX8zwpFns4XB2jmXgECglxQsWT93: { name: 'Rick Lunt', subtitle: 'Scenset travel curator' },
  FtnqKEa6MSPIgp6OToMgYILOZQC2: { name: 'Christian Gibney', subtitle: 'Scenset travel curator' },
  tF4oythgaSXAzDWaZjYrPRx0hjt2: { name: 'Virginia Rovagna', subtitle: 'Scenset travel curator' },
  dFz1s9X4Cbcc3Xndbdq2WOa78Uo1: { name: 'Dean Hayter', subtitle: 'Scenset travel curator' },
  xKhsJHczkWSjmO8B7cuN1gVYrnE3: { name: 'Jamie Ditaranto', subtitle: 'Scenset travel curator' },
  g655IieoORTomUmJWpyAaH8VcGa2: { name: 'Ioanna Yiasemi', subtitle: 'Scenset travel curator' },
  zassxZXcbnR4Ivy9EAH1ch8Wqx82: { name: 'Federico Aramburu', subtitle: 'Scenset travel curator' },
  mKzUrRHJ6TSZ7WwdaH5pLePxgni1: { name: 'Sophie-Anne Burton', subtitle: 'Scenset travel curator' },
};

export const getOptionsFromContributorIds = ids =>
  ids.map(id => {
    return { label: contributors[id].name, value: id };
  });

const tagOptions = getOptionsFromContributorIds(Object.keys(contributors));

export const getSubtitleForContributorId = id => contributors[id] && contributors[id].subtitle;

const colourStyles = {
  option: styles => {
    return {
      ...styles,
      color: 'rgba(25, 30, 31, 1)',
    };
  },
};

export const ContributorSelector = ({ className, value, onChange }) =>
  process.env.TAG !== 'dev' && (
    <Select
      className={className}
      options={tagOptions}
      value={value}
      closeMenuOnSelect={false}
      onChange={onChange}
      styles={colourStyles}
    />
  );
