import React from 'react';
import { Highlight } from 'react-instantsearch';
import 'twix';

import { useProfile } from '@origin-dot/components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser as travelerIcon } from '@fortawesome/free-solid-svg-icons/faUser';

import { Link } from 'react-router-dom';
import { getLocalTime } from '../../helpers/time';

import { Image } from '../Image';

const UserFirstName = ({ userId, ...props }) => {
  const profile = useProfile(userId);
  return <span {...props}>{profile && profile.displayName}</span>;
};

export const TripSearchResult = ({ data: trip }) => {
  const startTime =
    trip.startDate && getLocalTime({ date: new Date(`${trip.startDate}T12:00:00`), timeZone: 'Europe/Amsterdam' });
  const endTime =
    trip.endDate && getLocalTime({ date: new Date(`${trip.endDate}T12:00:00`), timeZone: 'Europe/Amsterdam' });
  const range = startTime && endTime && startTime.twix(endTime, { allDay: true });

  return (
    <div className="flex p-2 items-center">
      <Image path={trip.image} aspect={343 / 412} className="w-10 rounded bg-regularButtonBackgroundColor" />
      <div className="ml-2 truncate">
        <div className="text-sm font-semibold truncate flex items-center">
          <Highlight hit={trip} attribute="title" highlightedTagName="mark" />
          <span className="ml-1 rounded p-1 bg-mainTintColor text-white leading-none" style={{ fontSize: '0.6rem' }}>
            {trip.status}
          </span>
        </div>
        <div className="text-xs truncate">{range && range.format({ monthFormat: 'MMMM', implicitYear: false })}</div>
        <div className="text-xs">
          {(trip.users || []).map(userId => (
            <React.Fragment key={userId}>
              <FontAwesomeIcon icon={travelerIcon} size="xs" className="text-textDimmedColor mr-1" />
              <UserFirstName key={userId} userId={userId} className="mr-1" />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export const TripSearchResultWithLink = props => {
  const { data: trip } = props;

  return (
    <Link to={`/${trip.objectID.replace('trip', 'tripRequest').replace(':', 's/').replace(/:/g, '/')}`}>
      <TripSearchResult {...props} />
    </Link>
  );
};
