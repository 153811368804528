import { Image as OriginImage } from '@origin-dot/core';

import { CdnConfig, MappedUrl } from './ComponentsConfigProvider';

export type ImgInfo = {
  url: string;
  placeholderUrl?: string;
  srcSet?: string;
  width?: number;
  height?: number;
};

export type BackgroundImgInfo = {
  url: string;
  placeholderUrl?: string;
  imageSet?: string;
};

export type ProfileImgInfo = {
  url: string;
};

export type MetaImgInfo = {
  url: string;
};

export const defaultBackgroundImageInfo = (image: OriginImage): BackgroundImgInfo => {
  return {
    url: image.url,
  };
};

export const defaultImageInfo = (image: OriginImage): ImgInfo => {
  return {
    url: image.url,
    width: image.width,
    height: image.height,
  };
};

export const defaultMetaImage = (image: OriginImage): MetaImgInfo => {
  return {
    url: image.url,
  };
};

const getProperURINoQueryString = (url: string): string => {
  const urlNoQueryString = url.split(/[?#]/)[0] || '';
  return urlNoQueryString.replace('%2F', '/');
};

const generateCdnUrl = (
  cdnProvider: 'fastly' | 'imagekit',
  imageUrl: string,
  imageKitUrl: string,
  mapping: MappedUrl,
  params: { width?: number; height?: number; quality?: number; blur?: number }
): string => {
  if (Object.keys(params).length === 0) {
    return imageUrl;
  }
  const isImageKitUrl = imageUrl.startsWith(imageKitUrl);
  const urlNotHandled = !imageUrl.startsWith(mapping.sourceUrl) && !isImageKitUrl;

  if (urlNotHandled) {
    return imageUrl;
  }

  const strings = {
    start: '?',
    and: '&',
    equal: '=',
    width: 'width',
    height: 'height',
    quality: 'quality',
    blur: 'blur',
  };
  if (cdnProvider === 'imagekit' || isImageKitUrl) {
    strings.start = '?tr=';
    strings.and = ',';
    strings.equal = '-';
    strings.width = 'w';
    strings.height = 'h';
    strings.quality = 'q';
    strings.blur = 'bl';
  }

  let result = '';
  let separator = strings.start;
  for (const key in params) {
    const value = params[key as 'width' | 'height' | 'quality' | 'blur']!;
    result = `${result}${separator}${strings[key as 'width' | 'height' | 'quality' | 'blur']}${strings.equal}${value}`;
    separator = strings.and;
  }
  const urlNoQueryString = getProperURINoQueryString(imageUrl);
  if (isImageKitUrl) {
    return `${urlNoQueryString}${result}`;
  }

  return `${mapping.mappedUrl}${urlNoQueryString.substring(mapping.sourceUrl.length)}${result}`;
};

export const generateBackgroundImageInfo = (image: OriginImage, cdnProvider: string, cdnConfig?: CdnConfig) => {
  const info: BackgroundImgInfo = defaultBackgroundImageInfo(image);
  if (cdnProvider !== 'imagekit' && cdnProvider !== 'fastly') {
    return info;
  }
  if (!cdnConfig || !image.url) {
    return info;
  }

  info.url = generateCdnUrl(cdnProvider, image.url, cdnConfig.imageKitUrl, cdnConfig.images, {
    width: cdnConfig.heroWidth,
    quality: cdnConfig.defaultQuality,
  });
  if (info.url !== image.url) {
    info.placeholderUrl = generateCdnUrl(cdnProvider, image.url, cdnConfig.imageKitUrl, cdnConfig.images, {
      width: cdnConfig.heroWidth,
      quality: cdnConfig.blurQuality,
      blur: cdnConfig.blurRadius,
    });
    const isImageKitUrl = image.url.startsWith(cdnConfig.imageKitUrl);
    if (cdnProvider === 'imagekit' || isImageKitUrl) {
      info.imageSet = `image-set(url("${info.url},dpr-1") 1x, url("${info.url},dpr-2") 2x, url("${info.url},dpr-3") 3x)`;
    } else {
      info.imageSet = `image-set(url("${info.url}&dpr=1") 1x, url("${info.url}&dpr=2") 2x, url("${info.url}&dpr=3") 3x)`;
    }
  }

  return info;
};

export const generateImageInfo = (
  image: OriginImage,
  cdnProvider: string,
  cdnConfig?: CdnConfig,
  heightBound?: boolean
) => {
  const info: ImgInfo = defaultImageInfo(image);
  if (cdnProvider !== 'imagekit' && cdnProvider !== 'fastly') {
    return info;
  }
  if (!cdnConfig || !image.url) {
    return info;
  }

  if (heightBound) {
    info.url = generateCdnUrl(cdnProvider, image.url, cdnConfig.imageKitUrl, cdnConfig.images, {
      height: cdnConfig.galleryHeight,
      quality: cdnConfig.defaultQuality,
    });
    if (info.url !== image.url) {
      info.placeholderUrl = generateCdnUrl(cdnProvider, image.url, cdnConfig.imageKitUrl, cdnConfig.images, {
        height: cdnConfig.galleryHeight,
        quality: cdnConfig.blurQuality,
        blur: cdnConfig.blurRadius,
      });
      info.width = undefined;
      info.height = cdnConfig.galleryHeight;
    }
  } else {
    info.url = generateCdnUrl(cdnProvider, image.url, cdnConfig.imageKitUrl, cdnConfig.images, {
      width: cdnConfig.photoWidth,
      quality: cdnConfig.defaultQuality,
    });
    if (info.url !== image.url) {
      info.placeholderUrl = generateCdnUrl(cdnProvider, image.url, cdnConfig.imageKitUrl, cdnConfig.images, {
        width: cdnConfig.photoWidth,
        quality: cdnConfig.blurQuality,
        blur: cdnConfig.blurRadius,
      });
      info.width = cdnConfig.photoWidth;
      info.height = undefined;
    }
  }
  if (info.url !== image.url) {
    const isImageKitUrl = image.url.startsWith(cdnConfig.imageKitUrl);
    if (cdnProvider === 'imagekit' || isImageKitUrl) {
      info.srcSet = `${info.url},dpr-1 1x,${info.url},dpr-2 2x,${info.url},dpr-3 3x`;
    } else {
      info.srcSet = `${info.url}&dpr=1 1x,${info.url}&dpr=2 2x,${info.url}&dpr=3 3x`;
    }
  }
  return info;
};

export const generateProfileInfo = (pictureUrl: string, size: number, cdnProvider: string, cdnConfig?: CdnConfig) => {
  const info: ProfileImgInfo = { url: pictureUrl };
  if (cdnProvider !== 'imagekit' && cdnProvider !== 'fastly') {
    return info;
  }
  if (!cdnConfig) {
    return info;
  }

  info.url = generateCdnUrl(cdnProvider, pictureUrl, cdnConfig.imageKitUrl, cdnConfig.profiles, {
    width: size * 2,
    quality: cdnConfig.defaultQuality,
  });
  return info;
};

export const generateMetaImageInfo = (image: OriginImage, cdnProvider: string, cdnConfig?: CdnConfig) => {
  const info: MetaImgInfo = defaultMetaImage(image);
  if (cdnProvider !== 'imagekit' && cdnProvider !== 'fastly') {
    return info;
  }
  if (!cdnConfig || !image.url) {
    return info;
  }
  info.url = generateCdnUrl(cdnProvider, image.url, cdnConfig.imageKitUrl, cdnConfig.images, {
    width: cdnConfig.metaWidth,
    quality: cdnConfig.defaultQuality,
  });
  return info;
};
