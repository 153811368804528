import { Block } from './blocks';
import type { Locale } from './common';
import type { Branded } from './util';

// Yes, these are really just strings, but this prevents them from being used as strings without conversion.
export type OriginText = Branded<unknown, 'OriginText'>;
export type OriginRichText = Branded<unknown, 'OriginRichText'>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const formatCurrency = (amount: number, currency: string, locale?: Locale) => {
  try {
    return amount.toLocaleString(locale ?? 'en-US', { style: 'currency', currency });
  } catch (err: unknown) {
    // In case of invalid currency.
    return `${currency ? `${currency.toUpperCase()} ` : ''}${amount.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  }
};

/**
 * Tagged template string helper, to easily create OriginText literals.
 * Usage: const myText = originText`Something's happening at {{time|3PM}}!`;
 */
export const originText = (strings: TemplateStringsArray, ...expressions: string[]): OriginText =>
  strings.map((s, i) => s + (expressions[i] ?? '')).join('') as unknown as OriginText;

/**
 * Tagged template string helper, to easily create OriginRichText literals.
 * Usage: const myText = originRichText`Something's **happening** at {{time|3PM}}!`;
 */
export const originRichText = (strings: TemplateStringsArray, ...expressions: string[]): OriginRichText =>
  strings.map((s, i) => s + (expressions[i] ?? '')).join('') as unknown as OriginRichText;

const formatterRegex = /{{[^}]*?}}/g;

type InlineFormatterHandler = (args: string[], locale?: Locale) => string;

export const inlineFormatters: { [key: string]: InlineFormatterHandler } = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  currency: (args, locale) => {
    if (args.length !== 2) throw new Error('Currency formatter expects 2 arguments');
    const [currency, amount] = args as [string, string];

    if (!/^[a-z]{3}$/i.exec(currency)) throw new Error('Invalid currency in currency formatter');
    if (!/^-?\d+(\.\d{2})?$/.exec(amount)) throw new Error('Invalid amount in currency formatter');

    return formatCurrency(parseFloat(amount), currency, locale);
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  phone: (args, _locale) => {
    if (args.length !== 1) throw new Error('Phone formatter expects 1 argument');
    const [phone] = args as [string];

    if (!/^\+?[\d\s]*\d$/.exec(phone)) throw new Error('Invalid phone number in phone formatter');

    // TODO: do actual phone formatting based on locale.
    return phone;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  time: (args, _locale) => {
    if (args.length !== 1) throw new Error('Time formatter expects 1 argument');
    const [time] = args as [string];

    const match24 = /^(?<hours>\d{1,2}):(?<minutes>\d{2})$/.exec(time);
    const match12 = /^(?<hours>\d{1,2})(:(?<minutes>\d{2}))?\s*(?<period>AM|PM)$/i.exec(time);

    let hours;
    let minutes;

    if (match24) {
      hours = parseInt(match24.groups!.hours!, 10);
      minutes = parseInt(match24.groups!.minutes!, 10);
      if (hours > 23) throw new Error('Invalid hours in time formatter');
      if (minutes > 59) throw new Error('Invalid minutes in time formatter');
    } else if (match12) {
      hours = parseInt(match12.groups!.hours!, 10);
      minutes = parseInt(match12.groups!.minutes! || '0', 10);
      const period = match12.groups!.period!.toUpperCase();
      if (hours < 1 || hours > 12) throw new Error('Invalid hours in time formatter');
      if (minutes > 59) throw new Error('Invalid minutes in time formatter');
      if (period === 'AM' && hours === 12) hours = 0;
      if (period === 'PM' && hours < 12) hours += 12;
    } else {
      throw new Error('Invalid time in time formatter');
    }

    // TODO: do actual time formatting based on locale.
    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  duration: (args, _locale) => {
    if (args.length !== 2) throw new Error('Duration formatter expects 2 arguments');
    const [format, duration] = args as [string, string];

    const match = /^((?<hours>\d+):)?(?<minutes>\d+)$/.exec(duration);
    if (!match) throw new Error('Invalid duration in duration formatter');

    let totalMinutes = parseInt(match.groups!.minutes!, 10);
    if (match.groups!.hours) totalMinutes += parseInt(match.groups!.hours, 10) * 60;

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    // TODO: do actual duration formatting based on locale.
    switch (format) {
      case 'abbreviated':
        return minutes === 0 ? `${hours}h` : `${hours}h ${minutes}m`;
      case 'short':
        return minutes === 0 ? `${hours}hrs` : `${hours}hrs ${minutes}min`;
      case 'full':
        return minutes === 0 ? `${hours} hours` : `${hours} hours, ${minutes} minutes`;
      default:
        throw new Error('Invalid format in duration formatter');
    }
  },
};

const applyInlineFormatter = (formatterString: string, locale?: Locale) => {
  const match = /^{{(?<content>[^}]*?)}}$/.exec(formatterString);
  if (!match) throw new Error(`Invalid formatter ${formatterString}`);
  const content = match.groups!.content!;
  const [type, ...args] = content.split('|').map((token) => token.trim());

  const inlineFormatter = type ? inlineFormatters[type] : undefined;
  if (!inlineFormatter) throw new Error(`Unknown formatter ${formatterString}`);

  try {
    return inlineFormatter(args, locale);
  } catch (err: unknown) {
    throw new Error(`Incorrect ${type || ''} formatter ${formatterString}`);
  }
};

export const checkOriginText = (text: OriginText): string[] => {
  const issues: string[] = [];
  for (const formatterString of (text as unknown as string).match(formatterRegex) || []) {
    try {
      applyInlineFormatter(formatterString);
    } catch (err: unknown) {
      const { message } = err as { message: string };
      issues.push(message);
    }
  }
  return issues;
};

/** Converts OriginText to a plain text string, optionally using a given locale. */
export const originTextToString = (text: OriginText, locale?: Locale): string =>
  (text as unknown as string).replace(formatterRegex, (formatterString) => {
    try {
      return applyInlineFormatter(formatterString, locale);
    } catch (err: unknown) {
      return formatterString;
    }
  });

/** Converts OriginRichText to a plain text string, optionally using a given locale. */
export const originRichTextToString = (text: OriginRichText, locale?: Locale): string => {
  // First, process the Markdown formatting, keeping the formatters intact.
  // TODO: convert Markdown.
  const plainText = text as unknown as OriginText;

  // Then, apply the formatters to turn it into a regular string.
  return originTextToString(plainText, locale);
};

export const getWordCount = (text: string | OriginText | OriginRichText = '') =>
  (text as string)
    .trim()
    .split(/\s+/)
    .filter((w) => /[a-z0-9]/i.exec(w)).length;

export const getBlockWordCount = (block: Block): number => {
  switch (block.type) {
    case 'File':
      return getWordCount(block.info.title) + getWordCount(block.info.formattedMessage);
    case 'Group':
      return block.info.blocks.map(getBlockWordCount).reduce((sum, count: number) => sum + count, 0);
    case 'Header':
      return getWordCount(block.info.title);
    case 'Text':
      return getWordCount(block.info.formattedText);
    case 'Card': {
      switch (block.info.card.type) {
        case 'BlogPost':
          return getWordCount(block.info.card.info.title) + getWordCount(block.info.card.info.category);
        case 'Callout':
          return getWordCount(block.info.card.info.title) + getWordCount(block.info.card.info.formattedText);
        case 'Generic':
          return getWordCount(block.info.card.info.title) + getWordCount(block.info.card.info.formattedMessage);
        case 'GenericStyled':
          return getWordCount(block.info.card.info.title) + getWordCount(block.info.card.info.formattedMessage);
        case 'Payment':
          return getWordCount(block.info.card.info.title) + getWordCount(block.info.card.info.formattedMessage);
        case 'SimpleAction':
          return getWordCount(block.info.card.info.title) + getWordCount(block.info.card.info.formattedMessage);
        case 'AgentCallout': // Explicitly not counting AgentCalloutCard at the moment, since it's backend-generated.
        default:
          return 0;
      }
    }
    default:
      return 0;
  }
};
