import { createContext, useContext } from 'react';

import { WithChildren } from '../helpers/types';

export type MappedUrl = {
  sourceUrl: string;
  mappedUrl: string;
};
export type CdnConfig = {
  heroWidth: number;
  photoWidth: number;
  galleryHeight: number;
  metaWidth: number;
  defaultQuality: number;
  blurQuality: number;
  blurRadius: number;
  images: MappedUrl;
  profiles: MappedUrl;
  imageKitUrl: string;
};

export type CdnConfigProviderOverrides = {
  heroWidth?: number;
  photoWidth?: number;
  galleryHeight?: number;
  metaWidth?: number;
  defaultQuality?: number;
  blurQuality?: number;
  blurRadius?: number;
  imageKitUrl?: string;
  images?: {
    sourceUrl?: string;
    mappedUrl?: string;
  };
  profiles?: {
    sourceUrl?: string;
    mappedUrl?: string;
  };
};

export type ComponentsConfig = {
  cdnProvider: string;
  cdnConfig?: CdnConfig;
};

export type CdnProviderEnvOverrides = {
  dev?: CdnConfigProviderOverrides;
  staging?: CdnConfigProviderOverrides;
  production?: CdnConfigProviderOverrides;
};

export type CdnConfigOverrides = {
  cdnProvider?: 'fastly' | 'imagekit';
  fastly?: CdnProviderEnvOverrides;
  imagekit?: CdnProviderEnvOverrides;
};

const defaultCdnConfigWithProviders = {
  defaultCdnProvider: 'fastly',
  default: {
    heroWidth: 1200,
    photoWidth: 690,
    galleryHeight: 460,
    metaWidth: 1200,
    defaultQuality: 80,
    blurQuality: 20,
    blurRadius: 15,
    avatarSize: 36,
    imageKitUrl: 'https://ik.imagekit.io/l7rm8mpqm/',
    images: {
      sourceUrl: 'https://firebasestorage.googleapis.com/v0/b/origin-staging.appspot.com/o/images',
      mappedUrl: 'https://scenset-images-uploads-staging.global.ssl.fastly.net/images',
    },
    profiles: {
      sourceUrl: 'https://firebasestorage.googleapis.com/v0/b/origin-staging.appspot.com/o/profiles',
      mappedUrl: 'https://scenset-images-uploads-staging.global.ssl.fastly.net/profiles',
    },
  },
  fastly: {
    production: {
      images: {
        sourceUrl: 'https://firebasestorage.googleapis.com/v0/b/origin-270811.appspot.com/o/images',
        mappedUrl: 'https://scenset-images-uploads.global.ssl.fastly.net/images',
      },
      profiles: {
        sourceUrl: 'https://firebasestorage.googleapis.com/v0/b/origin-270811.appspot.com/o/profiles',
        mappedUrl: 'https://scenset-images-uploads.global.ssl.fastly.net/profiles',
      },
    },
  },
  imagekit: {
    dev: {
      blurRadius: 80,
      images: {
        sourceUrl: 'https://firebasestorage.googleapis.com/v0/b/origin-staging.appspot.com/o/images',
        mappedUrl: 'https://ik.imagekit.io/l7rm8mpqm/uploads-images-staging',
      },
      profiles: {
        sourceUrl: 'https://firebasestorage.googleapis.com/v0/b/origin-staging.appspot.com/o/profiles',
        mappedUrl: 'https://ik.imagekit.io/l7rm8mpqm/uploads-profiles-staging',
      },
    },
    staging: {
      blurRadius: 80,
      images: {
        sourceUrl: 'https://firebasestorage.googleapis.com/v0/b/origin-staging.appspot.com/o/images',
        mappedUrl: 'https://ik.imagekit.io/l7rm8mpqm/uploads-images-staging',
      },
      profiles: {
        sourceUrl: 'https://firebasestorage.googleapis.com/v0/b/origin-staging.appspot.com/o/profiles',
        mappedUrl: 'https://ik.imagekit.io/l7rm8mpqm/uploads-profiles-staging',
      },
    },
    production: {
      blurRadius: 80,
      images: {
        sourceUrl: 'https://firebasestorage.googleapis.com/v0/b/origin-270811.appspot.com/o/images',
        mappedUrl: 'https://ik.imagekit.io/l7rm8mpqm/uploads-images',
      },
      profiles: {
        sourceUrl: 'https://firebasestorage.googleapis.com/v0/b/origin-270811.appspot.com/o/profiles',
        mappedUrl: 'https://ik.imagekit.io/l7rm8mpqm/uploads-profiles',
      },
    },
  },
};

const mergeCdnConfig = (sourceConfig: CdnConfig, overrideConfig?: CdnConfigProviderOverrides): CdnConfig => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const cdnConfig = { ...sourceConfig };
  cdnConfig.images = { ...sourceConfig.images };
  cdnConfig.profiles = { ...sourceConfig.profiles };
  Object.assign(cdnConfig, overrideConfig);
  if (overrideConfig?.images) {
    Object.assign(cdnConfig.images, overrideConfig?.images);
  }
  if (overrideConfig?.profiles) {
    Object.assign(cdnConfig.profiles, overrideConfig?.profiles);
  }
  return cdnConfig;
};

export const loadCdnConfig = (
  environment: 'dev' | 'staging' | 'production' = 'production',
  cdnConfigOverrides: CdnConfigOverrides = {}
): CdnConfig => {
  const cdnProvider = cdnConfigOverrides.cdnProvider || 'fastly';
  const defaultCdnConfig = defaultCdnConfigWithProviders.default;
  const defaultProviderConfigEnvOverrides: CdnProviderEnvOverrides = defaultCdnConfigWithProviders[cdnProvider];
  const defaultProviderConfigOverrides: CdnConfigProviderOverrides | undefined =
    defaultProviderConfigEnvOverrides[environment];
  const cdnConfigProviderOverrides = cdnConfigOverrides[cdnProvider]?.[environment];
  return mergeCdnConfig(mergeCdnConfig(defaultCdnConfig, defaultProviderConfigOverrides), cdnConfigProviderOverrides);
};

const ComponentsConfigContext = createContext<ComponentsConfig>({ cdnProvider: 'none' });

export const useComponentConfig = (): ComponentsConfig | undefined => {
  return useContext(ComponentsConfigContext);
};

type ComponentsConfigProviderProps = WithChildren<{ config: ComponentsConfig }>;

export const ComponentsConfigProvider = ({ config, ...props }: ComponentsConfigProviderProps) => {
  return <ComponentsConfigContext.Provider value={config} {...props} />;
};
