import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import firebase from 'firebase/app';

const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
};

const app = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
export default app;

export const auth = app.auth();
auth.languageCode = 'en';

export const firestore = app.firestore();
export const { FieldValue, Timestamp, GeoPoint } = firebase.firestore;

export const storage = app.storage();

export const functions = app.functions('europe-west1');
