import type { PhotoBlock as PhotoBlockType } from '@origin-dot/core';

import { BlockContainer } from '../common/BlockContainer';
import { Photo } from '../common/Photo';
import { BlockComponentProps } from '../helpers/types';

export const PhotoBlock = ({ className, image, showsCaption = false }: BlockComponentProps<PhotoBlockType>) => (
  <BlockContainer extended className={className}>
    <Photo image={image} showCaption={showsCaption} />
  </BlockContainer>
);
