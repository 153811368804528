import React from 'react';
import { Highlight } from 'react-instantsearch';

import { Avatar } from '@origin-dot/components';
import { Link } from 'react-router-dom';

export const UserSearchResult = ({ data: user }) => {
  const userId = user.objectID.replace(/^user:/, '');

  return (
    <div className="flex p-2 items-center">
      <div>
        <Avatar userId={userId} />
      </div>
      <div className="ml-2 truncate">
        <div className="text-sm font-semibold truncate flex items-center">
          <Highlight hit={user} attribute="name" highlightedTagName="mark" />
          {(user.isAdmin || user.isTesting) && (
            <span
              className={`ml-1 rounded leading-none p-1 ${
                user.isAdmin ? 'bg-mainTintColor text-white' : 'bg-textDimmedColor text-cardBackgroundColor'
              }`}
              style={{ fontSize: '0.6rem' }}
            >
              {user.isAdmin ? 'admin' : 'testing'}
            </span>
          )}
        </div>
        <div className="text-xs truncate">
          <Highlight hit={user} attribute="email" highlightedTagName="mark" />
        </div>
      </div>
    </div>
  );
};

export const UserSearchResultWithLink = props => {
  const { data: user } = props;

  const userId = user.objectID.replace(/^user:/, '');
  return (
    <Link to={`/users/${userId}`}>
      <UserSearchResult {...props} />
    </Link>
  );
};
