export const prettyStatus = status => {
  switch (status) {
    case 'CREATED':
      return 'User requested trip';
    case 'PLACEHOLDER':
    case 'ACCEPTED':
      return 'Working on trip';
    case 'WAITLISTED':
      return 'Trip waitlisted';
    case 'REJECTED':
      return 'Trip rejected';
    case 'PREVIEWS':
      return 'Trip previews';
    case 'PREPARING':
      return 'Preparing story';
    case 'READY':
      return 'Story ready';
    case 'BOOKED':
      return 'Booked';
    case 'CANCELED':
      return 'Canceled';
    default:
      return '?';
  }
};
