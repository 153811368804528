import React, { useState, useEffect } from 'react';
import { withResizeDetector } from 'react-resize-detector';

const Content = withResizeDetector(({ width, height, targetRef, setRenderHeight, children, ...props }) => {
  useEffect(() => {
    if (height) setRenderHeight(height);
  });
  return (
    <div ref={targetRef} {...props}>
      {children}
    </div>
  );
});

export const ScaledRender = withResizeDetector(({ width, height, targetRef, renderWidth, style, ...props }) => {
  const [renderHeight, setRenderHeight] = useState();
  const innerStyle = { ...style };
  let scale = 1;
  if (width && renderWidth) {
    scale = width / renderWidth;
    Object.assign(innerStyle, {
      width: `${renderWidth}px`,
      transform: `scale(${scale})`,
      transformOrigin: 'top left',
    });
  }

  return (
    <div ref={targetRef} style={renderHeight ? { height: renderHeight * scale } : {}}>
      {width && <Content setRenderHeight={setRenderHeight} style={innerStyle} {...props} />}
    </div>
  );
});
