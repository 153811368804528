import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner as spinnerIcon } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { twMerge } from 'tailwind-merge';
import posthog from 'posthog-js';

export const ActionButton = ({
  onAction,
  disabled,
  primary,
  dangerous,
  className,
  overrideClassNames = false,
  postHogEvent = undefined,
  children,
  ...props
}) => {
  const [isRunning, setRunning] = useState();

  const componentIsMounted = useRef(true);
  useEffect(
    () => () => {
      componentIsMounted.current = false;
    },
    []
  );

  const handleClick = () => {
    if (disabled || isRunning) return;
    (async () => {
      try {
        setRunning(true);
        if (postHogEvent) {
          posthog.capture(postHogEvent);
        }
        await onAction();
      } catch (err) {
        // eslint-disable-next-line no-alert
        alert(err.message);
      } finally {
        if (componentIsMounted.current) {
          setRunning(false);
        }
      }
    })();
  };

  const buttonStyle = (() => {
    if (disabled) {
      return 'bg-regularButtonBackgroundColor text-dividerColor cursor-not-allowed';
    }

    if (dangerous) {
      return 'bg-generalTintColor fg-mainTintColor';
    }

    if (primary) {
      return 'bg-coloredButtonBackgroundColor text-coloredButtonForegroundColor';
    }

    return 'bg-regularButtonBackgroundColor text-textColor';
  })();

  const classNameWithPosthog = postHogEvent ? `ph-no-capture ${className || ''}` : className;
  return (
    <button
      className={
        overrideClassNames
          ? classNameWithPosthog
          : twMerge(`py-2 rounded w-full text-sm font-bold ${buttonStyle} ${classNameWithPosthog || ''}`)
      }
      onClick={handleClick}
      {...props}
    >
      {isRunning ? <FontAwesomeIcon icon={spinnerIcon} spin fixedWidth /> : children}
    </button>
  );
};
