import { useCallback, useEffect, useMemo } from 'react';
import type { HexColor, MapboxStyle, RouteMapBlock as RouteMapBlockType } from '@origin-dot/core';

import { BlockContainer, UpdateFunction, useDarkMode, useMapbox } from '../common';
import { ensureSourcesAndLayers, getBounds } from '../helpers/mapbox';
import { BlockComponentProps } from '../helpers/types';

const defaultLightStyle: MapboxStyle = {
  url: 'mapbox://styles/mapbox/light-v10',
  pin: '#e85f5f' as HexColor,
  pinText: '#ffffff' as HexColor,
  line: '#3378f6' as HexColor,
  lineText: '#ffffff' as HexColor,
  shadow: true,
  border: true,
  text: '#ffffff' as HexColor,
  stroke: '#000000' as HexColor,
};

const defaultDarkStyle: MapboxStyle = {
  ...defaultLightStyle, // Only override the URL, use the same colors as light for now.
  url: 'mapbox://styles/mapbox/dark-v10',
};

export const RouteMapBlock = ({
  className,
  mapboxStyle,
  mapboxLightStyle,
  mapboxDarkStyle,
  mapSize = 'landscape',
  legs = [],
  points = [],
}: BlockComponentProps<RouteMapBlockType>) => {
  const isDarkMode = useDarkMode();

  const style = useMemo<MapboxStyle>(() => {
    if (mapboxStyle) {
      return { ...defaultLightStyle, ...mapboxStyle };
    }
    if (isDarkMode) {
      return { ...defaultDarkStyle, ...mapboxDarkStyle };
    }
    return { ...defaultLightStyle, ...mapboxLightStyle };
  }, [isDarkMode, mapboxDarkStyle, mapboxLightStyle, mapboxStyle]);

  const bounds = useMemo(() => getBounds(legs, points), [legs, points]);

  const ratio = useMemo(() => {
    switch (mapSize) {
      default:
      case 'landscape':
        return 2 / 3;
      case 'halfPortrait':
        return 5 / 4;
      case 'portrait':
        return 3 / 2;
      case 'largePortrait':
        return 7 / 4;
      case 'square':
        return 1;
    }
  }, [mapSize]);

  const update = useCallback<UpdateFunction>(
    async (...args) => {
      await ensureSourcesAndLayers(...args, legs, points);
    },
    [legs, points]
  );

  const { ref, resize } = useMapbox(style, bounds, update);

  useEffect(() => {
    const doResize = () => setImmediate(() => resize());
    document.addEventListener('webkitfullscreenchange', doResize);
    return () => document.removeEventListener('webkitfullscreenchange', doResize);
  }, [resize]);

  useEffect(resize, [mapSize, resize]);

  return (
    <BlockContainer extended className={className}>
      <div css={{ position: 'relative', width: '100%', paddingTop: `${100 * ratio}%` }}>
        <div
          ref={ref}
          css={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            '.mapboxgl-canvas': { borderRadius: 12, width: '100% !important', height: '100% !important' },
          }}
        />
      </div>
    </BlockContainer>
  );
};
