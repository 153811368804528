import React from 'react';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import { useMode } from '../../hooks/useMode';

const frequentlyUsedEmojis = [
  'green_heart',
  'snow_capped_mountain',
  'ocean',
  'test_tube',
  'airplane',
  'small_airplane',
  'helicopter',
  'car',
  'taxi',

  'watch',
  'bed',
  'wine_glass',
  'beer',
  'clinking_glasses',
  'champagne',
  'tropical_drink',
  'cocktail',
  'tumbler_glass',

  'sun_with_face',
  'sunny',
  'mostly_sunny',
  'rain_cloud',
  'lion_face',
  'elephant',
  'leopard',
  'rhinoceros',
  'giraffe_face',
];

export const EmojiPicker = ({ className, value, onChange }) => {
  const isDarkMode = useMode() === 'dark';

  return (
    <div className={className}>
      <Picker
        set="apple"
        native
        emojiTooltip
        skin={1}
        showPreview={false}
        showSkinTones={false}
        theme={isDarkMode ? 'dark' : 'light'}
        recent={frequentlyUsedEmojis}
        emoji={value}
        onSelect={emoji => onChange(emoji.native)}
        color="var(--mainTintColor)"
      />
    </div>
  );
};
