import React from 'react';
import { useRefinementList } from 'react-instantsearch';

export const FacetFilter = ({ attribute, className, defaultItems, labelLookup = label => label }) => {
  const { items, refine } = useRefinementList({ attribute });
  const defaultItemMap = (defaultItems || []).reduce((map, item) => {
    map[item] = true;
    return map;
  }, {});

  const newItems = [];
  for (const item of items) {
    const newItem = { ...item, label: labelLookup(item.label) };
    if (item.label in defaultItemMap) delete defaultItemMap[item.label];
    newItems.push(newItem);
  }

  const defaultItemsToAdd = Object.keys(defaultItemMap);
  if (defaultItemsToAdd.length > 0) {
    for (const value of defaultItemsToAdd) {
      newItems.push({
        value,
        label: labelLookup(value),
        count: 0,
        isRefined: false,
        items: null,
        disabled: true,
      });
    }
  }
  newItems.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <ul className={className}>
      {newItems.map(item => (
        <li key={item.label}>
          <button
            disabled={!!item.disabled}
            className={`flex items-center w-full overflow-hidden py-1 text-sm ${item.isRefined ? 'font-bold' : ''} ${
              item.disabled ? 'text-textDimmedColor cursor-default' : ''
            }`}
            onClick={event => {
              event.preventDefault();
              refine(item.value);
            }}
          >
            <div className={`inline-block flex-none w-4 h-4 mr-1 checkbox ${item.isRefined ? 'checked' : ''}`} />
            <span className="truncate">{item.label}</span>
            <span>&nbsp;({item.count})</span>
          </button>
        </li>
      ))}
    </ul>
  );
};
