import React, { useEffect, useRef, useState } from 'react';
import posthog from 'posthog-js';
import firebase from 'firebase/app';
import 'firebase/auth';

import { AuthContext } from './AuthContext';

export const AuthProvider = ({ auth, children }) => {
  const [state, setState] = useState({ loading: true });
  const monitorRef = useRef(null);

  if (!monitorRef.current) {
    const monitor = {};
    monitor.promise = new Promise(resolve => {
      const handleError = error => {
        setState({ error });
        resolve();
      };
      monitor.unsubscribe = auth.onAuthStateChanged(user => {
        if (user) {
          user
            .getIdTokenResult()
            .then(tokenResult => {
              setState({ auth: { userId: user.uid, claims: tokenResult.claims } });
              posthog.identify(
                user.uid,
                { email: user.email, name: user.displayName } // optional: set additional person properties
              );
              resolve();
            })
            .catch(handleError);
        } else {
          const provider = new firebase.auth.GoogleAuthProvider();
          provider.addScope('profile');
          provider.addScope('email');
          provider.setCustomParameters({
            hd: 'scenset.com',
          });
          auth.signInWithRedirect(provider).then(resolve).catch(handleError);
        }
      }, handleError);
    });
    monitorRef.current = monitor;
  }

  useEffect(() => {
    return () => {
      const monitor = monitorRef.current;
      if (monitor && monitor.unsubscribe) {
        monitor.unsubscribe();
        delete monitor.unsubscribe;
      }
    };
  }, []);

  if (state.loading) state.loading = monitorRef.current.promise;
  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
