import { functions } from '../firebase';

export const processTripRequest = async (command, tripRequestId, params = {}) => {
  await functions.httpsCallable('processTripRequestCommand')({
    tripRequestId,
    command,
    params,
  });
};

export const addContact = (tripRequestId, value) => processTripRequest('AddContact', tripRequestId, { value });

export const editContact = (tripRequestId, contactId, value) =>
  processTripRequest('EditContact', tripRequestId, { contactId, value });

export const removeContact = (tripRequestId, contactId) =>
  processTripRequest('RemoveContact', tripRequestId, { contactId });
