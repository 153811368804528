export const getShortName = user => {
  if (user.firstName) return user.firstName;
  if (user.lastName) return user.lastName;
  if (user.email) return user.email.replace(/@.*$/, '');
  return '???';
};

export const getDisplayName = user => {
  if (user.firstName && user.lastName) return `${user.firstName} ${user.lastName}`;
  if (user.displayName) return user.displayName;
  return getShortName(user);
};

export const getPossessive = user => {
  const shortName = getShortName(user);
  return `${shortName}'${shortName.match(/s$/i) ? '' : 's'}`;
};
