import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDocument } from 'react-firebase-hooks/firestore';

import { firestore } from '../../firebase';
import { WordCountDiffProvider } from '../../hooks/useWordCountDiff';

import { Layout, LayoutColumn } from '../Layout';
import { ColumnTitle } from '../ColumnTitle';
import { PageTitle } from '../PageTitle';
import { ImageCropProvider } from '../ImageCropProvider';

import { TripDisplay } from '../wysiwyg/shared';
import { InspirationInfo } from './InspirationInfo';

export const InspirationPage = ({ isSidebarOpen }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isDraft = query.has('draft');

  const { inspirationId } = useParams();

  const liveReference = useMemo(() => firestore.collection('inspirations').doc(inspirationId), [inspirationId]);
  const draftReference = useMemo(() => firestore.collection('draftInspirations').doc(inspirationId), [inspirationId]);

  const [liveInspirationSnapshot] = useDocument(liveReference);
  const [draftInspirationSnapshot] = useDocument(draftReference);

  if (!liveInspirationSnapshot || !draftInspirationSnapshot) return null;

  const inspirationSnapshot =
    isDraft && draftInspirationSnapshot && draftInspirationSnapshot.exists
      ? draftInspirationSnapshot
      : liveInspirationSnapshot;

  const mainTitle = 'Inspiration Editing';

  return (
    <WordCountDiffProvider>
      <PageTitle title="Inspiration Item" />
      <Layout className="flex-grow" hasCollapsibleSidebar isSidebarOpen={isSidebarOpen} titleComponent={ColumnTitle}>
        <LayoutColumn relativeWidth={1} title="Trip Request">
          <InspirationInfo
            inspirationSnapshot={inspirationSnapshot}
            hasDraft={draftInspirationSnapshot.exists}
            draftStatus={draftInspirationSnapshot.get('status')}
            liveStatus={liveInspirationSnapshot.get('status')}
          />
        </LayoutColumn>
        <LayoutColumn relativeWidth={3} title={mainTitle} compactTitle="Content">
          <ImageCropProvider>
            <TripDisplay tripReference={inspirationSnapshot.ref} isStory={false} isBlueprintable={true} />
          </ImageCropProvider>
        </LayoutColumn>
      </Layout>
    </WordCountDiffProvider>
  );
};
