import type { PaymentCard as PaymentCardType } from '@origin-dot/core';
import { formatCurrency } from '@origin-dot/core';

import { Button } from '../common/Button';
import { CardContainer } from '../common/CardContainer';
import { OriginRichText } from '../common/OriginRichText';
import { OriginText } from '../common/OriginText';
import { CardComponentProps } from '../helpers/types';

/** A message to get attention, with a colorful emoji. */
export const PaymentCard = ({
  className,
  currency,
  amount,
  title,
  formattedMessage,
  action,
}: CardComponentProps<PaymentCardType>) => {
  const haveHeader = !!title || !!formattedMessage;
  const haveFooter = !!action && !!action.caption;

  return (
    <CardContainer className={className}>
      {title && <OriginText className="type-title3 text-primary" css={{ marginBottom: 8 }} text={title} />}
      {formattedMessage && <OriginRichText className="type-body text-secondary" text={formattedMessage} />}
      {(title || formattedMessage) && <div />}
      <div
        css={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderColor: 'var(--separatorColor)',
            marginLeft: -16,
            marginRight: -16,
            padding: '12px 16px',
          },
          haveHeader ? { borderTopWidth: 1, marginTop: 12 } : { paddingTop: 0 },
          haveFooter ? { borderBottomWidth: 1 } : { paddingBottom: 0 },
        ]}
      >
        <div className="type-subhead text-primary">Total (Inc. VAT)</div>
        <div className="type-subhead text-primary">{formatCurrency(amount, currency)}</div>
      </div>
      {action && action.caption && (
        <Button className="darkmode" style="secondary" css={{ width: '100%', marginTop: 16 }}>
          <OriginText text={action.caption} />
        </Button>
      )}
    </CardContainer>
  );
};
