import { useState, useEffect } from 'react';
import { storage } from '../firebase';

const cache = {};

const isUrl = path => path && (path.startsWith('/') || path.match(/^(blob:)?https?:\/\//i));

export const useImageUrl = path => {
  const [url, setUrl] = useState(isUrl(path) ? path : null);

  useEffect(() => {
    let stillMounted = true;

    if (!path) {
      setUrl(null);
    } else if (isUrl(path)) {
      setUrl(path);
    } else {
      if (!(path in cache)) {
        const fetchUrl = async () => {
          try {
            const reference = storage.ref(path);
            const downloadUrl = await reference.getDownloadURL();
            return downloadUrl;
          } catch (err) {
            return null;
          }
        };
        cache[path] = fetchUrl();
      }
      cache[path].then(downloadUrl => {
        if (stillMounted) setUrl(downloadUrl);
      });
    }
    return () => {
      stillMounted = false;
    };
  }, [path, url]);

  return url;
};
