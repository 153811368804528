import { RefObject, useEffect, useState } from 'react';

export const useWidth = <T extends HTMLElement>(ref: RefObject<T>) => {
  const [width, setWidth] = useState<number | undefined>();

  useEffect(() => {
    if (!ref || !ref.current) return () => {};

    const updateWidth = () => {
      if (!ref || !ref.current) return;
      setWidth(ref.current.getBoundingClientRect().width);
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [ref]);

  return width;
};
