import React from 'react';
import { twMerge } from 'tailwind-merge';

export const Button = ({ disabled = false, type = 'button', className, onClick, children }) => (
  <button
    type={type}
    disabled={disabled}
    className={twMerge(
      `w-32 py-2 rounded text-sm font-bold bg-regularButtonBackgroundColor ${
        disabled ? 'text-dividerColor cursor-not-allowed' : 'text-textColor'
      } ${className || ''}`
    )}
    onClick={onClick}
  >
    {children}
  </button>
);
