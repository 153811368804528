import React from 'react';
import Markdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';

import { applyFormatters } from '../helpers/formatters';

const parseHtml = htmlParser({ isValidNode: node => node.type === 'br' });

export const FormattedText = ({ source, className, doubleLine = true, ...props }) => {
  const formattedSource = applyFormatters(source)
    .trim()
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\n/g, '<br>\n');

  if (doubleLine) {
    formattedSource
      .replace(/^[-*][^\n]*<br>\n<br>\n([^-*]|[-*][^ ])/gm, substr => substr.replace('<br>\n<br>\n', '\n\n<br>'))
      .replace(/^[-*][^\n]*<br>\n([^-*]|[-*][^ ])/gm, substr => substr.replace('<br>\n', '\n\n'));
  }

  return (
    <Markdown
      className={`${className || ''} markdown`}
      source={formattedSource}
      escapeHtml={false}
      astPlugins={[parseHtml]}
      {...props}
    />
  );
};
