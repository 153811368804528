import React, { useEffect, useRef } from 'react';
import posthog from 'posthog-js';
import { useInstantSearch } from 'react-instantsearch';
import AutoSuggest from 'react-autosuggest';
import { useAutocomplete } from '../hooks/useAutocomplete';

export const AutoComplete = ({
  className,
  placeholder,
  ResultComponent,
  ContainerComponent,
  onSelect = () => {},
  postHogEvent = undefined,
  theme,
}) => {
  const inputRef = useRef();
  const { refine, currentRefinement } = useAutocomplete();
  const { results: searchResults } = useInstantSearch();
  useEffect(() => {
    const handleKeypress = event => {
      if (event.key === '/') {
        const { target } = event;
        if (!['input', 'textarea'].includes(target.tagName.toLowerCase())) {
          event.preventDefault();
          inputRef.current.focus();
        }
      }
    };
    document.addEventListener('keypress', handleKeypress);
    return () => {
      document.removeEventListener('keypress', handleKeypress);
    };
  }, []);

  const classNameWithPosthog = postHogEvent ? `ph-no-capture ${className || ''}` : className;
  return (
    <>
      <AutoSuggest
        suggestions={searchResults && searchResults.query ? searchResults.hits : []}
        // alwaysRenderSuggestions={true}
        // highlightFirstSuggestion={true}
        onSuggestionsFetchRequested={({ value }) => refine(value)}
        onSuggestionsClearRequested={() => refine()}
        getSuggestionValue={() => ''}
        shouldRenderSuggestions={value => value.match(/[a-z0-9]/i)}
        renderSuggestion={(suggestion, { isHighlighted }) => (
          <ResultComponent data={suggestion} isHighlighted={isHighlighted} />
        )}
        renderItem={() => <></>}
        renderSuggestionsContainer={({ containerProps, children, query }) => (
          <ContainerComponent {...containerProps} isEmpty={!children && query}>
            {children}
          </ContainerComponent>
        )}
        onSuggestionSelected={(event, { suggestion }) => {
          inputRef.current.blur();
          if (postHogEvent) {
            posthog.capture(postHogEvent);
          }
          onSelect(suggestion);
        }}
        renderSectionTitle={() => {}}
        getSectionSuggestions={() => {}}
        inputProps={{
          ref: inputRef,
          value: currentRefinement,
          spellCheck: false,
          onChange: e => {
            refine(e.target.value);
          },
          className: classNameWithPosthog,
          placeholder,
        }}
        theme={{
          suggestionsContainer: { display: 'none' },
          suggestionsContainerOpen: { display: 'block', position: 'absolute' },
          ...theme,
        }}
      />
    </>
  );
};
