import { DateHeaderBlock as DateHeaderBlockType, formatDateRange, getNumberOfNights } from '@origin-dot/core';

import { BlockContainer } from '../common';
import { BlockComponentProps } from '../helpers/types';

export const DateHeaderBlock = ({
  className,
  startDate,
  endDate,
  locale,
  hideNumberOfNights = false,
}: BlockComponentProps<DateHeaderBlockType>) => {
  let dateLocale = locale;
  // we cannot access native browser functionality with Next apps (like Share and Trip)
  if (!locale && typeof window !== 'undefined') {
    dateLocale = navigator.language;
  }
  const formattedRange = formatDateRange(startDate, endDate, dateLocale);
  const numberOfNights = getNumberOfNights(startDate, endDate);

  return (
    <BlockContainer className={className} css={{ paddingBottom: 0 }}>
      <div
        className="type-caption1Bold"
        css={{
          display: 'inline-block',
          backgroundColor: 'var(--cardElevatedPrimaryColor)',
          padding: '6px 8px',
          borderRadius: 22,
        }}
      >
        <span className="text-primary">{formattedRange}</span>
        {!hideNumberOfNights && numberOfNights > 0 && (
          <span className="text-secondary">
            {' '}
            · {numberOfNights} {numberOfNights === 1 ? 'night' : 'nights'}
          </span>
        )}
      </div>
    </BlockContainer>
  );
};
