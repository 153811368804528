import { css } from '@emotion/react';

const shadow = (...shadowParts: string[]) => shadowParts.join(', ');

const shadowDefinitions: Record<string, string | { light: string; dark: string }> = {
  card: shadow('0px 1px 1px rgba(0, 0, 0, 0.12)', '0px 4px 8px rgba(0, 0, 0, 0.06)'),
};

export const shadows = css`
  ${Object.entries(shadowDefinitions).map(([name, value]) =>
    typeof value === 'string'
      ? css`
          .shadow-${name} {
            box-shadow: ${value};
          }
        `
      : css`
          .shadow-${name} {
            box-shadow: ${value.light};
          }
          .darkmode .shadow-${name} {
            box-shadow: ${value.dark};
          }
          .lightmode .shadow-${name} {
            box-shadow: ${value.light};
          }
        `
  )}
`;
