import { OriginText as OriginTextType, originTextToString } from '@origin-dot/core';

import { Stylable } from '../helpers/types';

type OriginTextProps = Stylable<{ text: OriginTextType; as?: keyof JSX.IntrinsicElements }>;

// TODO: Should use locale browser or context override.
export const OriginText = ({ text, as: Component = 'div', ...props }: OriginTextProps) => (
  <Component {...props}>{originTextToString(text)}</Component>
);
