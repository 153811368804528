import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt as switchIcon } from '@fortawesome/free-solid-svg-icons/faExchangeAlt';

export const ColumnTitle = ({ isToggleButton = false, className, children, ...props }) => (
  <div
    className={`${className || ''} ${
      isToggleButton
        ? 'text-sm text-textDimmedColor font-semibold pb-2'
        : 'text-2xl font-extrabold pt-3 pb-1 font-serif'
    }  flex-none text-left mx-3 border-b border-dividerColor`}
    {...props}
  >
    {isToggleButton && <FontAwesomeIcon icon={switchIcon} fixedWidth className="mr-1" />}
    {children}
  </div>
);
