import React, { useMemo, useState } from 'react';
// import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import AutoSuggest from 'react-autosuggest';
import { debounce } from 'lodash';
import { Icon } from '@origin-dot/components';

const iconForTypes = types => {
  if (types.some(type => ['bakery', 'bar', 'meal_takeaway', 'cafe', 'restaurant', 'food'].includes(type)))
    return 'foodAndDrinks';
  if (types.some(type => ['airport'].includes(type))) return 'airport';
  if (
    types.some(type =>
      ['bus_station', 'light_rail_station', 'subway_station', 'train_station', 'transit_station'].includes(type)
    )
  )
    return 'station';
  if (types.some(type => ['lodging'].includes(type))) return 'accommodation';
  if (types.some(type => ['point_of_interest'].includes(type))) return 'activity';
  return undefined;
};

const Highlight = ({ value, matches }) => {
  let lastOffset = 0;
  const chunks = [];
  for (const { offset, length } of matches) {
    if (offset > lastOffset) {
      chunks.push(<span key={chunks.length}>{value.substr(lastOffset, offset - lastOffset)}</span>);
    }
    chunks.push(<mark key={chunks.length}>{value.substr(offset, length)}</mark>);
    lastOffset = offset + length;
  }
  if (value.length > lastOffset) {
    chunks.push(<span key={chunks.length}>{value.substr(lastOffset, value.length - lastOffset)}</span>);
  }
  return <span>{chunks}</span>;
};

const PlaceAutoComplete = ({ className, placeholder, onSelect = () => {}, onCancel = () => {} }) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const autocompleteService = useMemo(() => new window.google.maps.places.AutocompleteService(), []);
  const geocoder = useMemo(() => new window.google.maps.Geocoder(), []);

  const loadSuggestions = useMemo(
    () =>
      debounce(({ value: input }) => {
        autocompleteService.getPlacePredictions({ input }, results => setSuggestions(results || []));
      }, 200),
    [autocompleteService]
  );

  return (
    <AutoSuggest
      suggestions={suggestions}
      highlightFirstSuggestion
      onSuggestionsFetchRequested={loadSuggestions}
      onSuggestionsClearRequested={() => setSuggestions([])}
      getSuggestionValue={() => ''}
      renderSuggestion={(suggestion, { isHighlighted }) => (
        <div
          className={`flex align-middle truncate text-xs p-1 cursor-pointer ${
            isHighlighted ? 'bg-dividerColor highlighted' : ''
          }`}
        >
          <Icon className="mr-1 flex-shrink-0" name={iconForTypes(suggestion.types || []) || 'pin'} size={16} />
          <Highlight value={suggestion.description} matches={suggestion.matched_substrings || []} />
        </div>
      )}
      renderSuggestionsContainer={({ containerProps, children }) => (
        <div className={`w-full shadow bg-cardBackgroundColor rounded overflow-hidden z-50 mt-1`} {...containerProps}>
          {children}
        </div>
      )}
      onSuggestionSelected={(event, { suggestion }) => {
        geocoder.geocode({ placeId: suggestion.place_id }, results => {
          const result = Array.isArray(results) && results.length > 0 && results[0];

          onSelect({
            name: suggestion.structured_formatting.main_text,
            icon: iconForTypes(suggestion.types || []),
            location: result
              ? {
                  latitude: result.geometry.location.lat(),
                  longitude: result.geometry.location.lng(),
                }
              : {},
          });

          setValue('');
        });
      }}
      inputProps={{
        value,
        spellCheck: false,
        onChange: (event, { method }) => {
          if (method === 'escape') onCancel();
          setValue(event.target.value || '');
        },
        className,
        placeholder,
        autoFocus: true,
      }}
      theme={{
        container: { position: 'relative' },
        suggestionsContainer: { display: 'none' },
        suggestionsContainerOpen: { display: 'block', position: 'absolute' },
      }}
    />
  );
};

export const PlaceLookup = ({ className, onSelect, placeholder = 'Place lookup' }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={className}>
      {isOpen ? (
        <PlaceAutoComplete
          className="w-full bg-chatInputBackgroundColor placeholder-textDimmedColor text-xs border border-searchBorderColor rounded px-2 py-1 appearance-none"
          placeholder={placeholder}
          onSelect={result => {
            setOpen(false);
            onSelect(result);
          }}
          onCancel={() => setOpen(false)}
        />
      ) : (
        <button
          className="w-full text-xs font-bold flex hover:bg-separatorColor justify-center items-center rounded px-2 py-1 border border-searchBorderColor"
          onClick={() => setOpen(true)}
        >
          {placeholder}
        </button>
      )}
    </div>
  );
};
