import 'react-phone-number-input/style.css';
import React, { forwardRef } from 'react';
import PhoneInput from 'react-phone-number-input';
import { useField } from 'formik';

export { isValidPhoneNumber } from 'react-phone-number-input';

const CustomPhoneInput = forwardRef(({ className, ...inputProps }, ref) => {
  return <input ref={ref} className={`bg-chatInputBackgroundColor ${className}`} {...inputProps} />;
});
CustomPhoneInput.displayName = 'CustomPhoneInput';

export const PhoneInputField = ({ label, name, ...props }) => {
  const [field, , helpers] = useField(name);

  return (
    <PhoneInput
      {...props}
      {...field}
      className="flex bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg py-2 px-2 w-full appearance-none leading-normal ds-input"
      value={field.value}
      withCountryCallingCode={true}
      initialValueFormat="national"
      inputComponent={CustomPhoneInput}
      onChange={value => {
        helpers.setTouched(true);
        helpers.setValue(value || '');
      }}
    />
  );
};
