import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt as editIcon } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faEllipsisV as moveIcon } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { faPlus as addIcon } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faTimes as discardIcon } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faCheck as acceptIcon } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTrashAlt as deleteIcon } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faClone as cloneIcon } from '@fortawesome/free-solid-svg-icons/faClone';
import { faClipboard as clipboardIcon } from '@fortawesome/free-solid-svg-icons/faClipboard';
import { faClipboardCheck as clipboardCheckIcon } from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import { faCopy as pasteIcon } from '@fortawesome/free-solid-svg-icons/faCopy';

import { getBlockWordCount, getWordCount } from '@origin-dot/core';

import posthog from 'posthog-js';
import { useWordCountDiff } from '../../hooks/useWordCountDiff';

import { PendingChangesPrompt } from '../PendingChangesPrompt';
import { isInAppPaymentBlock } from '../../helpers/block';

const MoveIcon = ({ className, ...props }) => {
  return (
    <button
      className={`${
        className || ''
      } w-5 flex items-center justify-center absolute right-0 text-dashedBlockColor z-10 bg-white bg-opacity-75 h-full`}
      {...props}
    >
      <FontAwesomeIcon icon={moveIcon} fixedWidth />
    </button>
  );
};

const EditorIcon = ({ icon, className, onClick, postHogEvent, ...props }) => {
  const handleClick = event => {
    if (postHogEvent) {
      posthog.capture(postHogEvent);
    }
    if (onClick) {
      onClick(event);
    }
  };
  return (
    <button
      className={`${
        className || ''
      } w-8 h-8 my-1 bg-mainTintColor text-white rounded-full flex items-center justify-center ${
        postHogEvent ? 'ph-no-capture' : ''
      }`}
      onClick={handleClick}
      {...props}
    >
      <FontAwesomeIcon icon={icon} fixedWidth />
    </button>
  );
};

export const Editable = ({
  value,
  onChange = () => {},
  onInsert = () => {},
  onDelete = () => {},
  onClone = () => {},
  onPaste = () => {},
  ViewerComponent,
  editorComponents = [],
  width = 375,
  className,
  readonly = false,
  allowMove = false,
  allowDelete = false,
  showVisibilityHandle = false,
  dragHandleProps,
  isEditing,
  setEditing = null,
  isDragging = false,
  editorParams = {},
  allBlocks = [],
  selectedBlocks = [],
  setSelectedBlocks = () => {},
  defaultSelectedBlocks,
  ...props
}) => {
  const [draft, setDraft] = useState(null);
  const [isHovering, setHovering] = useState(false);
  const [hasDataInClipboard, setHasDataInClipboard] = useState(false);

  const [localIsEditing, localSetEditing] = useState(false);

  const [realIsEditing, realSetEditing] = setEditing ? [isEditing, setEditing] : [localIsEditing, localSetEditing];

  const { setBlockWordCountDiff, setTripWordCountDiff } = useWordCountDiff();

  const isBlock = 'info' in value;
  const isInAppPayment = isInAppPaymentBlock(value);

  const discardChanges = () => {
    realSetEditing(false);
  };

  const acceptChanges = () => {
    if (draft) {
      onChange(draft);
      realSetEditing(false);
    }
  };

  useEffect(() => {
    if (isBlock) setBlockWordCountDiff(0);
    else setTripWordCountDiff(0);
    setDraft(realIsEditing ? value : null);
  }, [value, realIsEditing, isBlock, setBlockWordCountDiff, setTripWordCountDiff]);

  const editingValue = draft || value;

  const setDraftWithWordCount = newDraft => {
    if (isBlock) {
      const oldWordCount = getBlockWordCount(value);
      const newWordCount = getBlockWordCount(newDraft);
      setBlockWordCountDiff(newWordCount - oldWordCount);
    } else {
      const oldWordCount = getWordCount(value.formattedIntro || '');
      const newWordCount = getWordCount(newDraft.formattedIntro || '');
      setTripWordCountDiff(newWordCount - oldWordCount);
    }
    setDraft(newDraft);
  };

  const isEnabledForReady =
    !editingValue.metadata ||
    !editingValue.metadata.hideFromStatuses ||
    !editingValue.metadata.hideFromStatuses.includes('READY');
  const isEnabledForBooked =
    !editingValue.metadata ||
    !editingValue.metadata.hideFromStatuses ||
    !editingValue.metadata.hideFromStatuses.includes('BOOKED');
  const isEnabledForSharing = !editingValue.metadata || !editingValue.metadata.hideFromSharing;

  // Note:  EditableBlock are used outside of selection in trip previous... so isSelected has to be using properties that may or may not be passed
  const isSelected = selectedBlocks?.uuids?.[value?.uuid] !== undefined;

  const canBeUnSelected =
    isSelected &&
    (selectedBlocks.uuids[value.uuid] === selectedBlocks.minIndex ||
      selectedBlocks.uuids[value.uuid] === selectedBlocks.maxIndex);

  const readyElementStyle = (() => {
    if (!isEnabledForReady) {
      return 'bg-coloredButtonBackgroundColor';
    }
    if (!isSelected) {
      return 'bg-coloredButtonForegroundColor';
    }
    if (canBeUnSelected) {
      return 'block-selected';
    }
    return 'inner-block-selected';
  })();

  const onCopyToClipboardAction = () => {
    setSelectedBlocks(defaultSelectedBlocks);
    const blocks = Object.keys(selectedBlocks.uuids).length
      ? allBlocks.filter(block => !isInAppPaymentBlock(block) && Object.keys(selectedBlocks.uuids).includes(block.uuid))
      : [editingValue];
    window.localStorage.setItem('scenset-clipboard', JSON.stringify(blocks));
    window.dispatchEvent(new Event('scenset-storage'));
  };

  const onPasteAction = () => {
    const data = window.localStorage.getItem('scenset-clipboard');
    window.localStorage.clear('scenset-clipboard');
    window.dispatchEvent(new Event('scenset-storage'));
    onPaste(data);
  };

  useEffect(() => {
    const checkModeChange = () => {
      const data = window.localStorage.getItem('scenset-clipboard');
      setHasDataInClipboard(!!data);
    };

    checkModeChange();

    window.addEventListener('scenset-storage', checkModeChange);

    return () => {
      window.removeEventListener('scenset-storage', checkModeChange);
    };
  });

  return (
    <div className="flex items-stretch" onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <div
        className={`${className || ''} relative flex-shrink-0 flex flex-col justify-center overflow-hidden`}
        style={{ width }}
        {...props}
      >
        {allowMove && <MoveIcon {...dragHandleProps} />}
        <ViewerComponent
          className={(isHovering && !readonly) || draft || isDragging ? 'bg-coloredButtonBackgroundColor' : undefined}
          value={editingValue}
        />
        {/* <div
          className={`${
            (isHovering && !readonly) || draft ? 'opacity-50' : 'opacity-0'
          } absolute left-0 top-0 right-0 bottom-0 bg-cardElevatedPrimaryColor`}
        /> */}
      </div>
      {showVisibilityHandle ? (
        <div className="flex bg-newBackgroundColor overflow-hidden w-4 ml-3" style={{ gap: 1, paddingBottom: 1 }}>
          <div className={`flex-1 ${readyElementStyle}`} />
          <div
            className={`flex-1 ${
              isEnabledForBooked ? 'bg-coloredButtonForegroundColor' : 'bg-coloredButtonBackgroundColor'
            }`}
          />
          <div
            className={`flex-1 ${
              isEnabledForSharing ? 'bg-coloredButtonForegroundColor' : 'bg-coloredButtonBackgroundColor'
            }`}
          />
        </div>
      ) : (
        <div className="w-4 ml-3" />
      )}
      {!readonly && (
        <div className="relative flex-grow">
          <div
            className="absolute left-0 flex flex-col z-50 -ml-6"
            style={{ top: '50%', transform: 'translate(0, -50%)' }}
          >
            {!draft && (
              <div className={`flex gap-1 ${isHovering || isDragging ? 'opacity-100' : 'opacity-0'}`}>
                {!isDragging && (
                  <EditorIcon icon={editIcon} postHogEvent="clicked_edit_block" onClick={() => realSetEditing(true)} />
                )}
                {!isDragging && allowDelete && (
                  <EditorIcon icon={deleteIcon} postHogEvent="clicked_delete_block" onClick={onDelete} />
                )}
                {!isDragging && allowMove && (
                  <EditorIcon icon={addIcon} postHogEvent="clicked_add_block" onClick={onInsert} />
                )}
                {!isDragging && allowMove && (
                  <EditorIcon icon={cloneIcon} postHogEvent="clicked_clone_block" onClick={onClone} />
                )}
                {!isDragging && allowMove && !isInAppPayment && (
                  <EditorIcon
                    icon={hasDataInClipboard ? clipboardCheckIcon : clipboardIcon}
                    postHogEvent="clicked_copy_to_clipboard_block"
                    onClick={onCopyToClipboardAction}
                  />
                )}
                {!isDragging && allowMove && !isInAppPayment && hasDataInClipboard && (
                  <EditorIcon icon={pasteIcon} postHogEvent="clicked_paste_block" onClick={onPasteAction} />
                )}
              </div>
            )}
            {draft && (
              <>
                <EditorIcon icon={discardIcon} postHogEvent="clicked_discard_change_block" onClick={discardChanges} />
                <EditorIcon icon={acceptIcon} postHogEvent="clicked_accept_change_block" onClick={acceptChanges} />
              </>
            )}
          </div>
          {draft && editorComponents && (
            <div
              className="absolute left-0 right-0 flex flex-col gap-3 z-40 ml-3"
              style={{ top: '50%', transform: 'translate(0, -50%)' }}
            >
              {editorComponents.map((EditorComponent, index) => (
                <div className="bg-cardBackgroundColor p-3 rounded shadow flex flex-col" key={index}>
                  <EditorComponent value={draft} setValue={setDraftWithWordCount} editorParams={editorParams} />
                </div>
              ))}
            </div>
          )}
          <PendingChangesPrompt show={draft} />
        </div>
      )}
    </div>
  );
};
