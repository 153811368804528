import { css, SerializedStyles } from '@emotion/react';
import type { DividerBlock as DividerBlockType } from '@origin-dot/core';

import { BlockContainer } from '../common/BlockContainer';
import { BlockComponentProps } from '../helpers/types';

const cssForStyle = (style: DividerBlockType['info']['style']): SerializedStyles => {
  switch (style) {
    default:
    case 'full':
      return css({
        paddingTop: 48,
        marginBottom: 48,
      });
    case 'fullButWithLessTopPadding':
      return css({
        paddingTop: 16,
        marginBottom: 48,
      });
    case 'short':
      return css({
        paddingTop: 48,
        marginBottom: 48,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 80,
      });
    case 'shortOnlyVisibleInDarkMode':
      return css({
        paddingTop: 16,
        marginBottom: 48,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 80,
        visibility: 'hidden',
        '.darkmode &': { visibility: 'visible' },
      });
  }
};

export const DividerBlock = ({ className, style = 'full' }: BlockComponentProps<DividerBlockType>) => {
  return (
    <BlockContainer className={className} css={{ paddingTop: 0, paddingBottom: 0 }}>
      <div
        css={[
          {
            borderBottomWidth: 1,
            borderBottomColor: 'var(--separatorColor)',
          },
          cssForStyle(style),
        ]}
      />
    </BlockContainer>
  );
};
