import React, { useEffect, useState } from 'react';

import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import '../tripRequest/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

registerLocale('en-GB', enGB);

const fromCalendarDate = date => {
  const components = date.split('-').map(n => parseInt(n, 10));
  components[1] -= 1;
  return new Date(...components, 12);
};

const toCalendarDate = date =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().substr(0, 10);

export const DateRangePicker = ({ value, onChange, isClearable = false }) => {
  const [startDate, endDate] = value || [];
  const [localStartDate, setLocalStartDate] = useState(startDate ? fromCalendarDate(startDate) : undefined);
  const [localEndDate, setLocalEndDate] = useState(endDate ? fromCalendarDate(endDate) : undefined);

  useEffect(() => {
    setLocalStartDate(startDate ? fromCalendarDate(startDate) : undefined);
    setLocalEndDate(endDate ? fromCalendarDate(endDate) : undefined);
  }, [startDate, endDate]);

  const onDateRangeChange = ([newStartDate, newEndDate]) => {
    if (newStartDate && newEndDate) {
      onChange([toCalendarDate(newStartDate), toCalendarDate(newEndDate)]);
    } else {
      setLocalStartDate(newStartDate);
      setLocalEndDate(newEndDate);
    }
  };

  const clear = () => {
    setLocalStartDate(undefined);
    setLocalEndDate(undefined);
    onChange([undefined, undefined]);
  };

  return (
    <div className="relative pt-5">
      {isClearable && (
        <span onClick={clear} className="absolute right-0 top-0 text-sm font-bold cursor-pointer">
          Clear
          <FontAwesomeIcon icon={faTimes} fixedWidth />
        </span>
      )}
      <DatePicker
        selected={localStartDate}
        onChange={onDateRangeChange}
        startDate={localStartDate}
        endDate={localEndDate}
        selectsRange
        fixedHeight
        disabledKeyboardNavigation
        locale="en-GB"
        inline
        isClearable={isClearable}
      />
    </div>
  );
};
