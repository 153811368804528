import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { storage, functions } from '../../firebase';
import { NotionIcon } from '../NotionIcon';

export const TemplateUploader = ({ onSelectTemplateId, className }) => {
  const [state, setState] = useState();

  const onDrop = useCallback(
    files => {
      if (!files || files.length === 0) return;
      onSelectTemplateId(null);
      (async () => {
        try {
          const [file] = files;
          setState({ task: `Uploading ${file.name}` });

          const storagePath = `import/${file.name}`;
          const reference = storage.ref(storagePath);
          const uploadTask = reference.put(file);

          uploadTask.on('state_changed', snapshot => {
            setState({ task: 'Uploading zip file...', progress: snapshot.bytesTransferred / snapshot.totalBytes });
          });

          await uploadTask;

          setState({ task: 'Converting Notion doc...' });
          const {
            data: { id: templateId, type },
          } = await functions.httpsCallable('importNotionProposal')({ storagePath });
          onSelectTemplateId(templateId);

          if (type === 'INSPIRATION') {
            setState({ task: 'Generating inspiration item...' });
            await functions.httpsCallable('createInspirationFromTemplate')({ templateId });
          } else {
            setState({ task: 'Generating preview trip...' });
            await functions.httpsCallable('createTripFromTemplate')({
              templateId,
              members: [process.env.PREVIEW_USER_ID],
              tripId: `preview_${templateId}`,
            });
          }

          setState({ task: 'Done!' });

          await new Promise(resolve => setTimeout(resolve, 5 * 1000));
          setState(null);
        } catch (err) {
          setState({ error: err.message });
          await new Promise(resolve => setTimeout(resolve, 10 * 1000));
          setState(null);
        }
      })();
    },
    [onSelectTemplateId]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'application/zip',
    multiple: false,
    disabled: !!state,
    onDrop,
  });

  return (
    <button
      {...getRootProps({
        className: `${className || ''} ${
          isDragActive ? 'bg-coloredButtonBackgroundColor text-cardBackgroundColor' : 'bg-cardBackgroundColor'
        } p-3 text-sm text-center`,
        disabled: !!state,
      })}
    >
      <input {...getInputProps()} />
      {state ? (
        <>
          <div className={state.error ? 'font-bold text-mainTintColor' : 'italic'}>
            {state.error ? `ERROR: ${state.error}` : state.task}
          </div>
          {'progress' in state && <div className="italic">{Math.floor(state.progress * 100)}%</div>}
        </>
      ) : (
        <>
          <NotionIcon className="w-12 h-12 inline-block m-2" />
          <div className="font-bold">Drop Notion</div>
          <div className="font-bold">HTML Export</div>
          <div className="font-bold">Here</div>
        </>
      )}
    </button>
  );
};
