import { Fragment } from 'react';
import { Block as BlockType, GroupBlock as GroupBlockType } from '@origin-dot/core';

import { BlockComponentProps } from '../helpers/types';
import { ButtonBlock } from './ButtonBlock';
import { CardBlock } from './CardBlock';
import { DateHeaderBlock } from './DateHeaderBlock';
import { DividerBlock } from './DividerBlock';
import { GalleryBlock } from './GalleryBlock';
import { HeaderBlock } from './HeaderBlock';
import { InAppPaymentBlock } from './InAppPaymentBlock';
import { PhotoBlock } from './PhotoBlock';
import { RouteMapBlock } from './RouteMapBlock';
import { TextBlock } from './TextBlock';
import { ViewStoryBlock } from './ViewStoryBlock';

// This lives here instead of in GroupBlock.tsx to prevent a circular dependency.
const GroupBlock = ({ className, blocks }: BlockComponentProps<GroupBlockType>) => {
  return (
    <Fragment>
      {blocks.map((block) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <Block key={block.uuid} className={className} {...block} />
      ))}
    </Fragment>
  );
};

type BlockProps = BlockType & { className?: string };

export const Block = ({ className, ...block }: BlockProps) => {
  switch (block.type) {
    case 'Button':
      return <ButtonBlock className={className} {...block.info} />;
    case 'Card':
      return <CardBlock className={className} {...block.info} />;
    case 'DateHeader':
      return <DateHeaderBlock className={className} {...block.info} />;
    case 'Divider':
      return <DividerBlock className={className} {...block.info} />;
    case 'Gallery':
      return <GalleryBlock className={className} {...block.info} />;
    case 'Group':
      return <GroupBlock className={className} {...block.info} />;
    case 'Header':
      return <HeaderBlock className={className} {...block.info} />;
    case 'InAppPayment':
      return <InAppPaymentBlock className={className} {...block.info} />;
    case 'Photo':
      return <PhotoBlock className={className} {...block.info} />;
    case 'RouteMap':
      return <RouteMapBlock className={className} {...block.info} />;
    case 'Text':
      return <TextBlock className={className} {...block.info} />;
    case 'ViewStory':
      return <ViewStoryBlock className={className} />;
    default:
      return null;
  }
};

export {
  ButtonBlock,
  CardBlock,
  DateHeaderBlock,
  DividerBlock,
  GalleryBlock,
  GroupBlock,
  HeaderBlock,
  InAppPaymentBlock,
  PhotoBlock,
  RouteMapBlock,
  TextBlock,
  ViewStoryBlock,
};
