import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { firestore } from '../../firebase';

import { Layout, LayoutColumn } from '../Layout';
import { ColumnTitle } from '../ColumnTitle';
import { PageTitle } from '../PageTitle';

import { getDisplayName, getPossessive } from '../../helpers/user';

import { UserInfo } from './UserInfo';
import { UserFeed } from './UserFeed';
import { UserChat } from './UserChat';

export const UserPage = ({ isSidebarOpen }) => {
  const { userId } = useParams();
  const reference = useMemo(() => firestore.collection('users').doc(userId), [userId]);
  const [user, loading, error] = useDocumentData(reference);

  // XXX why is useDocumentData giving us undefined for user but not loading=true?
  if (loading || error || !user) return null;

  const possessive = getPossessive(user);

  return (
    <>
      <PageTitle title={getDisplayName(user)} />
      <Layout className="flex-grow" hasCollapsibleSidebar isSidebarOpen={isSidebarOpen} titleComponent={ColumnTitle}>
        <LayoutColumn relativeWidth={2} title="User Info">
          <UserInfo userId={userId} user={user} />
        </LayoutColumn>
        <LayoutColumn
          relativeWidth={3}
          title={`${possessive} Feed`}
          compactTitle="Feed"
          // title={
          //   <div className="flex items-center">
          //     <div className="flex-grow">{`${possessive} Feed`}</div>
          //     <button className="add inline-block w-5 h-5"></button>
          //   </div>
          // }
        >
          <UserFeed userId={userId} />
        </LayoutColumn>
        <LayoutColumn relativeWidth={3} title={`${possessive} Chat`} compactTitle="Chat">
          <UserChat
            userId={userId}
            communicationPreference={user.communicationPreference || 'APP'}
            lastReadTime={
              user &&
              user.lastReadTime &&
              user.lastReadTime.messages &&
              user.lastReadTime.messages.date &&
              user.lastReadTime.messages.date.toDate()
            }
          />
        </LayoutColumn>
      </Layout>
    </>
  );
};
