import { createContext, useContext, useEffect, useState } from 'react';

import { WithChildren } from '../helpers/types';

export type Profile = {
  displayName?: string;
  picture?: string;
};

export type ProfileRepository = {
  get: (userId: string) => Profile | undefined;
  onUpdate?: (userId: string, listener: (profile: Profile | undefined) => void) => () => void;
};

const ProfileContext = createContext<ProfileRepository>({ get: () => undefined });

export const useProfile = (userId: string): Profile | undefined => {
  const profileRepository = useContext(ProfileContext);
  const [profile, setProfile] = useState<Profile | undefined>(() => profileRepository.get(userId));

  useEffect(() => {
    if (!profileRepository.onUpdate) return;
    // eslint-disable-next-line consistent-return
    return profileRepository.onUpdate(userId, setProfile);
  }, [profileRepository, userId]);

  return profile;
};

type ProfileProviderProps = WithChildren<{ repository: ProfileRepository }>;

export const ProfileProvider = ({ repository, ...props }: ProfileProviderProps) => (
  <ProfileContext.Provider value={repository} {...props} />
);
