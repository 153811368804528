import { cx } from '@emotion/css';
import type { BlogPostCard as BlogPostCardType } from '@origin-dot/core';
import { originTextToString } from '@origin-dot/core';

import { CardContainer } from '../common/CardContainer';
import { OriginText } from '../common/OriginText';
import { CardComponentProps } from '../helpers/types';

// Magic ratio to keep as per design spec width: 335px and 420px height
export const BlogPostCardAspectRatio = '997/1250';
export const BlogPostCard = ({
  className,
  title,
  category,
  image,
  link,
  titleIcon,
}: CardComponentProps<BlogPostCardType>) => (
  <CardContainer
    className={cx(className, 'darkmode')}
    css={{
      position: 'relative',
      padding: 0,
      borderRadius: 12,
      overflow: 'hidden',
      transform: 'translateZ(0)',
      '&:hover': {
        '& img.BlogPostImage': {
          transform: 'scale(1.1)',
        },
      },
      // we calculate card height based on proportions rather than fixed values
      '&:before': {
        content: '""',
        display: 'block',
        width: 1,
        height: 0,
        paddingBottom: `calc(100% / (${BlogPostCardAspectRatio}))`,
      },
    }}
  >
    {category && (
      <div
        className="lightmode"
        css={{
          position: 'absolute',
          top: 12,
          right: 12,
          zIndex: 2,
          padding: '8px 16px',
          backdropFilter: 'blur(10px)',
          background: 'var(--textSecondaryColor)',
          borderRadius: 24,
          color: 'var(--textInversePrimaryColor)',
        }}
      >
        <OriginText className="type-caption1Regular" text={category} />
      </div>
    )}

    <div
      css={{
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        '&:after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          zIndex: 2,
          display: 'block',
          width: '100%',
          height: '45%',
          background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);',
        },
      }}
    >
      <img
        className="BlogPostImage"
        css={{ width: '100%', height: '100%', objectFit: 'cover', transition: 'transform 0.3s ease-in-out' }}
        src={image.url}
        alt={image.caption ? originTextToString(image.caption) : originTextToString(title)}
      />
    </div>

    <h4
      className="title3"
      css={{
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 3,
        padding: '0 40px 56px',
        textAlign: 'center',
      }}
    >
      <OriginText as="span" text={title} />
      {titleIcon}
    </h4>

    <a
      href={link}
      target="_top"
      rel="noreferrer"
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 4,
        display: 'block',
        fontSize: 0,
        cursor: 'pointer',
      }}
    >
      {originTextToString(title)}
    </a>
  </CardContainer>
);
