import { Stylable, WithChildren } from '../helpers/types';
import { useContentGuide } from './BlockList';

type BlockContainerProps = Stylable<
  WithChildren<{
    /** Slightly increase top and bottom margins. */
    extended?: boolean;
  }>
>;

/** Block wrapper that defines default margins around blocks. */
export const BlockContainer = ({ extended = false, ...props }: BlockContainerProps) => {
  const { width, padding } = useContentGuide();
  return (
    <div
      css={{
        width: '100%',
        maxWidth: width + padding * 2,
        padding: `${extended ? 12 : 8}px ${padding}px`,
        margin: '0 auto',
      }}
      {...props}
    />
  );
};
