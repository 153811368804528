import React from 'react';

export const CallLink = ({ phone = '', children, ...props }) => {
  if (!phone) return children;

  return (
    <a href={`ct+tel://${phone}`} {...props}>
      {children}
    </a>
  );
};
