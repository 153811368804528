import type { TextBlock as TextBlockType } from '@origin-dot/core';

import { BlockContainer } from '../common/BlockContainer';
import { OriginRichText } from '../common/OriginRichText';
import { BlockComponentProps } from '../helpers/types';

export const TextBlock = ({ className, formattedText }: BlockComponentProps<TextBlockType>) => (
  <BlockContainer extended className={className}>
    <OriginRichText className="type-body text-secondary" text={formattedText} />
  </BlockContainer>
);
