import { createContext, useContext, useMemo } from 'react';

import { Stylable, WithChildren } from '../helpers/types';

type ContentGuide = {
  width: number;
  padding: number;
};

const defaultContentGuide = {
  width: 690,
  padding: 16,
};

const ContentGuideContext = createContext<ContentGuide>(defaultContentGuide);

type BlockListProps = Stylable<
  WithChildren<{
    contentWidth?: ContentGuide['width'];
    contentPadding?: ContentGuide['padding'];
  }>
>;

export const BlockList = ({
  contentWidth = defaultContentGuide.width,
  contentPadding = defaultContentGuide.padding,
  ...props
}: BlockListProps) => {
  const contentGuide = useMemo<ContentGuide>(
    () => ({
      width: contentWidth,
      padding: contentPadding,
    }),
    [contentWidth, contentPadding]
  );

  return (
    <ContentGuideContext.Provider value={contentGuide}>
      <div {...props} />
    </ContentGuideContext.Provider>
  );
};

export const useContentGuide = () => useContext(ContentGuideContext);
