import React from 'react';

import { Image } from '../Image';

export const TripPlaceholderCard = ({ info }) => {
  const { title, formattedMessage, action } = info;
  return (
    <div className="darkmode relative -mx-4 -my-2 bg-oldBackgroundColor">
      <Image path="https://assets.origin.me/placeholder.jpg" aspect={1} />
      <div
        className="absolute top-0 w-full h-full"
        style={{
          backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1.0) 100%',
        }}
      >
        <div className="absolute top-0 w-full h-full flex flex-col items-stretch p-2 text-center">
          <div className="flex-grow flex flex-col justify-center text-textColor px-8">
            <div className="text-xl font-serif font-bold mb-4">{title}</div>
            <div>{formattedMessage}</div>
          </div>
          {action && (
            <button className="bg-generalButtonSecondaryColor text-generalTextPrimaryColor py-3 mt-3 rounded font-bold">
              {action.caption}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
