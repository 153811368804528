import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import posthog from 'posthog-js';

import { ComponentsConfigProvider, GlobalStyles, loadCdnConfig } from '@origin-dot/components';

import { TripsPage } from './trip/TripsPage';
import { UserPage } from './user/UserPage';
import { TripPage } from './trip/TripPage';
import { TripRequestPage } from './tripRequest/TripRequestPage';
import { MapPage } from './trip/MapPage';
import { TemplatesPage } from './template/TemplatesPage';
import { PublishPage } from './template/PublishPage';
import { Navbar } from './Navbar';
import { Logout } from './Logout';
import { PageTitle } from './PageTitle';
import { InspirationsPage } from './inspiration/InspirationsPage';
import { InspirationPage } from './inspiration/InspirationPage';
import { SupplierPaymentsPage } from './supplierPayments/SupplierPaymentsPage';
import { OpenMessagesPage } from './OpenMessagesPage';
import { OpenTransactionsPage } from './OpenTransactionsPage';

function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);
}
export const App = () => {
  usePageViews();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const handleKeypress = event => {
      if (event.key === 'Escape') {
        const { target } = event;
        if (['input', 'textarea'].includes(target.tagName.toLowerCase())) {
          target.blur();
        }
      }
    };
    document.addEventListener('keypress', handleKeypress);
    return () => document.removeEventListener('keypress', handleKeypress);
  }, []);

  // Default to fastly for now
  const cdnProvider = 'fastly';
  const env = process.env.TAG || 'production';
  const environment = env === 'dev' ? 'development' : env;

  // Not using useLocation to avoid it changing when we navigate in the app...
  const query = new URLSearchParams(window.location.search);
  const disableCdn = query.has('disableCdn');

  const cdnConfig = loadCdnConfig(environment);
  const componentConfig = disableCdn
    ? null
    : {
        cdnProvider,
        cdnConfig,
      };
  return (
    <ComponentsConfigProvider config={componentConfig}>
      <GlobalStyles />
      <PageTitle />
      <div className="h-screen flex flex-col items-center" style={{ maxHeight: '-webkit-fill-available' }}>
        <svg style={{ position: 'absolute', width: 0, height: 0 }}>
          <defs>
            <clipPath id="iphone" clipPathUnits="objectBoundingBox">
              <path d="M0.208,0 L0.2103782216,0.0002663029938423645 C0.21541232026666668,0.0008299996391625615 0.21955546213333332,0.002569070061576355 0.22134505200000001,0.0048029557881773405 C0.22138470720000003,0.004852455899014779 0.2214214624,0.004908867512315271 0.22145531786666667,0.004972190615763547 L0.22143756693333333,0.009804160923645321 C0.2214374786666667,0.025495750246305418 0.2488973472,0.038177339901477834 0.28277081066666665,0.038177339901477834 L0.7172291893333332,0.038177339901477834 C0.7511026533333334,0.038177339901477834 0.7785625226666666,0.025495750246305418 0.7785625226666666,0.009852216748768473 C0.7785625226666666,0.009841717512315272 0.7785567733333334,0.008269190431034483 0.7785452773333333,0.0051346355295566505 C0.7785448613333333,0.0050215120443349755 0.7785875760000001,0.004909238251231527 0.7786714693333333,0.0048029557881773405 C0.7804474266666667,0.0025529701231527093 0.7845633893333334,0.0008327195357142858 0.7896217786666666,0.0002663029938423645 L0.792,0 L0.8933333333333333,0 C0.9522437066666667,-4.997687820197044e-18 1,0.02205493842364532 1,0.04926108374384237 L1,0.9507389162561576 C1,0.9779450615763547 0.9522437066666667,1 0.8933333333333333,1 L0.10666666666666667,1 C0.04775629333333333,1 7.21444e-18,0.9779450615763547 0,0.9507389162561576 L0,0.04926108374384237 C-7.21444e-18,0.02205493842364532 0.04775629333333333,4.997687820197044e-18 0.10666666666666667,0 L0.208,0 Z"></path>
            </clipPath>
          </defs>
        </svg>
        <Navbar isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="w-full max-w-screen-xl px-3 flex-grow overflow-hidden flex flex-col">
          <Suspense fallback={null}>
            <Switch>
              <Route exact path="/users/:userId">
                <UserPage isSidebarOpen={isSidebarOpen} />
              </Route>
              <Route exact path="/trips/:tripId">
                <TripPage isSidebarOpen={isSidebarOpen} />
              </Route>
              <Route exact path="/tripRequests/:tripRequestId/:tripId?/:view?">
                <TripRequestPage isSidebarOpen={isSidebarOpen} />
              </Route>
              <Route exact path="/map">
                <MapPage />
              </Route>
              <Route exact path="/templates/:templateId?">
                <TemplatesPage />
              </Route>
              <Route exact path="/inspiration/:inspirationId">
                <InspirationPage />
              </Route>
              <Route exact path="/inspirations/:inspirationId?">
                <InspirationsPage />
              </Route>
              <Route exact path="/openMessages">
                <OpenMessagesPage />
              </Route>
              <Route exact path="/openTransactions">
                <OpenTransactionsPage />
              </Route>
              <Route exact path="/supplierPayments/:paymentId?">
                <SupplierPaymentsPage />
              </Route>
              <Route exact path="/publish/:templateId">
                <PublishPage />
              </Route>
              <Route exact path="/logout">
                <Logout />
              </Route>
              <Route exact path="/">
                <TripsPage isSidebarOpen={isSidebarOpen} />
              </Route>
              <Route />
            </Switch>
          </Suspense>
        </div>
      </div>
    </ComponentsConfigProvider>
  );
};
