import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { auth } from '../firebase';

export const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await auth.signOut();
      history.replace('/');
    })();
  }, [history]);

  return null;
};
