import React from 'react';

export const SegmentedControl = ({ className, options, value, onChange }) => {
  const lastIndex = Object.keys(options).length - 1;
  return (
    <div className={`${className || ''} flex`}>
      {Object.entries(options).map(([itemKey, itemDisplay], index) => (
        <button
          key={itemKey}
          className={`${
            value === itemKey
              ? 'bg-textSecondaryColor text-newBackgroundColor border-textSecondaryColor'
              : 'border-searchBorderColor hover:bg-separatorColor'
          } ${index === 0 ? 'rounded-l' : 'border-l-0'} ${
            index === lastIndex ? 'rounded-r' : ''
          } border bg-clip-padding text-xs flex-1 flex justify-center items-center`}
          style={{ paddingTop: '0.1rem', paddingBottom: '0.1rem' }}
          onClick={() => onChange(itemKey)}
        >
          {itemDisplay}
        </button>
      ))}
    </div>
  );
};
