import React, { useEffect, useRef } from 'react';
import { InstantSearch, Configure, useInfiniteHits } from 'react-instantsearch';

import { useAlgolia } from '../../hooks/useAlgolia';

import { Layout, LayoutColumn } from '../Layout';
import { ColumnTitle } from '../ColumnTitle';

import { FacetFilter } from '../FacetFilter';
import { FacetMenu } from '../FacetMenu';
import { PageTitle } from '../PageTitle';

import { Card } from '../card/Card';
import { TripMember } from './TripMember';

const TripFilter = () => {
  const lookupAgent = userId => {
    return [
      userId,
      <div key={userId} className="flex items-center">
        <TripMember userId={userId} linkToUser={false} avatarSize={'small'} />
      </div>,
    ];
  };

  const statusMapping = {
    BOOKED: 'Booked',
    READY: 'Ready',
    PREVIEW: 'Preview',
    PREPARING: 'Preparing',
    CANCELED: 'Canceled',
  };

  const lookupStatus = status => statusMapping[status] || status;

  return (
    <div className="flex-grow p-3 overflow-y-auto">
      <div className="bg-cardBackgroundColor py-2 px-3 rounded shadow">
        <h2 className="text-lg font-semibold mb-1">Status</h2>
        <FacetFilter
          attribute="status"
          defaultRefinement={['proposal']}
          defaultItems={Object.keys(statusMapping)}
          labelLookup={lookupStatus}
        />
      </div>
      <div className="bg-cardBackgroundColor mt-3 py-2 px-3 rounded shadow">
        <h2 className="text-lg font-semibold mb-1">Destination</h2>
        <FacetMenu attributes={['countryNames']} />
      </div>
      <div className="bg-cardBackgroundColor mt-3 py-2 px-3 rounded shadow">
        <h2 className="text-lg font-semibold mb-1">Agent</h2>
        <FacetMenu attributes={['agentUserId']} labelLookup={lookupAgent} />
      </div>
      <div className="bg-cardBackgroundColor mt-3 py-2 px-3 rounded shadow">
        <h2 className="text-lg font-semibold mb-1">Type</h2>
        <FacetMenu
          attributes={['isDemo']}
          labelLookup={label => (label === 'true' ? 'Demo' : 'Client')}
          defaultRefinement={'false'}
          defaultItems={['false', 'true']}
        />
      </div>
    </div>
  );
};

const TripHits = () => {
  const { hits, showMore, isLastPage } = useInfiniteHits();
  const sentinelRef = useRef(null);

  useEffect(() => {
    let observer;
    if (sentinelRef.current !== null) {
      observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [isLastPage, showMore]);

  return (
    <div
      className="flex-grow p-3 overflow-y-auto grid content-start gap-4"
      style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))' }}
    >
      {hits.map(trip => {
        if (trip.indexType !== 'trip') return null;
        const startTime = trip.startTime ? new Date(trip.startTime * 1000) : null;
        const endTime = trip.endTime ? new Date(trip.endTime * 1000) : null;
        const [, tripRequestId, tripId] = trip.objectID.split(':');
        const card = {
          type: 'Trip',
          info: {
            tripId: trip.objectID,
            tripPath: `tripRequests/${tripRequestId}/trips/${tripId}`,
            trip: {},
          },
        };

        if (startTime && endTime) {
          Object.assign(card.info.trip, {
            startTime: { date: startTime, timeZone: 'Europe/Amsterdam' },
            endTime: { date: endTime, timeZone: 'Europe/Amsterdam' },
          });
        }

        if (trip.status !== 'placeholder') {
          Object.assign(card.info.trip, {
            isProposal: trip.status === 'proposal',
            members: trip.members,
            title: trip.title,
            subTitle: trip.subTitle,
            image: trip.image ? { path: trip.image } : {},
          });
        }

        return (
          <div key={trip.objectID}>
            <Card card={card} />
          </div>
        );
      })}
      <div ref={sentinelRef} aria-hidden="true" />
    </div>
  );
};

export const TripsPage = ({ isSidebarOpen }) => {
  const algolia = useAlgolia();

  return (
    <InstantSearch
      searchClient={algolia}
      indexName={`${process.env.ALGOLIA_INDEX_PREFIX}admin_universal${process.env.ALGOLIA_SORT_REPLICA}`}
    >
      <Configure filters="indexType:trip" />
      <PageTitle title="Trips" />
      <Layout className="flex-grow" hasCollapsibleSidebar isSidebarOpen={isSidebarOpen} titleComponent={ColumnTitle}>
        <LayoutColumn relativeWidth={1} title="Trip Filters">
          <TripFilter />
        </LayoutColumn>
        <LayoutColumn relativeWidth={4} title="Trips">
          <TripHits />
        </LayoutColumn>
      </Layout>
    </InstantSearch>
  );
};
