import type { ButtonBlock as ButtonBlockType } from '@origin-dot/core';

import { BlockContainer } from '../common/BlockContainer';
import { Button } from '../common/Button';
import { BlockComponentProps } from '../helpers/types';

export const ButtonBlock = ({ className, action, style }: BlockComponentProps<ButtonBlockType>) => (
  <BlockContainer className={className}>
    <Button style={style} css={{ width: '100%' }}>
      {action.caption ?? ''}
    </Button>
  </BlockContainer>
);
