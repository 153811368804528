import { Children, isValidElement, ReactNode } from 'react';
import { jsx } from '@emotion/react';

import { Stylable, WithChildren } from '../helpers/types';

const size = 32;
const offset = 21;

type AvatarListProps = Stylable<WithChildren>;

/** Use instead of React.cloneElement to be able to override the css prop. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emotionCloneElement = (element: any, props: any, ...children: ReactNode[]) => {
  // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
  const typeOverride = element.props.__EMOTION_TYPE_PLEASE_DO_NOT_USE__;
  return jsx(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    typeOverride ?? element.type,
    {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      key: element.key !== null ? element.key : undefined,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      ref: element.ref,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      ...element.props,
      ...props,
    },
    ...children
  );
};

export const AvatarList = ({ className, children }: AvatarListProps) => {
  const avatars = Children.toArray(children);
  const width = size + offset * (avatars.length - 1);

  return (
    <div className={className} css={{ position: 'relative', width, height: size, flexShrink: 0 }}>
      {avatars
        .map(
          (avatar, index) =>
            isValidElement(avatar) &&
            emotionCloneElement(avatar, {
              css: {
                position: 'absolute',
                right: index * offset,
              },
              size,
              border: true,
            })
        )
        .reverse()}
    </div>
  );
};
