import { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import type { GalleryBlock as GalleryBlockType } from '@origin-dot/core';

import { BlockContainer } from '../common/BlockContainer';
import { useContentGuide } from '../common/BlockList';
import { Photo } from '../common/Photo';
import { BlockComponentProps } from '../helpers/types';
import { useWidth } from '../helpers/useWidth';

const verticalMargin = 12;

type GalleryBlockProps = BlockComponentProps<GalleryBlockType>;

const SideScrollGalleryBlock = ({
  className,
  images,
  rowHeight,
  showCaptions,
}: Omit<GalleryBlockProps, 'sideScroll'>) => {
  const { width, padding } = useContentGuide();
  const ref = useRef<HTMLDivElement>(null);
  const outerRef = useRef<HTMLDivElement>(null);
  const [drag, setDrag] = useState<{ scroll: number; mouse: number } | undefined>(undefined);

  const realWidth = useWidth(outerRef);
  const galleryHeight = rowHeight ?? Math.max((Math.min(width, realWidth ?? 0) * 2) / 3, 240);

  useEffect(() => {
    if (!drag) return;
    const onMouseMove = (event: MouseEvent) => {
      if (!drag || !ref.current) return;
      const delta = drag.mouse - event.clientX;
      ref.current.scrollLeft = drag.scroll + delta;
    };
    const onMouseUp = () => {
      setDrag(undefined);
    };
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [drag]);

  const spacerCss = css({
    width: `calc((100% - ${width}px) / 2)`,
    minWidth: padding,
    flexShrink: 0,
    cursor: 'default',
  });

  return (
    <div css={{ width: '100%', position: 'relative' }}>
      <BlockContainer extended>
        <div ref={outerRef} css={{ height: galleryHeight }} />
      </BlockContainer>
      <div
        ref={ref}
        className={className}
        css={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          padding: `${verticalMargin}px 0`,
          overflowX: 'auto',
          overscrollBehaviorX: 'contain',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          userSelect: 'none',
          cursor: drag ? 'grabbing' : 'grab',
        }}
        onMouseDown={(event) => {
          if (!ref.current) return;
          setDrag({
            scroll: ref.current.scrollLeft,
            mouse: event.clientX,
          });
        }}
        role="presentation"
      >
        <div css={spacerCss} role="presentation" onMouseDown={(event) => event.stopPropagation()} />
        {images.map((image, index) => (
          <Photo
            key={index}
            css={[
              index > 0 && {
                marginLeft: 16,
              },
              {
                width: galleryHeight * (image.width && image.height ? image.width / image.height : 1),
                height: galleryHeight,
              },
            ]}
            image={image}
            showCaption={showCaptions}
            heightBound
          />
        ))}
        <div css={spacerCss} role="presentation" onMouseDown={(event) => event.stopPropagation()} />
      </div>
    </div>
  );
};

const GridGalleryBlock = ({ className, images, showCaptions }: Omit<GalleryBlockProps, 'sideScroll'>) => (
  <BlockContainer extended className={className}>
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: 12,
      }}
    >
      {images.map((image, index) => (
        <div key={index}>
          <Photo image={image} showCaption={showCaptions} />
        </div>
      ))}
    </div>
  </BlockContainer>
);

export const GalleryBlock = ({ sideScroll, ...props }: GalleryBlockProps) =>
  sideScroll ? <SideScrollGalleryBlock {...props} /> : <GridGalleryBlock {...props} />;
