import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { firestore } from '../../firebase';

import { Layout, LayoutColumn } from '../Layout';
import { ColumnTitle } from '../ColumnTitle';
import { PageTitle } from '../PageTitle';

import { TemplateListing } from './TemplateListing';
import { TemplateInfo } from './TemplateInfo';
import { SubstituteUserToggle } from '../user/SubstituteUserToggle';

export const TemplatesPage = () => {
  const reference = useMemo(() => firestore.collection('templates').orderBy('importInfo.time.date', 'desc'), []);
  const [templates] = useCollectionData(reference, { idField: 'id' });
  const history = useHistory();
  const { templateId } = useParams();
  const selectedTemplate = templateId && templates && templates.find(({ id }) => id === templateId);

  const onSelectTemplateId = selectedTemplateId => {
    history.replace(`/templates${selectedTemplateId ? `/${selectedTemplateId}` : ''}`);
  };

  return (
    <>
      <PageTitle title={selectedTemplate ? selectedTemplate.importInfo.title : 'Templates'} />
      <Layout className="flex-grow" titleComponent={ColumnTitle}>
        <LayoutColumn
          relativeWidth={2}
          title={
            <div className="flex items-center">
              <div className="flex-grow">All Templates</div>
              <SubstituteUserToggle
                className="font-sans font-normal"
                userId={process.env.PREVIEW_USER_ID}
                label="Preview"
              />
            </div>
          }
          compactTitle="Templates"
        >
          <TemplateListing
            templates={templates}
            selectedTemplateId={templateId}
            onSelectTemplateId={onSelectTemplateId}
          />
        </LayoutColumn>
        <LayoutColumn relativeWidth={1} title="Template Info" compactTitle="Info">
          <TemplateInfo selectedTemplate={selectedTemplate} />
        </LayoutColumn>
      </Layout>
    </>
  );
};
