import React from 'react';
import Select from 'react-select';

const inspirationTags = {
  adult_only: `adult-only resort`,
  animation_studio: `animation studio`,
  archeology: `archeology`,
  archery: `archery`,
  architecture_design: `architecture & design`,
  art_classes: `arts and crafts classes`,
  art_culture_history: `art & culture & history`,
  bakery: `bakery`,
  bar: `cocktail bar`,
  bbq: `BBQ`,
  beach: `beach`,
  big_five_wildlife: `big five`,
  bird_watching: `birdwatching`,
  boating: `boating & sailing`,
  bungeejumping: `bungeejumping`,
  cafe: `coffee shop`,
  calligraphy: `calligraphy`,
  camel_riding: `camel riding`,
  canopy_tours: `canopy tours`,
  castle: `castle`,
  cheese: `cheese making`,
  child_friendly: `family friendly`,
  chocolate: `chocolate tour`,
  cigar: `cigar factory tour`,
  city_tour: `city tour`,
  climbing: `climbing`,
  coffee_tour: `coffee tour`,
  cooking_class: `cooking class`,
  culture: `cultural event`,
  cycling: `cycling & biking`,
  dance: `music & dance`,
  design: `design hotel`,
  digital_nomad: `workation`,
  dogsled: `dogsledding`,
  eco_lodge: `eco lodge`,
  family_pet_friendly: `family or pet friendly`,
  farm: `farm tour`,
  fashion: `fashion`,
  fish: `fishing`,
  fitness: `gym`,
  food_drinks: `food & drinks`,
  garden_park: `gardens & parks`,
  glamping: `glamping`,
  glassblow: `glassblowing`,
  golf: `golf course`,
  guided_nature_walk: `guided nature walk`,
  hammam: `hammam, sauna & steam`,
  heli_ski: `heli-ski`,
  helicopter: `helicopter`,
  henna_art: `henna art`,
  hiking: `hiking`,
  historic: `historic sites and ruins`,
  honeymoon: `honeymoon`,
  horse: `horseback riding`,
  hot_air_balloon: `hot air balloon`,
  ice_hike: `ice hiking`,
  ice_skate: `ice skating`,
  ikebana_flower: `flower arranging`,
  jeep: `extreme driving`,
  jetski: `jetski`,
  kayak: `kayaking`,
  kids_club: `kids club & babysitting`,
  lgbtq: `LGBTQ+`,
  local_artisan: `local artisan`,
  market: `market`,
  meditation: `meditation & mindfulness`,
  michelin: `Michelin star`,
  movie: `cinema`,
  museum: `museum, exhibition & galleries`,
  natural_wonders_world: `natural wonders of the world`,
  no_electricity: `off-the-grid`,
  olive: `olive oil tasting`,
  onsen: `hot spring & onsen`,
  outdoor: `outdoor adventure`,
  paddle: `paddleboarding`,
  paraglide: `paragliding`,
  pet_friendly: `pet friendly`,
  photography: `photography`,
  picnic: `picnic`,
  pottery: `pottery`,
  private_beach: `private beach`,
  private_chef: `private chef`,
  private_pool: `private pool`,
  raft: `rafting`,
  relaxed: `rest and relaxation`,
  religion: `religious site`,
  road_trip: `road trip`,
  romance: `romance`,
  rum: `distillery or brewery tour`,
  safari: `safari`,
  scuba: `scuba diving`,
  ski: `ski & snowboard`,
  skydive: `skydiving`,
  snorkel: `snorkeling`,
  snowmobile: `snowmobiling`,
  snowshoe: `snowshoeing`,
  spa_treatment_massage: `spa treatment & massage`,
  spa_wellness: `spa & wellness`,
  sport: `sporting event`,
  stargazing: `stargazing`,
  surf: `surfing`,
  sustainable: `sustainability & conservation`,
  tea_tasting: `tea tour`,
  turtle_hatch: `turtle hatching`,
  urban: `urban exploration`,
  vegan_vegetarian: `vegan or vegetarian`,
  watersports: `watersports`,
  whale_watch: `whale watching`,
  wifi: `high speed wifi`,
  wildlife_nature: `wildlife & nature`,
  wine: `wine tour`,
  winter_sports: `winter sports`,
  yoga: `yoga & pilates`,
  wellness: `OLD:Wellness and spa treatments`,
  wildlife: `OLD:Wildlife safaris`,
  culinary: `OLD:Culinary experiences`,
  boats: `OLD:Cruise & boat tours`,
  museum_art: `OLD:Museums and art scenes`,
  forYou: 'Where to next',
  popular: 'Popular',
  shutterstock: 'Shutterstock',
};

export const getOptionsFromTags = tags =>
  tags.map(tag => {
    return { label: inspirationTags[tag] || tag, value: tag };
  });

const tagOptions = getOptionsFromTags(Object.keys(inspirationTags));

export const getNameForTag = tag => inspirationTags[tag] || tag;

const colourStyles = {
  option: styles => {
    return {
      ...styles,
      color: 'rgba(25, 30, 31, 1)',
    };
  },
};

export const TagSelector = ({ className, value, onChange }) => (
  <Select
    className={className}
    options={tagOptions}
    value={value}
    isMulti
    closeMenuOnSelect={false}
    onChange={onChange}
    styles={colourStyles}
    menuPlacement="auto"
  />
);
