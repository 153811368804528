const formatterRegex = /\{\{[^}]*?\}\}/g;

const formatters = {};

formatters.currency = args => {
  if (args.length !== 2) throw new Error('Currency formatter expects 2 arguments');
  const [currency, amount] = args;

  if (!currency.match(/^[a-z]{3}$/i)) throw new Error('Invalid currency in currency formatter');
  if (!amount.match(/^-?\d+(\.\d{2})?$/)) throw new Error('Invalid amount in currency formatter');

  // TODO: do actual locale currency formatting.
  // Not used yet on backend; should be shared with frontend.
  return `${currency.toUpperCase()} ${amount}`;
};

formatters.phone = args => {
  if (args.length !== 1) throw new Error('Phone formatter expects 1 argument');
  const [phone] = args;

  if (!phone.match(/^\+?[\d\s]*\d$/)) throw new Error('Invalid phone number in phone formatter');

  // TODO: do actual locale phone formatting.
  // Not used yet on backend; should be shared with frontend.
  return phone;
};

formatters.time = args => {
  if (args.length !== 1) throw new Error('Time formatter expects 1 argument');
  const [time] = args;

  const match24 = time.match(/^(\d{1,2}):(\d{2})$/);
  const match12 = time.match(/^(\d{1,2})(:(\d{2}))?\s*(AM|PM)$/i);

  let hours = 0;
  let minutes = 0;

  if (match24) {
    hours = parseInt(match24[1], 10);
    minutes = parseInt(match24[2], 10);
    if (hours > 23) throw new Error('Invalid hours in time formatter');
    if (minutes > 59) throw new Error('Invalid minutes in time formatter');
  } else if (match12) {
    hours = parseInt(match12[1], 10);
    minutes = parseInt(match12[3] || '0', 10);
    const period = match12[4].toUpperCase();
    if (hours < 1 || hours > 12) throw new Error('Invalid hours in time formatter');
    if (minutes > 59) throw new Error('Invalid minutes in time formatter');
    if (period === 'AM' && hours === 12) hours = 0;
    if (period === 'PM' && hours < 12) hours += 12;
  } else {
    throw new Error('Invalid time in time formatter');
  }

  // TODO: do actual locale phone formatting.
  // Not used yet on backend; should be shared with frontend.
  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};

const executeFormatter = formatterString => {
  const match = formatterString.match(/^\{\{([^}]*?)\}\}$/);
  if (!match) throw new Error(`Invalid formatter ${formatterString}`);
  const [, content] = match;
  const [type, ...args] = content.split('|').map(token => token.trim());

  const formatter = formatters[type];
  if (!formatter) throw new Error(`Unknown formatter ${formatterString}`);

  try {
    return formatter(args);
  } catch (err) {
    throw new Error(`Incorrect ${type} formatter ${formatterString}`);
  }
};

module.exports.stripFormatters = formattedText => formattedText.replace(formatterRegex, '');

module.exports.checkFormatters = formattedText => {
  const issues = [];
  for (const formatterString of formattedText.match(formatterRegex) || []) {
    try {
      executeFormatter(formatterString);
    } catch (err) {
      issues.push(err.message);
    }
  }
  return issues;
};

module.exports.applyFormatters = formattedText =>
  formattedText.replace(formatterRegex, formatterString => {
    try {
      return executeFormatter(formatterString);
    } catch (err) {
      return formatterString;
    }
  });
