import { MapLeg, MapLocation } from '@origin-dot/core';
import { LngLatBoundsLike } from 'mapbox-gl';

const defaultBounds: LngLatBoundsLike = [
  [-180, -90],
  [180, 90],
];

const defaultZoomOffset = 0.01;

export const getBounds = (legs: MapLeg[], points: MapLocation[]): LngLatBoundsLike => {
  const coordinates = [
    ...legs.flatMap(({ start, end }) => [start.location, end.location]),
    ...points.map(({ location }) => location),
  ];

  if (coordinates.length === 0) return defaultBounds;

  if (coordinates.length === 1) {
    const { longitude, latitude } = coordinates[0]!;
    return [
      [longitude - defaultZoomOffset, latitude - defaultZoomOffset],
      [longitude + defaultZoomOffset, latitude + defaultZoomOffset],
    ];
  }

  const latitudes = coordinates.map((coordinate) => coordinate.latitude);
  const longitudes = coordinates.map((coordinate) => coordinate.longitude);
  const minLatitude = Math.min(...latitudes);
  const maxLatitude = Math.max(...latitudes);
  const minLongitude = Math.min(...longitudes);
  const maxLongitude = Math.max(...longitudes);

  return [
    [minLongitude, minLatitude],
    [maxLongitude, maxLatitude],
  ];
};
