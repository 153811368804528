import type { CardBlock as CardBlockType } from '@origin-dot/core';

import { Card } from '../cards';
import { BlockContainer } from '../common';
import { BlockComponentProps } from '../helpers/types';

/** Embeds a Card into a Block. */
export const CardBlock = ({ className, card }: BlockComponentProps<CardBlockType>) => (
  <BlockContainer extended className={className}>
    <Card className={className} {...card} />
  </BlockContainer>
);
