import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle as issueIcon } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';

import { getLocalTime } from '../../helpers/time';
import { itineraryCardForTemplate } from '../../helpers/cards';

import { Card } from '../card/Card';
import { NotionIcon } from '../NotionIcon';

import { TemplateUploader } from './TemplateUploader';

export const TemplateListing = ({ templates, selectedTemplateId, onSelectTemplateId }) => {
  return (
    <div
      className="flex-grow p-3 overflow-y-auto grid content-start gap-2"
      style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))' }}
      onClick={() => onSelectTemplateId(null)}
    >
      {templates && (
        <TemplateUploader
          className="rounded-lg border-4 border-cardBackgroundColor"
          onSelectTemplateId={onSelectTemplateId}
        />
      )}
      {templates &&
        templates.map(template => {
          const { id: templateId, importInfo } = template;
          const itineraryCard = itineraryCardForTemplate(template);
          return (
            <button
              key={templateId}
              onClick={event => {
                event.stopPropagation();
                onSelectTemplateId(selectedTemplateId === templateId ? null : templateId);
              }}
              className={`text-left p-2 bg-cardBackgroundColor border-2 rounded-lg ${
                selectedTemplateId === templateId ? 'border-mainTintColor' : 'border-cardBackgroundColor'
              }`}
            >
              <div className="mb-2 flex flex-col text-xs items-stretch">
                <div className="flex items-center">
                  <NotionIcon className="w-4 h-4 mr-1 flex-shrink-0" />
                  <div className="font-bold text-sm flex-grow truncate">{importInfo.title || ''}</div>
                  {(importInfo.issues || []).length > 0 && (
                    <>
                      <FontAwesomeIcon icon={issueIcon} fixedWidth className="text-mainTintColor ml-1" />
                      <div className="text-mainTintColor font-bold">{(importInfo.issues || []).length}</div>
                    </>
                  )}
                </div>
                <div className="truncate">Last upload: {getLocalTime(importInfo.time).fromNow()}</div>
              </div>
              <Card card={itineraryCard} />
            </button>
          );
        })}
    </div>
  );
};
