import { createContext, useContext } from 'react';
import { Trip } from '@origin-dot/core';

import { WithChildren } from '../helpers/types';

type TripContextType = {
  trip: Trip;
  onViewStory?: () => void;
};

const TripContext = createContext<TripContextType | undefined>(undefined);

export const useTrip = () => useContext(TripContext);

type TripProviderProps = WithChildren<TripContextType>;

export const TripProvider = ({ trip, onViewStory, ...props }: TripProviderProps) => (
  <TripContext.Provider value={{ trip, onViewStory }} {...props} />
);
